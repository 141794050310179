define('modules/chat',[
    'app',
    // Libraries.
    'backbone',
    "main/settings",

    // modules
    "modules/contact",
    "modules/message",
    "main/lib",
    "modules/account",
    "main/UI",
    "main/notifications",
    "main/cache",
    "libs/minEmoji",
    'constants',

    // plugins
    'plugins/sliding-view',
    'plugins/jquery.lazyload',
    'plugins/jquery.scrollTo'
], function (App, Backbone, Settings, Contact, Message, Lib, Account, UI, Notifications, Cache, minEmoji) {
    var M = Backbone.Marionette;
    var Chat = new App.module();
    // move message module into the namespace
    App.Message = Message;

    Chat.Model = Backbone.Model.extend({
        url: 'chats_list',
        idAttribute: 'thread_id',
        defaults: function () {
            return {
                // added in VWC
                is_deleted: false,
                //timestamp: (new Date()).getTime() / 1000,

                // standard chat attributes
                last_message: {},
                unread_count: 0,
                thread_id: null,
                recipients: [],
                groups: [],
                loaded_all_chats: false,
                data_retention_policy: false,
                is_walkie: false,
                messages: new Chat.Messages(),
                live_audio: false,
                invites : [],
                replies_to: {},
                thread_meta: {
                    create_time: (new Date()).getTime() / 1000,
                    creator: (App.Auth.get('user_id')) ? App.Auth.get('user_id') : '0',
                    groups: [],
                    recipients: [],
                    subject: '',
                    thread_id: ''
                }
            };
        },
        initialize: function () {
            var that = this,
                thread_flags = Settings.get('user_settings') &&
                               Settings.get('user_settings').threads &&
                               Settings.get('user_settings').threads[this.get('thread_id')];
            thread_flags = thread_flags || { flags: [] };

            if (thread_flags) {
                this.set({ pinned_chat:                  thread_flags.flags.indexOf('pinned_chat') !== -1 });
                this.set({ zapier_chat:                  thread_flags.flags.indexOf('zapier_chat') !== -1 });
                this.set({ _muted:                       thread_flags.flags.indexOf('mute') !== -1 });
                this.set({ _silent:                      thread_flags.flags.indexOf('silent') !== -1 });
                this.set({ _favorite:                    thread_flags.flags.indexOf('favorite') !== -1 });
                this.set({ _notificationsDisabled:       thread_flags.flags.indexOf('ignored') !== -1 });
                this.set({ _extremeNotificationsEnabled: thread_flags.flags.indexOf('xnotifs') !== -1 });
                this.set({ _extremeNotificationsEnabled: thread_flags.flags.indexOf('xnotif') !== -1 }); // This is for android bug
                this.set({ _mentionsEnabled:             thread_flags.flags.indexOf('mentions') !== -1 });
                this.set({ _interruptEnabled:            thread_flags.flags.indexOf('interrupt') !== -1 });
                this.set({ _priorityRecordEnabled:       thread_flags.flags.indexOf('priority') !== -1 });
            }

            if (this.get('thread_meta') && this.get('thread_meta').creator === 'voxer.bot@voxer.com' || this.get('creator') === 'voxer.bot@voxer.com') {
                this.set({'is_walkie': true});
            }

            if (this.get('thread_id') && this.get('thread_id').indexOf('FC_') !== -1) {
                this.set({'favorited': true});
            }

            if (this.get('replies_to')) { // clear replies_to flag on new chat
                this.set({replies_to: {}});
            }
            var invites = this.get('invites');

            // set title for hotlines (created from contacts)
            if (this.is_hotline() && this.get('creator') !== "voxer.bot@voxer.com") {
                if (this.get('subject') === undefined || (this.get('recipients').length === 1 && this.get('recipients')[0] === App.Auth.get('user_id'))) {
                    var other_id = _.without(this.get('recipients'), App.Settings.get('user_id'));
                    var subject = "Chat",
                        that = this;
                    if (other_id.length && other_id[0] !== App.Auth.get('user_id')) {
                        var profile = App.profiles.findWhere({user_id: other_id[0]});
                        if (profile) {
                            subject = profile.get('name');
                        } else {
                            App.profiles.get_profile([other_id[0]]).done(function (profiles) {
                                var profile = App.profiles.get(other_id[0]);

                                subject = profile.get('name');
                                that.set({subject: subject});
                            }.bind(this)).fail(function () {
                                that.set({subject: "Chat"});
                            });
                        }
                    } else {
                        if (invites.length > 0) {
                            subject =  invites[0].first + ' ' + invites[0].last;
                        } else {
                            // Invite didn't got updated yet, let's fetch it
                            App.API.thread_details(this.get('thread_id'), {
                                home_router: App.Settings.get('home_router')
                            }, function(e) {
                                console.error(e);
                            }, function(details) {
                                if (details.invites && details.invites.length > 0) {
                                    subject =  details.invites[0].first + ' ' + details.invites[0].last;
                                    that.set({
                                        subject: subject,
                                        title: subject
                                    });
                                }
                            });
                            subject = 'Note to self';
                        }
                    }
                    this.set({subject: subject});
                }
            } else if (this.get('creator') !== "voxer.bot@voxer.com" && this.get('subject') !== undefined) {
                var subject = this.get('title') || this.get('subject');
                this.set({subject: subject});
            } else if (this.get_participant_count() > 0){
                var participant = App.profiles.findWhere({user_id: this.get_recipients().recipients[0]});
                if (participant) {
                    this.set({subject: participant.name()});
                }
            }
            if(this.get("is_walkie")){
                this.set({subject: "Walkie"});
            }
            if(this.get("favorited")){
                this.set({subject: "Starred"});
            }

            this.on('play:chirp', function () {
                this.play_chirp();
            }, this);

            this.on('change:unread_count', function () {
                this.set({unread_count: this.get('messages').where({consumed: false}).length});
            });

            this.on('change:active', function (model, collection) {
                var message_ids = [];
                if (this.get('active') === false) {
                    // consume all messages
                    var that = this;
                    message_ids = _.compact(this.get('messages').map(function (m) {
                        if (m.get('content_type') !== 'audio' && m.get('consumed') === false) {
                            return m.get('message_id');
                        }
                    }));

                    if (message_ids.length !== 0) {
                        this.consume_messages(message_ids).done(function (data) {
                            that.get('messages').each(function (m) {
                                if (message_ids.indexOf(m.get('message_id')) !== -1) {
                                    m.set({'consumed': true});
                                }
                            });
                        }).fail(function (xhr) {
                                // debugger;
                                // notification maybe??
                        });
                    }
                } else {
                    // Set the thread in url
                    if (location.pathname.indexOf("chats") !== -1){
                        App.Router.navigate("chats/" + this.get("thread_id"), { trigger: false});
                    }
                }
            }, this);

            if (_.contains(Settings.fetch('live-chats'), this.get('thread_id'))) {
                this.set({live_audio: true});
            }

            this.fetch_if_unconsumed = function() {
                // If we still have unconsumed messages that have not been fetched, let's fetch them
                if (parseFloat(right_time(this.get('last_message'), this.get('timestamp'))) > parseFloat(this.get('unread_count_timestamp')) &&
                        this.get('messages').length && this.get('messages').where({consumed:false}).length <  this.get('unread_count')) {
                    this.get('messages').timeline({
                        chat: this,
                        success: function() {
                            // this whole bit of code seems messed up but hopefully this will stop it from requesting timelines over and over
                            if (this.get('loaded_all_chats')) {
                                return;
                            }
                            this.fetch_if_unconsumed.call(this);
                        }.bind(this)
                    });
                }
            }
            App.vent.on('avatar_bust', this.fetch_if_unconsumed.bind(this));

            if (this.get('messages')) {
                this.set({unread_count: this.get('messages').where({consumed: false}).length});
            }
        },
        handle_active: function (e) {
            // Do nothing if chat is already active
            if (this.get('active')){
                return
            }
            // grab all cids for active chat models
            var active_cids = _.pluck(App.chats.where({active: true}), 'cid') || [];
            _.each(active_cids, function (cid) {
                var _view,
                    _views = App.UI.ViewsContainer.filter(function (view) {
                        return view.model && view.model.cid == cid &&
                            view.$el.attr('id').indexOf('popout') == -1;
                    });

                if (_views.length > 1) {
                    try {
                        _views.forEach(function (view) {
                            view.destroy();
                        });
                    } catch (err) {
                        console.error(err);
                    }
                    return;
                } else {
                    _view = _views[0];
                }

                if (!_view){
                    return;
                }
                if (_view.regionManager.get('participants')){
                    _view.regionManager.get('participants').destroy();
                }

                _view.$el.hide();
            });

            App.chats.where({active: true}).forEach(function (chat) {
                // unset the active flag
                chat.set({active: false});
            });
            App.vent.trigger('metrics', 'track', {});

            // show the chat panel, load timeline then render chat messages
            if (e && (e.target.getAttribute('href') === null)) {
                e.preventDefault();
            } else if (!e) {
            } else {
                return;
            }

            this.set({active: true});

            var existingView = App.UI.ViewsContainer.findByModelCid(this.cid);
            if (!existingView || (existingView.$el && !existingView.$el.parent().length)) {
                var that = this;
                var panel = new Chat.Layouts.Panel({
                    model: this,
                    id: 'panel-' + this.cid
                }).on('render', function () {
                    setTimeout(function () {
                        App.layout.regionContent.currentView.workspace._ensureElement();
                        App.layout.regionContent.currentView.workspace.$el.scrollTo('#panel-' + that.cid, {duration: 400});
                    }, 200);
                });

                panel.render();
                App.layout.regionContent.currentView.workspace.show(panel);

                if (this.get('encrypted_chat')) {
                    panel.$el.html(
                        '<div class="private-caht-placeholder">' +
                            '<i class="zmdi zmdi-lock-outline"></i>' +
                            '<h1>Private Chat</h1>' +
                            '<div>This is a private chat and can only be unlocked from your mobile device.</div>' +
                         '</div>');

                    return;
                }

                var messages = this.get('messages');
                // unset all "append" attributes
                messages.invoke('set', 'append', false);

                function onMessages() {
                    if (panel.isDestroyed){
                        return;
                    }
                    var messagesView = new Chat.Views.Messages({
                        collection: messages
                    });
                    panel.messages.show(messagesView);
                    messagesView.scroll_to_latest_message({ forced: true });
                    that.set({
                        unread_count:
                        messages.where({consumed: false}).length
                    });
                }

                if (this.get('loaded_all_chats') === true) {
                    onMessages();
                } else {
                    if (messages.filter(function (msg) {
                        return !msg.get('recall_time') && !msg.get('hard_deleted') && !msg.get('deleted');
                    }).length) {
                        onMessages();
                    } else {
                        // load the chat's timeline, then render
                        messages.timeline({
                            chat: that,
                            success: onMessages
                        });
                    }
                }
            }

            App.vent.trigger('metrics:track', 'v4w/open_chat', {
                source: 'chat_list'
            });
        },
        save: function () {
            var that = this,
                post_data,
                recips = this.get('recipients'),
                thread_id,
                now = (new Date()).getTime() / 1000,
                deferred = $.Deferred();

            // include self in start_thread so other sessions get updated too
            if (recips.indexOf(App.Auth.get('rebelvox_user_id')) === -1) {
                recips.push(App.Auth.get('rebelvox_user_id'));
            }
            // set thread_id for new chats
            if (!this.get('thread_id')) {
                thread_id = this.generate_thread_id();
                this.set({
                        create_time: now,
                        creator: App.Auth.get('user_id'),
                        groups: this.get('groups'),
                        recipients: this.get('recipients'),
                        subject: this.get('subject'),
                        thread_id: this.get('thread_id')
                    });
            }
            // set subject for hotlines (created from contacts)
            if (!this.get('subject') && this.get('recipients').length === 2) {
                var other_id = _.without(this.get('recipients'), App.Auth.get('rebelvox_user_id'));
                var profile = App.profile.findWhere({user_id: other_id});
                var subject = profile.get('name');
                this.set({subject: subject});
            }

            if (that.get('thread_id').indexOf('TL_') === 0 && App.chats.get(that.get('thread_id'))
                    && !that.get('recipients').length) {
                deferred.resolve(this.attributes);
            } {
                post_data = {
                    "message_id": generate_message_id(),
                    "create_time": now,
                    "thread_id": that.get('thread_id'),
                    "creator" : App.Auth.get('rebelvox_user_id'),
                    "from" : App.Auth.get('rebelvox_user_id'),
                    "recipients": this.get('recipients'),
                    "groups": this.get('groups'),
                    "subject": this.get('subject'),
                    "content_type": "start_thread"
                };

                App.API._post_message(post_data, {
                    url: 'post_message',
                    home_router: App.Settings.get('home_router')
                }, function (xhr) {
                    deferred.reject(xhr);
                }, function (data) {
                    deferred.resolve(data);
                });
            }

            return deferred.promise();

        },
        // deleting all messages or leaving chat
        destroy: function (options) {
            var that = this, data, post_body, url = '';

            data = {
                "thread_id"     : this.get('thread_id'),
                "unconsumed_count": App.chats.get_total_unconsumed_count() - this.get('unread_count')
            };

            if (typeof options === 'string' && options === 'leave') {
                url = '2/cs/leave_chat';
            }
            else {
                url = 'chat/delete';

                delete localStorage['timeline_' + App.Auth.get('user_id') + data.thread_id]
            }
            var deferred = $.Deferred();
            App.API._post_message(data, {
                url: url,
                home_router: App.Settings.get('home_router')
            }, function (xhr) {
                deferred.reject(xhr);
            }, function (data) {
                App.chats.trigger('change:unread_count');
                deferred.resolve(data);
            });
            return deferred.promise();
        },
        consume_messages: function (msg_ids) {
            var message_ids = msg_ids || [];

            message_ids = _.isArray(message_ids) ? message_ids : [message_ids];

            if (message_ids.length === 0) {
                this.get('messages').each(function (m) {
                    if (m.get('consumed') !== true) {
                        message_ids.push(m.get('message_id'));
                    }
                });
            }

            var deferred = new $.Deferred();


            if (message_ids.length > 0) {
                App.API.consume_message({
                    message_ids: message_ids,
                    thread_id: this.get('thread_id'),
                    hwm: undefined,
                    unconsumed_count: App.chats.get_total_unconsumed_count() - message_ids.length
                }, {
                    router: App.Settings.get('home_router')
                }, function (value) {
                    deferred.reject(value);
                }, function (value) {
                    deferred.resolve(value);
                });

                return deferred.promise();
            }

            return deferred.resolve();
        },

        generate_thread_id: function () {
            var thread_id,
                recipients = this.get('recipients'),
                _recipients = [],
                groups = this.get('groups'),
                broadcast = this.get('broadcast'),
                index,
                me = App.Auth.get('rebelvox_user_id');
            if (recipients.indexOf(me) === -1) {
                recipients.push(me);
            }

            if (broadcast) {
                thread_id = 'BC_' + generate_message_id() + '__' + App.Auth.get('user_id');
            } else if (groups.length == 1) {
                var group = App.profiles.get(groups[0]);
                var members = group.get('members');
                if (members && members.indexOf(me) > -1) {
                    thread_id = 'TL_' + groups[0];
                } else {
                    thread_id = generate_message_id();
                }
            } else if (recipients.length > 2 || groups.length > 1) {
                thread_id = generate_message_id();
            } else {
                if (recipients.length === 1) {
                    recipients[1] = me; // this shall be known as note to self
                }
                thread_id = 'HL_' + [recipients[0], recipients[1]].sort().join('_');
            }
            this.set({
                thread_id: thread_id
            }, {silent: true});

            return thread_id;

        },
        play_chirp: function () {
            var playing,
                volume = App.Settings.set_volume(),
                sound = (this.get('_extremeNotificationsEnabled')) ? 'chirp' : 'chirp_4';

            // play sound only in append mode (i.e. when message came through update channel)
            // only play if message is sent by someone else
            if (this.get('last_message').from === App.Auth.get('rebelvox_user_id') ||
                    this.get('last_message').update !== true ||
                    this.get('_notificationsDisabled') ||
                    this.get('_muted') ||
                    this.get('_silent') ||
                    (this.get('active') && window.active && window.active === "visible") ||
                    !Settings.get('sound-on')){
                return
            }

            Object.keys(soundManager.sounds).some(function(key) {
                playing = soundManager.sounds[key].playState === 1;

                return playing;
            });

            // Don't play chirp if an sound is playing
            if (playing){
                return
            }
            try {
                App.sounds[sound].play({
                    volume: 100
                });
            }
            catch (err) {
                console.log(err);
            }

        },
        prepare_last_vox_snippet: function (message) {
            var show_emo = (message) ? false : true;
            var message = message || this.get('last_message'), text = '', type = '';

            type = message.content_type;
            text = message ? message.body : 'sent a vox snippet';
            if (type === 'text' && message.sub_content_type === "file_share") {
                text = 'Shared file';
            } else if (type === 'text') {
                text = !message.body || message.body === '' ? message.alt_text : message.body;
            } else if (type === 'image') {
                text = 'Shared image';
            } else if (type === 'audio') {
                text = 'Audio message';
            } else if (type === 'video') {
                text = 'Shared video';
            } else if (type === 'start_thread') {
                if (message.creator === App.Auth.get('rebelvox_user_id')) {
                    text = 'You';
                }
                else {
                    text = message.sender_name;
                }
                text += ' started the chat';
            }

            text = linkify(escapeHTML(text), 17);

            // Don't append <span> tags to emotifi desktop notifications
            if (show_emo) {
                text = minEmoji(text);
            }

            return text;
        },
        is_hotline: function () {
            return this.get('thread_id') && this.get('thread_id').indexOf('HL_') === 0;
        },
        is_profile: function () {
            return this.get('thread_id') && this.get('thread_id').indexOf('PC_') === 0;
        },
        is_broadcast: function () {
            return this.get('thread_id') && this.get('thread_id').indexOf('BC_') === 0;
        },
        is_favorited: function () {
            return this.get("thread_id") === "FC_" + App.Auth.get('user_id') + "_" + App.Auth.get('user_id')
        },
        prepare_subject: function () {
            var model = this,
                subject = model.get('subject'),
                thread_meta,
                invites =  model.get('invites');

            if (this.is_hotline()) {
                // hotlines are a special case: the chat subject is the name of the other party in the hotline
                var other = _.first(_.without(model.get('recipients'), App.Auth.get('rebelvox_user_id')));
                if (other) {
                    if (App.profiles.get(other)) {
                        subject = App.profiles.get(other).get('name');
                    } else {
                        subject = model.get('subject') ? model.get('subject') : other;
                    }
                }
                else {
                    if(invites.length == 1){
                        subject = invites[0].first;
                    }else{
                        subject = model.get('subject') ? model.get('subject') : 'Note to self';
                    }
                }
            }else {
                subject = Lib.truncate_string(subject, 50);
            }
            if(model.get('favorited')){
                subject = "Starred";
            }
            if(model.get('is_walkie')){
                subject = "Walkie";
            }

            return subject;
        },
        // helper to sort this mess with thread meta
        get_recipients: function () {
            var recipients = this.get('recipients'),
                thread_meta = this.get('thread_meta'),
                groups = this.get('groups'),
                me_id = App.MyProfile.get('user_id');

            groups = _.union(groups, thread_meta.groups);
            recipients = _.union(recipients, thread_meta.recipients);
            invites = _.union(this.get('invites'), thread_meta.invites).map(function (index, invite) {
                return invite.user_id;
            });

            return {
                recipients: recipients,
                groups: groups,
                invites: invites,
                to: _.without(_.union(recipients, groups, invites), me_id)
            };
        },
        get_participant_count: function () {
            return this.get_recipients().to.length;
        },
        set_unread_count: function () {
            var count = this.get('messages').where({consumed: false}).length;
            this.set({
                    unread_count: count
                });
            return count;
        },
        can_leave: function () {

        },
    });

    Chat.Messages = Backbone.Collection.extend({
        model: Message.Model,
        url: function (chat) {
            var last_message_time,
                url,
                options = {
                    "thread_id": chat.get('thread_id'),
                    "limit": 40
                };

            if (this.models.length !== 0) {
                last_message_time = _.min(this.pluck('normalized_create_time'));
            } else {
                last_message_time = right_time(chat.get('last_message'));
            }

            if (!chat.is_profile() && this.models.length) {
                options["end_time"] = last_message_time
            }

            url = App.create_url('3/cs/timeline', options);
            return url;
        },
        initialize: function () {
            // Hash of messages who have had there turn being played live.
            this.live_message = [];

            this.on('add', function (model, collection) {
                if (model.get('content_type') === 'audio') {
                    this.handle_audio(model);
                }
                if (!model.get('consumed')) {
                    var chat = App.chats.get(model.get('thread_id'));
                    if (chat) {
                        chat.set_unread_count();
                        model.set({'chat_name': chat.get('subject') || chat.get('title')});
                    }

                    if (chat && !chat.get('_notificationsDisabled')
                             && !chat.get('_muted')
                             && !chat.get('_silent')
                             && !chat.get('_mentionsEnabled')) {
                        Notifications.show_notification(model);
                    } else if (chat.get('_mentionsEnabled') && model.get('mentions')) {
                        if (_.map(model.get('mentions'), function (m) {
                            return m.user_id;
                        }).indexOf(App.Auth.get('rebelvox_user_id')) !== -1) {
                            Notifications.show_notification(model);
                        }
                    }
                }
            }, this);
        },
        add: function (model) {
            var models = _.isArray(model) ? model : [model];

            Backbone.Collection.prototype.add.call(this, models);
            // also make messages available globally for use in flash callbacks
            App.messages.add(models, {silent: true});
        },
        comparator: function (m) {
            return right_time(m.attributes);
        },
        timeline: function (options) {
            var that = this,
                length = that.models.length,
                url;

            var messages = Cache.fetch_chats(App.Auth.get('user_id'), options.chat.get("thread_id"));
            if (!length && messages && messages.length && !options.remote) {

                messages = _.without(messages, undefined);
                App.Message.route(messages, {live: false});

                if (options && options.success) {
                    options.success(messages);
                }

                if (messages.filter(function (msg) {
                    return ["text", "audio", "image", "video"].indexOf(msg.content_type) !== -1 &&
                        !msg.recall_time && !msg.hard_deleted && !msg.deleted; // Don't consider deleted or recalled messages as valid
                }).length) {
                    return; //return early if we have cached messages
                }
            }

            function fetch_remote_messages () {
                $.ajax({
                    type: 'GET',
                    url: that.url(options.chat),
                    dataType: "text",
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (data, textStatus, jqXHR) {
                        var models = data.split("\r\n");
                        models.pop();

                        models = _.map(models, function (model) {
                            var m = JSON.parse(model);
                            // filter blacklisted ops
                            if (m.op === 'put_message') {
                                if (['text', "audio", "audio_update", "image", "video"].indexOf(m.args.content_type) !== -1) {
                                    // remove falsely stored attributes
                                    if (m.args && m.args.append) {
                                        delete m.args.append;
                                    }
                                    if (m.args.from === App.Auth.get('user_id') || m.args.from === 'voxer.bot@voxer.com') {
                                        m.args.consumed = true;
                                    }
                                    if (m.args.revox !== undefined) {
                                        if (m.args.revox.audio_duration_ms) {
                                            m.args.audio_duration_ms = m.args.revox.audio_duration_ms;
                                        }

                                        if (m.args.revox.audio_length_bytes) {
                                            m.args.audio_length_bytes = m.args.revox.audio_length_bytes;
                                        }
                                    }


                                    return m.args;
                                } else if (["like_receipt", "read_receipt"].indexOf(m.args.content_type) !== -1) {
                                    return m.args;
                                } else if (["transcription_update"].indexOf(m.args.content_type) !== -1) {
                                    return m.args;
                                } else if (m.args.content_type === 'timeline_complete') {
                                    if (m.args.leftovers_time) {
                                        options.chat.set({'data_retention_policy': true}, {silent: true});
                                    }
                                }                            }
                        });
                        models = _.without(models, undefined);

                        if (!options.chat.is_profile()) {
                            App.Message.route(models, {live: false});
                        }

                        // messages from timeline are NOT from update channel
                        that.each(function (message) {
                            message.set({update: false}, {silent: true});
                        });

                        if (that.models.length === length) {
                            options.chat.set({'loaded_all_chats': true}, {silent: true});
                        } else {
                            options.chat.set({'loaded_all_chats': false}, {silent: true});
                            options.chat.trigger('change:consumed_count');
                        }

                        if (options && options.success) {
                            options.success(models);
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status === 402) {
                            App.Auth.getAuth({
                                success: function () {
                                    $('.navbar .badge').removeClass().addClass('badge badge-success');
                                    that.timeline(options);
                                },
                                error: function () {
                                    $('.navbar .badge').removeClass().addClass('badge badge-important');
                                    console.log("ERROR: attempt to restart update channel failed.");
                                }
                            });
                        }

                        if (options && options.error) {
                            console.log(options.errors)
                            options.error();
                        }
                    }
                });
            };

            if (App.Auth.get('Rv_session_key')) {
                fetch_remote_messages.call(this);
            } else {
                this.interval = setInterval(function () {
                    if (App.Auth.get('Rv_session_key')) {
                        clearInterval(this.interval);
                        fetch_remote_messages.call(this);
                    }
                }.bind(this), 500);
            }
        },

        /* HELPERS */
        handle_audio: function (model, live) {
            var chat = App.chats.findWhere({thread_id: model.get('thread_id')});

            if (((chat && chat.get('live_audio')) === true || live) &&
                model.get('from') !== App.Auth.get('rebelvox_user_id') &&
                model.get('update')) {

                // Check if this live message tried to sneak into the audio queue a second time
                if (this.live_message[model.get('message_id')] !== undefined) {
                    console.log('We are trying to double play a live message!');
                } else {
                    // Add the message id to a hash to check if it trys to skip in line
                    this.live_message[model.get('message_id')] = 1;
                    model.play_vox("live").done(function (data) {
                        //model.set({consumed: true});
                    });
                }
            }
        }
    });

    Chat.Collection = Backbone.Collection.extend({
        model: Chat.Model,
        comparator: function (model) {
            if (model.get('pinned_chat')) {
                return -(Date.now() / 1000 + right_time(model.get('last_message'), model.get('timestamp')));
            } else {
               return -right_time(model.get('last_message'), model.get('timestamp'));
            }
        },
        initialize: function () {
            var unknown_participants = [];
            var timer = null;

            this.on('add', function (model) {
                clearTimeout(timer);
                timer = setTimeout(this.sort.bind(this), 500);

                var recips = model.get_recipients().to;

                recips.forEach(function (participant_id) {
                    if (!App.profiles.get(participant_id))
                        unknown_participants.push(participant_id);
                });
            });

            this.on('change', function (model) {
                if (model.changed.timestamp && model.changed.timestamp !== model.attributes.timestamp) {
                    this.sort(); // sort works now
                } else if(model.changed.last_message) {
                    if (model.changed.last_message.posted_time !== model.previousAttributes().last_message.posted_time ||
                            model.changed.last_message.normalized_create_time !== model.previousAttributes().last_message.normalized_create_time ||
                            model.changed.last_message.create_time !== model.previousAttributes().last_message.create_time) {
                        this.sort(); // sort works now
                    }
                }
            }, this);

            App.vent.on('Updates.Channel:started', function onUpdatesChannelStarted() {
                // Let's remove duplicates
                unknown_participants = _.unique(unknown_participants);
                unknown_participants = unknown_participants.filter(function(item, pos) {
                    return !App.profiles.get(item);
                });
                unknown_participants = _.without(unknown_participants, undefined, "undefined", null);

                if (unknown_participants.length) {
                    App.profiles.get_profile(unknown_participants).always(function(profiles) {
                        App.vent.trigger('avatar_bust');
                    });
                } else {
                    App.vent.trigger('avatar_bust');
                }
                App.vent.off('Updates.Channel:started', onUpdatesChannelStarted);
            });
        },
        fetch: function (options) {
            var deferred = new $.Deferred(),
                collection = this, timeout;

            options = options || {};
            options.dataType = 'text';

            // a protection against backend not responding
            timeout = window.setTimeout(function () {
                deferred.reject(null, null, 'The request timed out. Please wait a few minutes, then reload');
            }, 10000);

            options.success = function (data, text, xhr) {
                window.clearTimeout(timeout);
                deferred.resolve(collection);
            };
            options.error = function (xhr, status, message) {
                deferred.reject(xhr, status, message);
            };
            var that = this;
            // to test slow loading times, wrap this into a setTimeout
            Backbone.Collection.prototype.fetch.call(that, options);
            return deferred.promise();
        },
        parse: function (data) {
            if (data === "") {
                data = "{}";
            }

            var chats = [], chat, is_deleted = false, message_sent_after_chat_deleted;

            data = JSON.parse(data);
            // loop through all threads
            Object.keys(data).forEach(function (thread_id) {
                chat = null;
                chat = data[thread_id];
                // filter out chats we do not want to see
                is_deleted = chat.deleted !== 0;
                message_sent_after_chat_deleted = chat.last_message && chat.last_message.normalized_create_time < chat.deleted;
                if (is_deleted && (message_sent_after_chat_deleted || chat.last_message === undefined || chat.left_chat > 0)) {
                    chat.is_deleted = true;
                }

                if (!chat.is_deleted) {
                    // set the thread_id
                    chat.thread_id = thread_id;

                    // determine the subject of the chat
                    if (chat.thread_meta && chat.thread_meta.subject && chat.thread_meta.subject !== "") {
                        chat.subject = chat.thread_meta.subject;
                    }
                    else if (chat.last_message && chat.last_message.subject && chat.last_message.subject !== "") {
                        chat.subject = chat.last_message.subject;
                    }
                    else {
                        chat.subject = "Unknown";
                    }

                    chat.messages = new Chat.Messages(chat.last_message);

                    // add object to array to be added to collection
                    chats.push(chat);
                }
            });

            return chats;
        },
        get_total_unconsumed_count: function () {
            var total = 0;
            if (this.models) {
                this.models.forEach(function (chat) {
                    total += chat.get('unread_count');
                });
            }
            return total;
        }
    });

    Chat.Layouts.List = M.LayoutView.extend({
        template: 'chat/list_layout',
        className: 'panel panel-default',
        regions: {
            selector: '#chat-selector',
            list: '#chats-list'
        },
        events: {
            "click .new_chat": "handle_start_new_chat"
        },
        initialize: function () {
            App.chats.on('add', function () {
                try {
                    App.layout.regionHeader.currentView.menuRegion.currentView.handle_consume();
                }catch (e) {

                }
            }, this);
        },
        onShow: function () {

        },
        onRender: function () {
        },
        handle_start_new_chat: function (e) {
            e.preventDefault();
            /* close all chats each time you open a chat */

            App.chats.where({active: true}).forEach(function (chat) {
                var view = App.UI.ViewsContainer.findByModelCid(chat.cid);

                if (view)
                    view.$el.hide();

                // unset the active flag
                chat.set({active: false});
                chat.set({was_active: true});
            });

            /* close all chats each time you open a chat */
            App.Settings.set({'finished_setup': true});
            App.vent.trigger("Chat:create_new");
        }
    });

    //A single chat
    Chat.Views.Item = M.ItemView.extend({
        template: "chat/chats_list_item",
        tagName: 'li',
        id: function () { return this.model.cid; },
        initialize: function () {
            var onAvatarBoost = _.debounce(function avatar_bust() {
                if (!this.isDestroyed) {
                    this.render();
                } else {
                    App.vent.off('avatar_bust', onAvatarBoost, this);
                }
            }, 250);
            App.vent.on('avatar_bust', onAvatarBoost, this);

            if (this.model.is_favorited()) {
                function onUnstared() {
                    this.model.set({'last_message': false});

                    if (!this.isDestroyed) {
                        this.render();
                    } else {
                        App.vent.off('unstared', onUnstared, this);
                    }
                }
                App.vent.on('unstared', onUnstared, this);
            }
        },
        className: function () {
            var classnames = ['chat-item'];

            if (this.model.get('active')) {
                classnames.push('active');
            }
            if (this.model.get('receiving')) {
                classnames.push('receiving-vox');
            }
            if (this.model.is_profile()) {
                classnames.push('is_my_timeline');
            }
            if (this.model.is_broadcast()) {
                classnames.push('broadcast');
            }
            if (this.model.get('encrypted_chat')) {
                classnames.push('encrypted');
            }
            if (this.model.get('live_audio')) {
                classnames.push('live_audio');
            }
            return classnames.join(' ');
        },
        events: {
            "click .heard-chat": "handle_consume_all",
            "click .delete-chat": "handle_delete_chat",
            "click": "handle_click"
        },
        modelEvents: {
            "change:active": "active_change",
            "change:pinned_chat": "render",
            "change:unread_count": "handle_consume",
            "change:live_audio": "handle_live_mode",
            "change:receiving": "handle_receiving",
            "remove": "handle_remove",
            "change:last_message": "render",
            "change:title": "handle_change_title",
            "change:subject": "handle_change_title"
        },
        handle_change_title: function () {
            this.render();
        },
        handle_remove: function () {
            this.destroy();
        },
        active_change: function (model) {
            var active_array = Settings.fetch('active_chats');
            try {
                active_array = JSON.parse(active_array);
            } catch (e) {
                active_array = {};
            }

            if (active_array !== undefined && typeof active_array === 'object') {
                active_array = _.clone(active_array);
            } else {
                active_array = {};
            }

            if (model.changed.active === true) {
                active_array = {};
                active_array[this.model.get('thread_id')] = (new Date()) / 1000;
                Settings.set({ 'active_chats': JSON.stringify(active_array)});
                this.$el.addClass('active');
                App.Settings.set({'finished_setup': true});

                // When another chat becomes active pause all ongoing sounds
                soundManager.pauseAll();
            }
            else {
                delete active_array[this.model.get('thread_id')];
                Settings.set({ 'active_chats': JSON.stringify(active_array) });
                this.$el.removeClass('active');
            }
            //debugger;
            this.render();
        },
        handle_consume: function (model) {
            if (!this.isDestroyed) // Don't rerender an view that has been distroyed
                this.render();
        },
        handle_live_mode: function () {
            if (this.model.get('live_audio') === true) {
                this.$el.addClass('live_audio');
            }
            else {
                this.$el.removeClass('live_audio');
            }
            this.render();
        },
        handle_receiving: function () {
            if (this.model.get('receiving') === true) {
                this.$el.addClass('receiving-vox');
            }
            else {
                this.$el.removeClass('receiving-vox');
            }
        },
        serializeData: function () {
            // find profile
            var model = this.options.model,
                data, profile,
                last_message = model.get('last_message'),
                subject = model.prepare_subject(),
                last_vox_time, text = '', type = 'text',
                avatar_src, to, last_message_sender, sender, from, revox, id,
                icons = 0;


            from = last_message ? (last_message.revox ? last_message.revox.from : last_message.from) : '';

            if (!last_message && model.get('messages').length > 0) {
                last_message = model.get('messages').last().attributes;
                model.set({last_message: last_message});
            } else if (last_message && model.get('messages').length &&
                last_message.message_id !== model.get('messages').last().get('message_id')) {
               last_message = model.get('messages').last().attributes;
               model.set({last_message: last_message});
            }

            this.model.set({unread_count: this.model.get('messages').where({consumed: false}).length});

            // last vox timestamp
            // if there is a timestamp, we'll use it wether or not the message is a subcontent_type message
            if (model.get('timestamp') !== undefined) {
                last_vox_time = format_message_timestamp(model.get('timestamp'));
            }
            if (last_message && !_.isEmpty(last_message)) {
                last_message_sender = App.profiles.get(from);
                last_vox_time = format_message_timestamp(right_time(last_message, last_vox_time));

                type = last_message.content_type;
                text = model.prepare_last_vox_snippet();

                id = last_message.message_id;
            }
            else {
                to = _.without(model.get('recipients'), App.Auth.get('rebelvox_user_id'));
                last_message_sender = !_.isEmpty(last_message) ? App.MyProfile : undefined;
            }


            if (this.model.is_hotline()) {
                profile = App.profiles.get(_.without(model.get('recipients'), App.Auth.get('rebelvox_user_id'))[0]);
                if (!profile && model.get('invites') && model.get('invites').length) {
                    var invitee = model.get('invites')[0];

                    invitee.user_id = invitee.suggested_user_id;
                    invitee.name = invitee.name ? invitee.name : invitee.first + " " + invitee.last;
                    profile = new App.Profile.Model(invitee);
                    App.profiles.add(invitee);
                }

                avatar_src = profile ? profile.avatar_src(null, true) : "/assets/img/person.png";
            } else if (this.model.is_favorited()) {
                avatar_src = '/assets/img/chatlist-favorites-circle.png';
            } else if (!this.model.get('groups').length &&
                       this.model.get('recipients').length === 1 &&
                       this.model.get('recipients')[0] === App.Auth.get('rebelvox_user_id')) {
                // Note to self
                avatar_src = App.MyProfile.avatar_src(null, true);
            } else {
                var stored_recipients = this.model.get('stored_recipients') || [];
                avatar_src = [];

                if (stored_recipients.length < 3) {
                    stored_recipients = stored_recipients.concat(this.model.get('recipients').slice(0, 3));
                }

                if (last_message_sender) {
                    stored_recipients = _.without(stored_recipients, last_message_sender.get('user_id'));
                    stored_recipients.unshift(last_message_sender.get('user_id'));
                }
                stored_recipients = stored_recipients.slice(0, 3);

                stored_recipients.forEach(function(id) {
                    var profile = App.profiles.get(id),
                        avatar = (profile) ? App.profiles.get(id).avatar_src(null, true) : "/assets/img/person.png";
                    avatar_src.push(avatar);
                });
                this.model.set({stored_recipients: stored_recipients}, {silent: true});
            };


            if (last_message.system) {
                sender = '';
            } else if (last_message_sender === undefined) {
                if (last_message.sender_name) {
                    sender = last_message.sender_name;
                } else if (from) {
                    sender = from;
                }
                else {
                    sender = '';
                }
            } else {
                sender = (from === App.MyProfile.get('user_id') ? 'Me' : last_message_sender.get('first'));
            }

            // figure out if we should show the sender (leave chat messages)
            if (model.get('last_message') && model.get('last_message').subcontent_type === 'remove_participant') {
                sender = '';
            }
            // last message snippet to show

            if (model.get('last_message').revox && !model.get('favorited')) {
                if (model.get('last_message').revox.from === App.Auth.get('user_id')) {
                    sender = 'You';
                }
                else {
                    sender = model.get('last_message').revox.sender_name;
                }
                if (model.get('last_message').from === App.Auth.get('user_id')) {
                    revox = 'You';
                }
                else {
                    revox = model.get('last_message').sender_name;
                }
                icons += 1;
            } else {
                revox = false;
            }

            sender = escapeHTML(sender);

            if (model.get('unread_count'))
                icons += 1;

            if (model.get('live_audio'))
                icons += 1;

            data = {
                subject: escapeHTML(subject),
                encrypted_chat: model.get('encrypted_chat') || false,
                pinned_chat: model.get('pinned_chat') || false,
                zapier_chat: model.get('zapier_chat') || false,
                avatar_src: avatar_src,
                last_vox_from: escapeHTML(from),
                last_vox_prefix: escapeHTML(sender ? sender + ": " : ""),
                last_vox_text: text || "",
                last_vox_timestamp: last_vox_time,
                my_message: last_message &&
                    (last_message.from === App.Auth.get('user_id') ||
                        (last_message.revox && last_message.revox.from === App.Auth.get('user_id'))),
                is_hotline: model.is_hotline(),
                iconPlayClass: (soundManager.getSoundById(id) && soundManager.getSoundById(id).playState) ? 'zmdi-pause' : 'zmdi-play',
                revox: revox,
                active: model.get('active'),
                unread_count: model.get('unread_count'),
                type: type,
                icons: icons,
                is_broadcast: model.is_broadcast(),
                id: id
            };

            return data;
        },

        /* EVENTS */
        handle_click: function (e) {
            // Clear the search bar
            if ($('.navbar-search input').val())
                $('.navbar-search input').val('').trigger('keyup', e);

            this.model.handle_active(e);
        },
        onRender: function () {
            this.$('img.avatar').on('error', function () {
                $(this).attr('src', '/assets/img/person.png');
            });
        },
        handle_consume_all: function () {
            var that = this;
            this.model.consume_messages().done(function (data) {
                that.model.get('messages').each(function (m) {
                    m.set({'consumed': true});
                });
            }).fail(function (xhr) {
                    // debugger;
                    // notification maybe??
            });
        },
        handle_delete_chat: function (e) {
            var modal = new Chat.Views.TaskModal({
                model: this.model,
                task: 'delete'
            });
            App.layout.regionIntro.show(modal);
        }
    });

    Chat.Views.NoItemsView = M.ItemView.extend({
        template: "chat/no-chats-template"
    });

    //Chats list
    Chat.Views.List = M.SlidingView.extend({
        template: "chat/chats_list",
        tagName: 'ul',
        className: 'chat-list',
        childView: Chat.Views.Item,
        reorderOnSort: true,
        indexOffset: 10,
        initialLowerBound: 0,
        initialUpperBound: function() {
            return Math.floor(this.options.viewPort.height() / 71) + this.indexOffset;
        },
        getLowerBound: function() {
            var start = Math.floor(this.options.viewPort.scrollTop() / 71);

            start -= this.indexOffset;

            if (start < 0) {
                start = 0;
            }

            return start;
        },

        getUpperBound: function(lowerBound) {
            var contained = Math.floor(this.options.viewPort.height() / 71);

            // Multiply the offset by 2 to account for the start index
            end = lowerBound + contained + 2 * this.indexOffset;

            return end;
        },
        registerUpdateEvent: function() {
            var self = this;
            this.options.viewPort.on('scroll', function() {
                if (self.referenceCollection.filter_where && self.referenceCollection.filter_where.length)
                    return;
                self.onUpdateEvent();
            });
        },
        pruneCollection: function(lowerBound, upperBound, collection) {
            collection = collection || this.referenceCollection;

            if (collection.filter_where && collection.length) {
                if (Object.keys(collection.filter_where).length)
                    this._search = true;

                Object.keys(collection.filter_where).forEach(function (key) {
                    collection = collection.filter(collection.filter_where[key]);
                });
            }

            if (upperBound >= collection.length)
                upperBound = collection.length;

            return collection.slice(lowerBound, upperBound)
        },
        onUpdateEvent: function() {
            var self = this;
            requestAnimationFrame(function() {
                self.throttledUpdateHandler();
            });
        },
        emptyView: function () {
            return new Chat.Views.NoItemsView();
        },
        onRender: function () {
            // make sure avatars render unbroken if no profile image exists on the backend
            this.$('img.avatar').on('error', function () {
                $(this).attr('src', '/assets/img/person.png');
            });
        },
        collectionClass: Backbone.Collection.extend({
          comparator: function (model) {
            if (model.get('pinned_chat')) {
                return -(Date.now() / 1000 + right_time(model.get('last_message'), model.get('timestamp')));
            } else {
               return -right_time(model.get('last_message'), model.get('timestamp'));
            }
          }
        }),
        _updateCollection: function _updateCollection(collection) {
          var comparator;
          this.collection.set(this.pruneCollection(0, this._upperBound, collection));

          if (this._search) {
            this._search = false;
            comparator = this.collection.comparator;
            this.collection.comparator = function (model) {
                if (model.is_hotline()) {
                    return -(Date.now() / 1000 + right_time(model.get('last_message'), model.get('timestamp')));
                } else {
                    return -right_time(model.get('last_message'), model.get('timestamp'));
                }
            }
          }
          this.collection.sort();

          if (comparator)
            this.collection.comparator = comparator;
        },
        initialize: function () {
            var that = this;

            this.referenceCollection.on('change add sort', _.debounce(function () {
                that._updateCollection();
            }, 100));

            this.referenceCollection.on('apply:filter', _.debounce(function () {
                that._updateCollection();
            }, 250));
        }
    });

    Chat.Views.Messages = M.CollectionView.extend({
        tagName: 'ul',
        className: 'chat-messages loading-images',
        childView: Message.Views.Message,
        reorderOnSort: true,
        onReorder: function () {
            this.scroll_to_latest_message({duration:0});
        },
        childViewOptions: function (model) {
            var chat = App.chats.get(model.get('thread_id'));
            return {
                indexInCollection: this.collection.indexOf(model),
                chat: chat
            };
        },
        emptyView: Chat.Views.NoItemsView,
        collectionEvents: {
            "change:update": "handle_audio_message_update",
            "added:message": "handle_manual_message_render"
        },
        onAddChild: function (view) {
            var view = view;

            if (view.model.get('append') === true) {
                this.scroll_to_latest_message();
            }

            // If the normalize time changed the order we will have to re-render
            // so we won't have the miss-sorted messages
            if (!view.modelEvents)
                view.modelEvents = {};
            view.modelEvents["change:normalized_create_time"] = function change_nct() {
                var last_message = this.collection.last().id,
                    last_message_sort = this.collection.sort().last().id;

                // off the listener
                delete view.modelEvents["change:normalized_create_time"];
                if (last_message !== last_message_sort)
                    this.render();
            }.bind(this);
        },
        onRender: function () {
            var loaders = [];
            // let's do this to avoid the silly non-scrolling:
            // do not lazyload initial images
            // pack them in deferreds and show images when all have loaded....

            this.$('img.voxer-image').each(function (idx, img) {
                $(img).removeClass('lazy');
                $(img).attr('src', $(img).data('original'));
                loaders.push($.image(img));
            });

            // proxy the function to pass the right context ($.when swallows context completely)
            var bound = $.proxy(function () {
                // show the content in all its glory and scroll down.
                this.$el.removeClass('loading-images');
                this.scroll_to_latest_message({ forced: true, duration: 0});
            }, this);
            $.when.apply(null, loaders).done(bound);

            // make sure avatars render unbroken if no profile image exists on the backend
            this.$('img.avatar').on('error', function () {
                $(this).attr('src', '/assets/img/person.png');
            });
        },
        onShow: function () {
            // We have to make a new thread for the scroll animation
            setTimeout(function () {
                this.scroll_to_latest_message({ forced: true });
            }.bind(this), 0);
        },
        handle_audio_message_update: function () {
            // when the audio message transitions from audio recording to finished audio
            // we need to scroll ddown all the way, otherwise parts of the message get hidden
            this.scroll_to_latest_message();
        },
        handle_manual_message_render: function (id) {
            var childView = new Message.Views.Message({model: this.collection.get(id)});
            this.attachHtml(this, childView);
        },
        initialize: function () {
            // when the panel has been shown, scroll to bottom
            this.on('show', function () {
                var that = this;
                that.$el.find('img.lazy').lazyload({
                    container: that.$el.closest('.panel-body'),
                    threshold: 200,
                    effect: "fadeIn",
                    load: function (elements_left, settings) {
                        // set dimensions of image
                        $(this).width(this.naturalWidth).height(this.naturalHeight);
                    }
                });
                setTimeout(function () {
                    that.$el.closest('.panel-body').trigger('scroll');
                }, 300);
            }, this);
        },
        scroll_to_latest_message: _.debounce(function (options) {
            options = options || {};
            var $scroll = this.$el.closest('.panel-body'),
                scrollHeight = $scroll.prop("scrollHeight"),
                scrollTop = $scroll.prop("scrollTop"),
                clientHeight = $scroll.prop("clientHeight");

            if (scrollHeight > scrollTop + clientHeight + 450 &&
                    !options.forced && App.Settings.get('first_render'))
                return;

            options = _.extend({
                duration: 150
            }, options);

            $scroll.animate({ scrollTop: scrollHeight }, options);
        }, 50)
    });

    // chats list dropdown menu
    Chat.Views.Selector = M.ItemView.extend({
        template: "chat/chat-selector",
        className: 'chat-selector',
        events: {
            "click li a": "handle_click",
            "keyup .navbar-search": "handle_search",
            "click .navbar-search .icon-remove-sign": "handle_clear_search",
            "submit": function (e) {e.preventDefault(); }
        },
        initialize: function () {

        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
        },
        serializeData: function () {
            return this.options;
        },
        handle_click: function (e) {
            e.preventDefault();

            var $button = this.$('button span:first'),
                // the current selection
                text = $button.html(),
                // the unselected selection
                $menuitem = $(e.currentTarget);

            // change the dropdown texts
            $button.html($menuitem.html());
            $menuitem.html(text);

            if ($button.html().indexOf('All') !== -1) {
                delete App.chats.filter_where['filter'];
            }
            else {
                if (!App.chats.filter_where) {
                    App.chats.filter_where = [];
                }
                // set a flag on the collection representing the filterstate
                App.chats.filter_where['filter'] = function (c) {
                    return c.get('unread_count') > 0;
                };
            }
            App.chats.trigger('apply:filter');
        },
        handle_search: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var query = this.$('.navbar-search input').val().toLowerCase();

            if (e.which !== 13) {
                if (e.which === 27) {
                    this.$('.navbar-search input').val("");
                    query = "";
                }
                if (query.length === 0) {
                    //$('.chat-list li').removeClass('hide');
                    this.$('.navbar-search .icon-remove-sign').addClass('hide');
                    delete App.chats.filter_where['search'];
                } else {
                    this.$('.navbar-search .icon-remove-sign').removeClass('hide');

                    if (!App.chats.filter_where) {
                        App.chats.filter_where = [];
                    }
                    var matched_profiles = App.profiles.without(App.profiles.get(App.Auth.get('user_id'))).filter(function(profile) {
                        return profile.name().toLowerCase().indexOf(query) !== -1;
                    });
                    matched_profiles = matched_profiles.map(function (profile) {
                        return profile.get('user_id')
                    });

                    App.chats.filter_where['search'] = function (c) {
                        var title = c.get('subject') || c.get('title') || c.get('thread_id');

                        return title.toLowerCase().indexOf(query) !== -1 || c.get("recipients").filter(function (user_id) {
                            return matched_profiles.indexOf(user_id) !== -1;
                        }).length;
                    };
                }
            }
            App.chats.trigger('apply:filter');
        },
        handle_clear_search: function (e) {
            e.preventDefault();
            this.$('input').val("");
            this.$('input').focus();
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
            this.handle_search(e);
            $(e.currentTarget).addClass('hide');
            App.chats.trigger('apply:filter');
        }
    });

    Chat.Layouts.Panel = M.LayoutView.extend({
        template: 'chat/panel/panel',
        className: function () {
            var classnames = 'chat-panel panel panel-default';
            if (this.model.is_hotline()) {
                classnames += ' hotline';
            }

            return classnames;
        },
        events: {
            "click .button.vox-text": "handle_send_vox_button",
            "keypress div.vox-text": "handle_send_vox",
            "keyup div.vox-text": "handle_keyup",
            "keydown div.vox-text": "handle_keydown",
            "paste div.vox-text": "handle_paste",
            "click .text-container": "toggle_textarea",
            "click #cancel-reply": "cancel_reply",
            // menu
            "click .panel-menu .chat-users": "handle_chat_users",
            "click .menu-admin-control": "toggle_admin_control",
            "click .menu-zapier-control": "toggle_zapier_control",
            "click .menu-allconsumed": "handle_consume_all",
            "click .share-chat-link": "handle_share_chat",
            "click .menu-leavechat": "handle_leave_chat",
            "click .menu-delete": "handle_delete_chat",
            "click .menu-livemode": "handle_live_mode",
            "click .chat_title": "handle_change_chat_title",
            "click .panel-menu .pin-chat-icon": "handle_pin_chat",
            "click .icon-person": "handle_chat_users",
            "click .chat-ptt button.vox-voice": "handle_browser_support",
            "click .pick-user .contact-item": "handle_user_tag_pick",
            "mousedown .chat-ptt button.vox-voice": "handle_recording_status",
            "mouseup .chat-ptt button.vox-voice": "handle_recording_status",
            "click .dropbox-chooser": "handle_dropbox",
            // "click .google-chooser": "handle_google_drive",
            "click .gif-button": "handle_gif_modal",
            "wheel .panel-body": "wheelScrollHandler",
            "click #data-retention-upgrade": "handle_data_retention_upgrade",
            "click .text-container .emoticons span.em": "handle_pick_emoticon",
            "blur h1 input": "handle_change_title",
            "click .notification-level": "handle_notification_level",
            "click .pop-out-chat": "handle_pop_out_chat",
            "click .pop-in-chat": "handle_pop_in_chat",
            "keyup h1": "handle_change_title",
            "click h1": "handle_change_chat_title_top",
            "click .chat-settings-menu": "handle_chat_settings_menu",

        },
        modelEvents: {
            "change:title": "handle_model_change_title",
            "change:active": "handle_active_change",
            "change:controlled_chat": "handle_model_change_admin",
            "change:zapier_chat":"handle_model_change_zapier",
            "change:replies_to": "handle_model_chagne_reply",
        },
        regions: {
            avatar: '.panel-avatar',
            title: '.panel-title',
            messages: '.panel-messages',
            contacts: '.pick-user ul',
            participants: '.participants'
        },
        ui: {
            chat_interaction: '.chat-interact',
            panel_menu: '.panel-menu'
        },
        handle_change_chat_title_top:function(e){
            if( (this.model.get('creator') == App.Auth.get('user_id')|| !this.model.get('controlled_chat'))
                && ( this.model.get_participant_count() > 2 ||  !(this.model.is_hotline() || this.model.get('favorited')) )
                && this.$el.find('.new-chat-title').length == 0) {
                this.handle_change_chat_title(e);
            }
        },
        initialize: function () {
            var that = this,
                unknown_participants = [];

            this.analyser = (App.VoxerAudio.audioContext) ? App.VoxerAudio.audioContext.createAnalyser() : null;
            if (this.analyser) {
                this.analyser.minDecibels = -90;
                this.analyser.maxDecibels = -10;
                this.analyser.smoothingTimeConstant = 0.85;
            }

            this.is_loading = true;
            this.messages_to_consume = [];
            this.search_querry = "";
            this.recipients = _.map(this.model.get_recipients().recipients, function (user_id) {
                return App.profiles.get(user_id) || user_id;
            }).filter(function(recipient) {
                if (typeof recipient === "string")
                    unknown_participants.push(recipient);

                return typeof recipient === "object";
            });

            if (unknown_participants.length) {
                App.profiles.get_profile(unknown_participants).always(function(profiles) {
                    that.recipients = _.map(that.model.get_recipients().recipients, function (user_id) {
                        return App.profiles.get(user_id) || user_id;
                    }).filter(function(recipient) {
                        return typeof recipient === "object";
                    });

                    // Reset the list
                    if (that.conview) {
                        that.conview.collection = new Backbone.Collection(that.recipients);
                        that.conview.render();
                    }
                });
            }
        },
        onRender: function () {
            var that = this,
                Conview = Contact.Views.List.extend({
                    onBeforeRender: undefined,
                    childView: Contact.Views.Item.extend({
                        events: { }
                    }),
                    onRender: function () {
                        margin = this.collection.length ? this.collection.length * 65 + 2 : 33;
                        margin = (margin < 400) ? margin : 400;
                        that.$el.find('.pick-user').css('margin-top', '-' + margin + 'px');
                        if (this.options.parent) {
                            this.options.parent.html(this.$el.html());
                        }
                        that.$el.find('.contact-item:first-of-type').addClass('selected');
                        var contact_items = that.$el.find('.contact-item');
                        contact_items.on('mouseenter', function () {
                            contact_items.removeClass('selected');
                            this.className += ' selected';
                        });
                    },
                    emptyView: function () {
                        return new Contact.Views.NoItemsView();
                    }
                });

            if (!this.model.is_hotline()) {
                this.conview = new Conview({
                    collection: new Backbone.Collection(this.recipients)
                });
                this.contacts.show(this.conview);
            }

            // Don't add it if it has an opener window, it will overwrite the real view
            if (App.UI && !this.opener) {
                // add view to global view babysitter for easier access later
                App.UI.ViewsContainer.add(this);
            }

            //this.model.set({id: -1, consume_ready:false});
            $(this.$el.find('.panel-body')).on('scroll', {
                that: this
            }, this.scrollHandler);
            that.$el.find('[data-toggle="tooltip"]').tooltip({ 'delay': { show: 1500, hide: 100 } });
            this.canvas = this.$el.find('canvas')[0];
            this.canvasCtx = this.canvas.getContext('2d');

            that.$el.find('div.vox-text').on('input', function(e) {
                if(e.target.textContent == '' || e.target.textContent == " " ) {
                    that.$el.find('.button.vox-voice').removeClass('flipOutX').addClass('flipInX');
                    that.$el.find('.button.vox-text').removeClass('flipInX').addClass('flipOutX');
                    that.$el.find('.chat-interact').css('height', '100px');
                }else{
                    that.$el.find('.button.vox-voice').removeClass('flipInX').addClass('flipOutX');
                    that.$el.find('.button.vox-text').removeClass('flipOutX').addClass('flipInX');
                    that.$el.find('.chat-interact').css({'height': 'auto'});
                }
            });
        },
        handle_active_change: function(model, active) {
            if (!active) {
                this.handle_recording_status({isPressed: false});

                var playing = this.$el.find('.zmdi-pause');
                if (playing.length !== 0) {
                    this.$el.find('.zmdi-pause').each(function (i, icon) {
                        var sound = App.VoxerAudio.live_audio_playing[
                            $(icon).closest('.message-item').attr("id")
                        ];
                        if (sound) {
                            sound.pause();
                        }
                    });
                }
            } else {
                try {
                    var view = this.regionManager.get('messages') &&
                               this.regionManager.get('messages').currentView;
                    this.$el.show();

                    if (this.model.get('encrypted_chat')) {
                        this.$el.html(
                            '<div class="private-caht-placeholder">' +
                                '<i class="zmdi zmdi-lock-outline"></i>' +
                                '<h1>Private Chat</h1>' +
                                '<div>This is a private chat and can only be unlocked from your mobile device.</div>' +
                             '</div>'
                        );

                        return;
                    }

                    if (!view) {
                        var view = new Chat.Views.Messages({
                            collection: this.model.get('messages')
                        });

                        if (!this.regionManager.get('messages')) {
                            App.layout.regionContent
                               .currentView.workspace.show(this);
                        }

                        this.messages.show(view);
                        view.scroll_to_latest_message({ forced: true });
                    } else if (this.model.get('unread_count')) {
                        view.scroll_to_latest_message({ forced: true });
                    }

                    this.$el.find('.text-container .vox-text').get(0).focus();
                } catch (e) {
                    console.warn(e);
                    this.destroy();
                }
            }
        },
        handle_model_change_admin: function(model, mode) {
            if (mode && model.get('creator') !== App.Auth.get('user_id')) {
                this.$el.find('.dropdown-menu .chat_title').hide();
                this.$el.find(".share-chat-link").hide();
            } else {
                this.$el.find('.dropdown-menu .chat_title').show();
                this.$el.find(".share-chat-link").show();
            }

            if (mode) {
                this.$el.find('.menu-admin-control i')
                    .removeClass('zmdi-lock-open').addClass('zmdi-lock-outline');
            } else {
                this.$el.find('.menu-admin-control i')
                    .removeClass('zmdi-lock-outline').addClass('zmdi-lock-open');
            }
        },
        handle_model_change_zapier: function (model, mode) {
          if (mode) {
            this.$el
              .find(".menu-zapier-control i")
              .removeClass("zmdi-circle-o")
              .addClass("zmdi-dot-circle");
          } else {
            this.$el
              .find(".menu-zapier-control i")
              .removeClass("zmdi-dot-circle")
              .addClass("zmdi-circle-o");
          }
        },
        handle_model_change_title: function(model, title) {
            this.$el.find('.panel-heading h1').text(title);
        },
        toggle_admin_control: function (e) {
            var controlled = this.model.get('controlled_chat'),
                thread_id = this.model.get('thread_id'),
                post_data = {
                    controlled: !controlled,
                    thread_id: thread_id
                };

            App.API.controlled_chat(post_data, {
                router: App.Settings.get('home_router')
            });

            App.vent.trigger('metrics:track', '/admin_control', {
                state: (post_data.controlled) ? 'on' : 'off'
            });
            App.vent.trigger('metrics:register', {
                hasUsedAdminControl: true
            });
        },
        toggle_zapier_control: function (e) {
          var zapped = this.model.get("zapier_chat"),
          that = this;
          var is_pro = false;
          var changes = {};

          changes[this.model.get("thread_id")] = {
            add: zapped ? [] : ["zapier_chat"],
            remove: zapped ? ["zapier_chat"] : [],
          };

          App.API.mod_thread_tags(
            {
              changes: changes,
            },
            {
              home_router: Settings.fetch("home_router"),
              query: {
                user_id: App.Auth.get("user_id"),
              },
            },
            function (err) {
              console.error(err);
            },
            function (data) {
              if (data.args && data.args.success) {
                if (zapped) {
                  $(e.target).removeClass("active");
                  that.model.set({ zapier_chat: false });
                } else {

                  $(e.target).addClass("active");
                  that.model.set({ zapier_chat: true });
                }
              }
            }
          );
          App.vent.trigger("metrics:track", "/zapier_chat");
        },
        handle_model_chagne_reply: function () {
            var reply_info = this.model.get('replies_to'),
                infoView = this.$(".reply-info");

            if (reply_info.message_id) {
                var sender_name = reply_info.sender_name;
                var preview_content = '';
                if (!sender_name) {
                    sender_name = 'Me';
                    reply_info.sender_name = App.MyProfile.get('display_name');
                }

                this.model.set('replies_to', reply_info);
                // render reply info
                this.$el.find('.panel-body').addClass('active-reply');
                this.$el.find('.chat-ptt').addClass('active-reply');

                infoView.find("#reply-message-id").text(reply_info.message_id);
                infoView.find("#reply-message-sender").text(`Reply to ${sender_name}:`);
                infoView.find("#reply-image-preview").addClass('hide');

                switch (reply_info.content_type) {
                    case 'audio':
                        preview_content = `
                            <span style="display: inline-flex; align-items: center; margin-top: 2px;">
                                <img src="/assets/img/microphone_icon.png" alt="Audio" style="width: 16px; height: 16px; vertical-align: middle; margin-right: 5px; display:inline-block;" />
                                ${reply_info.duration}
                            </span>`;
                        break;
                    case 'image':
                        const image_url = App.create_url('get_body', {'message_id': reply_info.message_id});
                        infoView.find("#reply-image-preview").attr("src", image_url);
                        infoView.find("#reply-image-preview").removeClass('hide');
                        preview_content = 'Image';
                        break;
                    default: 
                        preview_content = reply_info.body;
                        break;
                }
                
                infoView.find("#reply-message-preview").html(preview_content);
                infoView.removeClass('hide');
            } else {
                this.cancel_reply();
            }
        },
        cancel_reply: function () {
            const infoView = this.$(".reply-info");
            this.$el.find('.panel-body').removeClass('active-reply');
            this.$el.find('.chat-ptt').removeClass('active-reply');
            this.model.set('replies_to', {});
            infoView.addClass('hide');
        },
        toggle_textarea: function (e) {
            $(e.currentTarget).find('div.vox-text').get(0).focus();
        },
        scrollHandler: function (e) {
            var that = e.data.that,
                context = this,
                messages = that.model.get('messages') ? that.model.get('messages').models : [];

            messages = _.filter(messages, function (value, key, list) {
                if (!value.get('consumed') && (value.get('content_type') !== 'audio')) {
                    return that.visibleMessages($('#' + value.get('message_id')));
                }
                return false;
            });
            messages = _.invoke(messages, 'get', 'message_id');
            that.messages_to_consume = that.messages_to_consume.concat(messages);
            that.messages_to_consume = _.uniq(that.messages_to_consume);

            if (that.ready) {
                that.consume();
            }
            that.checkScroll(e, context);
        },
        wheelScrollHandler: function (e) {
            var that = this,
                messages = messages = this.model.get('messages').models;
            messages = _.filter(messages, function (value, key, list) {
                if (!value.get('consumed') && (value.get('content_type') !== 'audio')) {
                    return that.visibleMessages(that.$el.find('#' + Lib.sanitize_message_id(value.get('message_id'))));
                }
                return false;
            });
            messages = _.invoke(messages, 'get', 'message_id');
            this.messages_to_consume = this.messages_to_consume.concat(messages);
            this.messages_to_consume = _.uniq(this.messages_to_consume);
            that.consume();

            var lastIsVisible = this.visibleMessages(this.$el.find(".panel-body .message-item:first-child"));
            if (!this.is_loading && lastIsVisible && e.originalEvent.deltaY < 0) {
                this.moreMessageshandler();
            }
        },
        preventdrag: function (e) {
            e.preventDefault();
        },
        checkScroll: function (e, context) {
            //scroll_direction 1 = up, 0 = down. only check on up.
            var that = e.data.that,  triggerPoint = 40, scroll_direction = 0;

            if (that.Top > context.scrollTop) {
                that.Top = context.scrollTop;
                that.ready = true;
                scroll_direction = 1;
            } else {
                that.Top = context.scrollTop;
                scroll_direction = 0;
            }

            if (!that.is_loading && scroll_direction && context.scrollTop < triggerPoint) {
                that.moreMessageshandler();
            }
        },
        moreMessageshandler: function (bottom) {
            var that = this,
                $success = $('<div class="alert alert-success" style="text-align:center">Loading more messages</div>'),
                $no_more = $('<div class="alert alert-success" style="text-align:center">No more messages</div>'),
                $upgrade = $('<div class="alert alert-success" style="text-align:center">' +
                                'Upgrade to Voxer Pro to get access to older messages <span id="data-retention-upgrade">Upgrade To PRO</span>' +
                             '</div>');

            that.is_loading = true;
            that.new_height = that.$('.panel-body')[0].scrollHeight;

            if (bottom) {
                $success.insertAfter(that.$el.find('.chat-messages'));
            } else {
                $success.insertBefore(that.$el.find('.chat-messages'));
            }

            that.model.get('messages').timeline({
                chat: that.model,
                success: function (data) {
                    if (!that.model.get('loaded_all_chats')) {
                        that.is_loading = false;
                    }
                    var $scroll = that.$('.panel-body');
                    $scroll.prop({scrollTop: $scroll[0].scrollHeight - that.new_height });
                    that.Top = that.el.scrollTop;
                    $success.remove();
                    if (bottom) {
                        var likes = [];

                        if (data && data.length) {
                            data = _.filter(data, function (m) {
                                if (m.content_type === "like_receipt") {
                                    likes.push(m);
                                }

                                return m.content_type !== "read_receipt" && m.content_type !== "like_receipt";
                            });

                            that.model.get('messages').add(data);
                        }

                        if (likes.length) {
                            likes.forEach(function(like) {
                                _.forEach(like["like_times"], function (prop, key) {
                                    var m = that.model.get('messages').get(key);
                                    if (m) {
                                        like_times = m.get('like_times') || {};
                                        length = Object.keys(like_times).length;

                                        if (like.like) {
                                            like_times[like.from] = prop;
                                        } else {
                                            if (like_times[like.from] && like_times[like.from] < prop) {
                                                delete like_times[like.from];
                                            }
                                        }

                                        m.set({ 'like_times': like_times });
                                        if (length !== Object.keys(like_times).length) {
                                            m.trigger('change:like_times', 'like_times');
                                        }
                                    }
                                });
                            });

                            delete likes;
                        }
                    }
                    if (that.model.get('loaded_all_chats') && !that.model.get('data_retention_policy')) {
                        if (bottom) {
                            $no_more.insertAfter(that.$el.find('.chat-messages'));
                        } else {
                            $no_more.insertBefore(that.$el.find('.chat-messages'));
                        }
                    } else if (that.model.get('loaded_all_chats') && that.model.get('data_retention_policy')) {
                        if (bottom) {
                            $upgrade.insertAfter(that.$el.find('.chat-messages'));
                        } else {
                            $upgrade.insertBefore(that.$el.find('.chat-messages'));
                        }
                    }
                }.bind(this)
            });
        },
        handle_data_retention_upgrade: function(e) {
            App.vent.trigger('metrics:track', '/upgrade_screen', {
                from: 'chat',
                feature: 'data_retention'
            });
            App.Router.navigate('upgrade', { trigger: true });
        },
        handle_pin_chat: function (e) {
            var pinned = this.model.get('pinned_chat'),
                that = this;

            var changes = {};

            changes[this.model.get('thread_id')] = {
                add: pinned ? [] : ['pinned_chat'],
                remove: pinned ? ['pinned_chat'] : []
            };

            App.API.mod_thread_tags({
                changes: changes
            }, {
                home_router: Settings.fetch('home_router'),
                query: {
                    user_id: App.Auth.get('user_id')
                }
            }, function (err) {
                console.error(err);
            }, function (data) {
                if (data.args && data.args.success) {
                    if (pinned) {
                        $(e.target).removeClass('active');
                        that.model.set({pinned_chat: false});
                    } else {
                        $(e.target).addClass('active');
                        that.model.set({pinned_chat: true});
                    }
                }
            });
        },
        handle_pop_out_chat: function (e) {
            $(e.currentTarget).addClass('hide');
            this.$el.find('.pop-in-chat').removeClass('hide')

            var width = 640,
                height = 640;

            try {
                width = this.$el.width();
                height = this.$el.height();
            } catch (err) {
                console.error(err);
            }

            this.child_window = window.open(location.origin + "/popout-chat?thread_id=" + this.model.get('thread_id'),
                                            this.model.get('thread_id'),
                                            "menubar=0,toolbar=0,width=" + width + ",height=" + height);

            window.addEventListener('message', function (e) {
                if (e.data === this.model.get('thread_id')) {
                    this.$el.find('.pop-in-chat').addClass('hide');
                    this.$el.find('.pop-out-chat').removeClass('hide')
                }
            }.bind(this));

            var onLogout = function (e) {
                if (!this.child_window.closed) {
                    this.child_window.postMessage('close', '*');
                    this.child_window.close();
                }
            }.bind(this);

            App.vent.off("logged_out", onLogout, this);
            App.vent.on("logged_out", onLogout, this);
            window.addEventListener("beforeunload", onLogout);
        },
        handle_pop_in_chat: function (e) {
            $(e.currentTarget).addClass('hide');
            this.$el.find('.pop-out-chat').removeClass('hide');

            try {
                if (this.child_window && !this.child_window.closed) {
                    this.child_window.close();
                }
            } catch (err) {
                console.error(err);
            }
        },
        consume: _.debounce(function ChatPanel_consume() {
            var that = this;
            if (that.messages_to_consume.length !== 0) {
                that.model.consume_messages(that.messages_to_consume).done(function (val){
                    _.each(that.messages_to_consume, function (id) {
                        var message = that.model.get('messages').get(id);
                        if (message)
                            message.set({'consumed': true});
                    });
                    that.messages_to_consume = [];
                });
            }
        }, 1000),
        visibleMessages: function (element, settings) {

            if (element === undefined) {
                return false;
            }
            var $element = element;
            var offset = $element.offset();

            var $window = this.$el;
            var windowTop = $window.scrollTop();
            var threshold = 20;

            if (offset === undefined) {
                return false;
            }

            if (offset.top - 180 <= 0) {
                // top edge above the window's top
                return false;
            }
            if (offset.top >= $window.height()) {
                // bottom edge below the window's bottom
                return false;
            }
            return true;
        },
        onShow: function () {
            this.is_loading = false;
            var that = this;

            this.$('.fileinput-button[data-toggle="popover"]').popover({
                trigger: 'click',
                html: true,
                content: function () {
                    return '<div class="pull-left col-xs-3 hint">' +
                               '<div class="info "><i class="zmdi zmdi-close-circle"></i> Upload files </div>' +
                           '</div>' +
                           '<div class="col-xs-3 pull-left text-center">' +
                               '<a class="fileinput-button ">' +
                                   '<i class="zmdi-image zmdi zmdi-hc-lg"></i> <span> Computer</span>' +
                                   '<input type="file" accept="image/x-png, image/gif, image/jpeg" title="Select picture to send">' +
                               '</a>' +
                           '</div>' +
                           '<div class="col-xs-3 pull-left text-center">' +
                               '<a class="dropbox-chooser "> '+
                                    '<i class="zmdi zmdi-dropbox zmdi-hc-lg"></i> '+
                                    '<span>Dropbox</span>'+
                                '</a>' +
                           '</div>';
                        //    + '<div class="col-xs-3 pull-left text-center">' +
                        //        '<a class="google-chooser ">' +
                        //             '<i class="zmdi zmdi-google-drive zmdi-hc-lg"></i> '+
                        //             '<span>Google Drive</span>' +
                        //         '</a>' +
                        //    '</div>';
                }
            });

            this.$('[data-toggle="popover"]').on('shown.bs.popover', function () {
                var input = that.$('input[type="file"]');

                that.$el.find('.chat-ptt').addClass('hide');
                that.$el.find('.chat-interact').css('height', '100px');
                that.$el.find('.hint ').click(function(){
                    that.$('[data-toggle="popover"]').popover('hide');
                });

                if (!input.length)
                    return;

                input[0].addEventListener('change', function(event){
                    that.send_image_to_server(event, that);

                    that.$('[data-toggle="popover"]').popover('hide');
                }, false);
            });

            this.$('[data-toggle="popover"]').on('hide.bs.popover', function () {
                that.$el.find('.chat-ptt').removeClass('hide');
                that.$el.find('.chat-interact').css('height', 'auto');
                that.$el.find(".text-container .popover").css("display", "none"); //so it does not keep it live
                that.$('input[type="file"]').off('change');
                that._vox_text_focus();
            });

            var _content = '';

            var emoji_keys = Object.keys(minEmoji.emoji);
            emoji_keys.forEach(function(key) {
                _content += '<span data="' + key + '" class="em emj'+minEmoji.emoji[key]+'"></span>';
            });

            this.$('.emoticons-button[data-toggle="popover"]').popover({
                trigger: 'click',
                html: true,
                content: function () {
                    return '<div class="pull-left col-xs-3 emoticons hint">' +
                               '<div class="info "><i class="zmdi zmdi-close-circle"></i>Pick an emoticon</div>' +
                           '</div>' +
                           '<div class="col-xs-9 pull-left emoticons text-center">' +
                           _content +
                           '</div>';
                }
            });

            //make sure we only paste plain text; remove all html copied on clipboard
            this.$el.find('div.vox-text').on("paste", function(e){
                e.preventDefault();
                var text = (e.originalEvent || e).clipboardData.getData('text/plain');
                window.document.execCommand('insertText', false, text);
            });
            this.$el.find('div.vox-text').focus();
        },
        handle_user_tag_pick: function (event) {
            var el = $(event.currentTarget),
                vox_text = this.$el.find("div.vox-text"),
                user_id = el.find('.name').attr('user-id'),
                name = el.find('a.name span.name').text();

            vox_text.html(
                vox_text.html().replace(
                    '@' + this.search_querry,
                    '<a href="/contact/' + user_id + '" data="' + user_id + '">' + name + '</a>&nbsp;'
                )
            );
            this.tag_user_on = false;
            this.$el.find('.pick-user').tooltip('hide')
            this.$el.find('.pick-user').addClass('hide');
            // Reset the list after picking one user
            this.conview.collection = new Backbone.Collection(this.recipients);
            this.conview.render();

            this._vox_text_focus();
        },
        handle_pick_emoticon: function (event) {
            var emo = $(event.target).attr('data'),
                textbox = this.$el.find('div.vox-text'),
                val = textbox.html();

            textbox.html(val + event.target.outerHTML + "&nbsp;");
            // Trigger an change so send-vox button updates
            if (val.length === 0)
                textbox.trigger('input');

            this._vox_text_focus();
            this.$el.find('[data-toggle="popover"]').popover('hide');
        },
        send_image_to_server: function (event, context, details) {
            var file,
                is_blob,
                is_gif = typeof is_gif === "undefined" ? false : is_gif,
                orientation = 0;

            // Only process image files.
            if (event && event.target && event.target.files[0].type.match('image.*')) {
                EXIF.getData(event.target.files[0], function() {
                    try {
                        // convert the orientation so it pairs with the iOS values
                        orientation = exifOrientationConvert(this.exifdata.Orientation);
                    } catch (err) {
                        console.error(err);
                    }
                });
                if (event.target.files[0].type.match('image.gif')) {
                    is_gif = true;
                    file = event.target.files[0];
                } else {
                    file = window.URL.createObjectURL(event.target.files[0]);
                }
            } else if (event && typeof event === "string") {
                file = event;
            } else if (event instanceof Blob) {
                is_blob = true;
                is_gif = true;
                file = event;
            } else {
                return;
            }

            var reader = new FileReader(),
                image_width,
                image_height;

            reader.onprogress = function () {
                context.progressBar.css("width", "75%");
            };

            reader.onerror = function () {
                context.$el.find('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                .addClass('system')
                .find('.message-text')
                .addClass('text-danger')
                .html('Image upload failed. Error in FileReader.');

                $('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                .addClass('system')
                .find('.message-text')
                .addClass('text-danger')
                .html('Image upload failed. Error in FileReader.');
                // remove model silently as to not remove the message view
                context.model.get("messages").remove(context.message_model, {silent: true});
            };

            var message_id = generate_message_id('image'),
                participants = context.model.get("participants"),
                now = (new Date()).getTime() / 1000, header,
                data = {};

            //Are you not messaging a contact?
            context.add_contact_from_send();

            // post the message
            data = {
                from: App.Auth.get("rebelvox_user_id"),
                subject: context.model.get("subject"),
                body: '',
                content_type: "text",
                thread_id: context.model.get("thread_id"),
                message_id: message_id,
                normalized_create_time: now,
                create_time: now,
                consumed: true,
                append: true,
                className: 'placeholder'
            };

            if (App.Settings.get('geo')) {
                var coord = App.Settings.get('geo');
                coord = coord.coords || undefined;
                if (coord) {
                    data.geo = {
                        altitude: 0,
                        latitude: coord.latitude,
                        longitude: coord.longitude
                    };
                }
            }

            // check if replying to another message
            if (this.model.get('replies_to').message_id) {
                data.replies_to = this.model.get('replies_to');
            }

            // keep "data" intact, use "header" to transmit
            header = _.clone(data);
            header.content_type = 'image';

            // add the placeholder to the view - replace when all is done
            context.model.get('messages').add(data);

            // keep a reference of the message_model for further processing in callbacks
            context.message_model = context.model.get('messages').get(message_id);
            context.message_model.set({status: 'pending'});
            if (data.replies_to && data.replies_to.message_id) {
                context.message_model.set({replies_to: this.model.get('replies_to')});
                this.model.set('replies_to', {});
            }

            var _messageView = context.$el.find('#' + Lib.sanitize_message_id(context.message_model.id) + ' .message-text');
            _messageView.html('<div class="image-container"><img src="/assets/img/ajax-loader-medium.gif" class="voxer-image" /></div>' +
                '<span>Uploading image</span><div class="image-progress"></div>');
            $('#' + Lib.sanitize_message_id(context.message_model.id) + ' .message-text').html('<div class="image-container"><img src="/assets/img/ajax-loader-medium.gif" class="voxer-image" /></div><span>Uploading image</span><div class="image-progress"></div>');
            context.progressBar = _messageView.find('.progress .bar');

            // Closure to capture the file information.
            reader.onload = (function(theFile, theData) {

                return function(e) {
                    context.progressBar.css("width", "100%");

                var meta = {
                    session_key: App.Auth.get('Rv_session_key'),
                    from: theData.from,
                    thread_id: theData.thread_id,
                    message_id: theData.message_id,
                    content_type: 'image',
                    content_json: {
                        dimensions: {
                            w: image_width,
                            h: image_height
                        },
                        o: orientation
                    },
                    create_time: theData.create_time,
                }

                if (is_gif) {
                    meta.content_json.fmt = "gif";
                } else {
                    meta.content_json.fmt = "jpg";
                }

                if (theData.replies_to && theData.replies_to.message_id) {
                    meta.replies_to = theData.replies_to;
                }

                meta.secure_router = {
                    host: App.Auth.attributes.home_router.address,
                    port: App.Auth.attributes.home_router.port
                }
                function send_image () {
                    var stream = App.VoxerAudio.client.send(e.target.result, meta);
                    stream.once('data', function (data) {
                        var results = {};

                        try {
                            results = (typeof data === "string") ? JSON.parse(data) : data;
                            if (results.statusCode !== undefined && results.statusCode > 300) {
                                context.$el.find('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                                .addClass('system')
                                .find('.message-text')
                                .addClass('text-danger')
                                .html('Image upload failed');

                                $('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                                .addClass('system')
                                .find('.message-text')
                                .addClass('text-danger')
                                .html('Image upload failed');
                                return;
                            }
                        } catch (err) {
                            console.error(err);
                        }

                        var model = context.message_model;
                        try {
                            context.model.get("messages").remove(context.message_model);
                            model.set({
                                live_image: true,
                                content_type: 'image',
                                status: 'sent',
                                message_id: results.final_message_id,
                                normalized_create_time: results.normalized_create_time,
                                posted_time: results.posted_time,
                                content_json: {
                                    dimensions: {
                                        w: image_width,
                                        h: image_height
                                    },
                                    fmt: meta.content_json.fmt,
                                    o: orientation
                                }
                            });
                            context.model.get("messages").add(model);
                        } catch (e) {
                            context.model.get("messages").remove(context.message_model);
                            model.set({
                                live_image: true,
                                content_type: 'image',
                                status: 'sent',
                                message_id: results.final_message_id,
                                normalized_create_time: results.normalized_create_time,
                                posted_time: results.posted_time
                            });
                            context.model.get("messages").add(model);
                        }

                        App.vent.trigger('metrics:track', 'v4w/message_sent', {
                            media_type: 'image',
                            chat_participants: context.model.get_participant_count(),
                            open_chats: App.chats.where({'active': true}).length,
                            fmt: meta.content_json.fmt
                        });
                    });
                    stream.on('end', function (data) {
                        stream.destroy();
                    });
                }

                if (App.VoxerAudio.socket_connected) {
                    send_image();
                } else {
                    App.VoxerAudio.connect_ws(App.webserver, send_image)
                }
                };
            })(file, data);

            if (is_gif) {
                var image = new Image();
                image.onload = function() {
                    image_width = this.width,
                    image_height = this.height,
                    reader.readAsArrayBuffer(file);
                };
                image.setAttribute('crossOrigin', 'anonymous');
                image.src = window.URL.createObjectURL(file);
            } else if (is_blob) {
                image_width = details.width;
                image_height = details.height;
                reader.readAsArrayBuffer(file);
            } else {
                context.resize_image(file).done(function (file, width, height) {
                    image_width = width;
                    image_height = height;

                    reader.readAsArrayBuffer(file);
                }).fail(function () {
                    context.$el.find('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                    .addClass('system')
                    .find('.message-text')
                    .addClass('text-danger')
                    .html('Image upload failed. Failed to resize.');

                    $('#' + Lib.sanitize_message_id(context.message_model.get('message_id')) + ' .message')
                    .addClass('system')
                    .find('.message-text')
                    .addClass('text-danger')
                    .html('Image upload failed. Failed to resize.');
                });
            }
            context.scroll_to_latest_message();
        },
        resize_image: function (file) {
            var deferred = new $.Deferred(),
                image = new Image();

            image.onload = function() {
                var width = this.width,
                    height = this.height,
                    canvas = document.createElement('canvas');

                // Scale the image so it will have an max side, without stracthing
                if (width > height) {
                  if (width > CONSTANTS.chatImage.MAX_WIDTH) {
                    height *= CONSTANTS.chatImage.MAX_WIDTH / width;
                    width = CONSTANTS.chatImage.MAX_WIDTH;
                  }
                } else {
                  if (height > CONSTANTS.chatImage.MAX_HEIGHT) {
                    width *= CONSTANTS.chatImage.MAX_HEIGHT / height;
                    height = CONSTANTS.chatImage.MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;

                var ctx = canvas.getContext("2d");
                ctx.drawImage(this, 0, 0, width, height);

                deferred.resolve(App.dataUriToBlob(canvas.toDataURL("image/png")), this.width, this.height);
            };

            image.onerror = function(err) {
                console.error(err);
                deferred.reject();
            }

            image.setAttribute('crossOrigin', 'anonymous');
            image.src = file;

            return deferred.promise();
        },
        handle_dropbox: function (e) {
            e.preventDefault();
            var that = this;

            this.$('[data-toggle="popover"]').popover('hide');

            Dropbox.choose({
                success: that.link_image_upload_callback.bind(that),
                cancel: function () {
                    console.log("Dropbox cancelled");
                },
                linkType: "preview"
            });
        },
        link_image_upload_callback: function (files) {
            var model = this.model;
            files.forEach(function(file) {
                var post_data = {
                        "message_id":  generate_message_id(),
                        "create_time":  (new Date()).getTime() / 1000,              // MUST be recreated everytime for a send, even if retrying to send after error
                        "model": navigator.userAgent.toLowerCase()
                    },
                    post_body,
                    options = {},
                    re = /(?:\.([^.]+))?$/;

                if (App.Settings.get('geo')) {
                    var coord = App.Settings.get('geo');
                    coord = coord.coords || undefined;
                    if (coord) {
                        post_data.geo = {
                            altitude: 0,
                            latitude: coord.latitude,
                            longitude: coord.longitude
                        };
                    }
                }

                post_data = _.extend(post_data, {
                    content_type: 'text',
                    subcontent_type: 'file_share',
                    message_id: generate_message_id(),
                    from: App.Auth.get('user_id'),
                    subject: model.get('subject'),
                    body: file.link,
                    thread_id: model.get('thread_id'),
                    file_link_uri: file.link,
                    file_name: file.name,
                    file_extension: re.exec(file.name)[1],
                    file_size: file.bytes,
                    provider: 'dropbox',
                    icons: file.icon
                });

                // check if replying to another message
                if (this.model.get('replies_to').message_id) {
                    post_data.replies_to = this.model.get('replies_to');
                }

                var message = new App.Message.Model(post_data);
                var now = (new Date()).getTime() / 1000;

                // optimistically render the message
                message.set({
                    normalized_create_time: now,        // native parameters
                    append: true,                       // app params (will be stripped before saving)
                    status: 'pending'
                });
                if (post_data.replies_to && post_data.replies_to.message_id) {
                    message.set({replies_to: this.model.get('replies_to')});
                    this.model.set('replies_to', {});
                }

                // add thread's messages collection if not there already
                if (!model.get('messages')) {
                    model.set({
                        'messages': new Chat.Messages()
                    });
                }

                message.set({'preview_link': post_data.body.replace('www.dropbox.com', 'dl.dropboxusercontent.com')});
                message.set({'sub_content_type': 'file_share'});

                model.get('messages').add(message);
                model.set({
                    last_message: message.attributes
                });

                message.save({context: model}).then(function (data) {
                    // update the renderering of the message text
                    message.set({
                        status: 'sent',
                        message_id: data.final_message_id || message.get('message_id'),
                        normalized_create_time: data.normalized_create_time,
                        posted_time: data.posted_timem,
                        consumed: true,
                        delivery_times: {},
                        read_times: {}
                    });

                    App.vent.trigger('metrics:track', 'v4w/message_sent', {
                        media_type: 'file',
                        attach_src: message.get('file_link_uri'),
                        file_type: re.exec(file.name)[1]
                    });
                }).fail(function (xhr) {
                    // update the renderering of the message text
                    message.set({status: 'failed'});
                    // retry (a couple of times ??)
                    console.log(xhr);
                });
            });
        },
        // handle_google_drive: function (e) {
        //     var auth = App.google.authInstance,
        //         that = this;

        //     if(auth.isSignedIn.get()) {
        //         getGoogleDriveAPI()
        //     } else {
        //         auth.signIn({app_package_name: 'com.rebelvox.enterprise'}).then(getGoogleDriveAPI.bind(this));
        //     }

        //     function getGoogleDriveAPI() {
        //         if (App.pickerLoaded) {
        //           var picker = new google.picker.PickerBuilder().
        //               addView(new google.picker.PhotosView()).
        //               setAppId(App.Config.get('oauth_creds').google.client_id).
        //               setCallback(function(data) {
        //                 if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        //                     var files = data[google.picker.Response.DOCUMENTS];
        //                     files = _.map(files, function(file) {
        //                         return {
        //                             link: file.thumbnails[file.thumbnails.length - 1].url,
        //                             bytes: file.sizeBytes,
        //                             icon: file.iconUrl,
        //                             name: file.name
        //                         };
        //                     });
        //                     that.link_image_upload_callback.call(that, files);
        //                 }
        //               }.bind(this)).setOAuthToken(gapi.auth2.getAuthInstance().currentUser.get('access_token').getAuthResponse().access_token).
        //               build();
        //           picker.setVisible(true);
        //           that.$el.find('[data-toggle="popover"]').popover('hide');
        //         }
        //     }
        // },
        handle_gif_modal: function () {
            var model = new UI.Views.GIFsModal({
                    callback: function (data) {
                        var that = this,
                            xhReq = new XMLHttpRequest();

                        xhReq.open("GET", data.link);
                        xhReq.responseType = 'blob';
                        xhReq.onload = function () {
                            that.send_image_to_server(xhReq.response, that, data);
                        };
                        xhReq.onerror = function (e) {
                            try {
                                console.log("error in handle_gif_modal xhrReq " + JSON.parse(xhReq.responseText));
                            } catch (err) {
                                console.log('Unknown error in handle_gif_modal xhrReq : ' + err);
                            }
                        };
                        xhReq.ontimeout = function (e) {
                            console.log("Server timeout out");
                        };
                        xhReq.send(null);
                    }.bind(this)
                });

            App.layout.regionIntro.show(model);
        },
        start_recording: function (e) {
            // Pause all ongoning audios
            if (soundManager) {
                soundManager.pauseAll()
            }

            // start the timer
            App.record_start = new Date().getTime();

            var that = this,
                message_id = generate_message_id(),
                params = {
                    session_key: App.Auth.get('Rv_session_key'),
                    host: App.Auth.get('home_router').address,
                    port: App.Auth.get('home_router').port,
                    from: App.Auth.get("user_id"),
                    to: this.model.get("recipients"),
                    thread_id: this.model.get("thread_id"),
                    message_id: message_id,
                    active_vox: true
                };

            if (App.Settings.get('geo')) {
                var coord = App.Settings.get('geo');
                coord = coord.coords || undefined;
                if (coord) {
                    params.geo = {
                        altitude: 0,
                        latitude: coord.latitude,
                        longitude: coord.longitude
                    };
                }
            }

            // check if replying to another message
            if (this.model.get('replies_to').message_id) {
                params.replies_to = this.model.get('replies_to');
            }

            App.VoxerAudio.start_recording(params, function (data) {
                var audio_message = that.model.get('messages').where({placeholder: true})[0];

                // update model
                if (data && audio_message) {
                    audio_message.set({
                        consumed: true,
                        update: false,
                        placeholder: !audio_message.get('blobUrl'),
                        active_vox: false,
                        status: 'sent',
                    });
                    that.model.set({
                        last_message: audio_message.attributes
                    });
                } else if (!data && audio_message) {
                    audio_message.set({
                        update: false,
                        placeholder: !audio_message.get('blobUrl'),
                        active_vox: false,
                        status: 'failed',
                    });
                }
            }).done(function () {
                // toggle the recording button
                $(e.target).addClass('btn-active');

                params = $.extend(params, {
                    consumed: true,
                    content_type: "audio",
                    create_time: new Date().getTime() / 1000,
                    update: true,
                    placeholder: true,
                    append: true,
                });

                // Clear the reply state
                if (that.model.get('replies_to').message_id) {
                    params.replies_to = that.model.get('replies_to');
                    that.model.set('replies_to', {});
                }

                // add a placeholder status message to panel ("recording audio")
                var chat_messages = that.model.get('messages');
                chat_messages.add(params);
                that.model.set({
                    last_message: params
                });
            }.bind(this)).fail(function () {
                this.stop_recording();
                App.layout.regionIntro.show(new App.UI.Views.IntroView({
                    MicStatus: !App.VoxerAudio.get_is_muted(),
                    Name: App.Auth.get('login_id')
                }));
            }.bind(this));
        },
        stop_recording: function (e) {
            var that = this;

            if (e && e.preventDefault)
                e.preventDefault();

            App.VoxerAudio.stop_recording().done(function(blobUrl, size) {
                var audio_message = that.model.get('messages').where({placeholder: true})[0],
                    // Calculate the duration on socket response and decrease with 200ms witch is the network TBF
                    duration_ms = (new Date().getTime() - App.record_start - 200);

                if (audio_message) {
                    audio_message.set({
                        blobUrl: blobUrl,
                        audio_length_bytes: size,
                        audio_duration_ms: duration_ms,
                        placeholder: !audio_message.get('status')
                    });
                }

                if (e && e.target) {
                    $(e.target).removeClass('btn-active');
                } else {
                    $('.vox-voice').removeClass('btn-active');
                }

                that.model.set('should_be_sending', false);

                that.handle_recording_status.call(that);
            });

            App.vent.trigger('metrics:track', 'v4w/message_sent', {
                media_type: 'audio',
                chat_participants: that.model.get_participant_count(),
                open_chats: App.chats.where({'active': true}).length
            });
        },
        get_conch: (function () {
            var conch = {};
            conch.callbacks = [];
            conch.appendCallback = function (callback) {
                conch.callbacks.push(callback);
            };
            return function () {
                while (conch.callbacks.length) {
                    conch.callbacks.pop()();
                }
                return conch;
            };
        })(),
        handle_change_chat_title: function (e) {
           var html = this.model.get('subject');
            if (this.$el.find('.panel-heading h1 input').length === 1) {
                this.handle_change_title({which: 13});

                this.$el.find('.panel-heading h1').text(html);
            } else {
                html = $('<input>').addClass('form-control input-lg new-chat-title')
                                   .attr("placeholder", html).val($("<div>").text(html).html());
                this.$el.find('.panel-heading h1').html(html);
                this.$el.find('.panel-heading h1 input').focus();
            }
        },
        handle_notification_level: function (e) {
            var notification_levels = {
                    'muted': 'zmdi-volume-off',
                    'normal': 'zmdi-volume-down',
                    'xnotif': 'zmdi-volume-up',
                    'mentions': 'zmdi-accounts-alt',
                    'ignored': ''
                },
                changes  = {},
                that = this,
                mode = $(e.target).attr('data') || 'normal';

            changes[this.model.get('thread_id')] = {
                add: [mode],
                remove: _.without(Object.keys(notification_levels), mode)
            };

            App.API.mod_thread_tags({
                changes: changes
            }, {
                home_router: Settings.fetch('home_router'),
                query: {
                    user_id: App.Auth.get('user_id')
                }
            }, function (err) {
                console.error(err);
            }, function (data) {
                if (data.args && data.args.success) {
                    var icon = this.$el.find('.notifications_icon');

                    icon.removeClass('zmdi-volume-off zmdi-volume-down zmdi-volume-up zmdi-accounts-alt');
                    icon.addClass(notification_levels[mode]);

                    switch (mode) {
                        case "muted":
                            this.model.set({ _muted:  true});
                            this.model.set({ _silent: true});
                            this.model.set({ _extremeNotificationsEnabled: false});
                            this.model.set({ _mentionsEnabled: false});
                            break;
                        case "normal":
                            this.model.set({ _muted:  false});
                            this.model.set({ _silent: false});
                            this.model.set({ _extremeNotificationsEnabled: false});
                            this.model.set({ _mentionsEnabled: false});
                            break;
                        case "xnotif":
                            this.model.set({ _muted:  false});
                            this.model.set({ _silent: false});
                            this.model.set({ _extremeNotificationsEnabled: true});
                            this.model.set({ _mentionsEnabled: false});
                            break;
                        case "mentions":
                            this.model.set({ _muted:  false});
                            this.model.set({ _silent: false});
                            this.model.set({ _extremeNotificationsEnabled: false});
                            this.model.set({ _mentionsEnabled: true});
                            break;
                        default:
                    }
                }
            }.bind(this));
        },
        handle_change_title: function (e) {
            if (e.which == 13 || e.type === "focusout") {
                var that = this,
                    old_subject = this.model.get('subject'),
                    new_subject = this.$el.find('.panel-heading h1 input').val(),
                    post_body = {
                        thread_id: this.model.get('thread_id'),
                        chat_name: new_subject
                    },
                    options = {
                        router: Settings.fetch('home_router')
                    };

                if (old_subject === new_subject && e.type === "focusout"){
                    this.$el.find('.panel-heading .fg-line').removeClass('fg-toggled');
                    this.$el.find('.panel-heading h1').text(new_subject);
                    return;
                } else if (old_subject === new_subject || that.change_title_ongoing) {
                    return;
                }

                if (new_subject.trim().length === 0) {
                    that.$('h1').addClass('has-error');
                } else if (this.model.get_recipients().to.length >= 2 || !(this.model.is_hotline() || this.model.get('favorited') )) {
                    that.change_title_ongoing = true;
                    App.API.change_title(post_body, options, function (data) {
                        that.$('h1').addClass('has-error');
                        that.change_title_ongoing = false;
                    }, function (data) {
                        that.change_title_ongoing = false;
                        that.model.set({subject: new_subject, title: new_subject});

                        // Route a system message
                        App.Message.route([
                            _.extend(data, {
                                body: 'You named the chat "' + new_subject + '"',
                                message_id: data.final_message_id,
                                content_type: "text",
                                consumed: true,
                                system: true
                            })
                        ], {live: false});
                    });
                }
            }
        },
        handle_chat_settings_menu: function (e) {
            try {
              var capabilities = App.Auth.get("capabilities");
              var business_user = App.Auth.get("business_id") != undefined;
              var is_pro =
                capabilities &&
                Object.keys(capabilities.capability_keys).length > 0;
              if (is_pro && !business_user) {
                this.$el.find("#allow-zapier").removeClass("hide-allow-zapier");
              }
            } catch (err) {
              console.error(err);
            }
        },
        add_contact_from_send: function () {
            //Are you not messaging a contact?
            var contact = this.model.get('contact'), that = this;
            if (contact !== undefined && !contact) {
                App.profiles.add_contact(this.model.get('creator'), {
                    success: function () {
                        that.model.set({'contact': true});
                    },
                    error: function () {
                        //debugger;
                    }
                });
            }
        },
        handle_browser_support: function (e){
            if((navigator.userAgent.indexOf('MSIE') > -1) || ((navigator.userAgent.indexOf('Safari') != -1) && (navigator.userAgent.indexOf('Chrome') == -1)) ){
                    App.layout.regionIntro.show(new App.UI.Views.IntroView({
                }));
            }
        },
        handle_recording_status: function (e) {
            var that = this,
                $this_button = this.$el.find('.vox-voice'),
                $all_buttons = this.$el.find('.vox-voice:not(.favorited)'),
                $vox_sway = this.$el.find('#vox-sway');
                flash_active = (App.VoxerAudio.micAverageLevel !== -1),
                shouldBeSending = that.model.get('should_be_sending');

            if (e && e.data && e.data.that) {
                that = e.data.that;
            }

            if (!App.VoxerAudio.isReady) {
                return false;
            }

            if (!flash_active && !shouldBeSending && (e && e.type === 'mousedown')) {

                App.layout.regionIntro.show(new App.UI.Views.IntroView({
                    MicStatus: !App.VoxerAudio.get_is_muted(),
                    Name: App.Auth.get('login_id')
                }));

                return false;
            }

            var wasPressed = that.model.get('is_pressed');
            var isPressed = wasPressed;
            if (e && e.which === 1) {
                var isTtt = Settings.fetch('talk-method') === 'TTT';
                var isPtt = !isTtt;

                if (isTtt) {
                    if (e.type === 'mousedown') {
                        isPressed = !wasPressed;
                    }
                } else if (isPtt) {
                    if (e.type === 'mousedown') {
                        isPressed = true;
                    } else if (e.type === 'mouseup' || e.type === 'mouseleave') {
                        isPressed = false;
                    }
                }
            } else if (e && typeof e.isPressed !== "undefined") {
                isPressed = e.isPressed;
            }

            that.model.set('is_pressed', isPressed);
            var isButtonEvent = isPressed !== wasPressed;

            if (shouldBeSending && isButtonEvent && isPressed) {
                //console.log('SLOW DOWN!');
                //ignore this event, we're still sending the last message
            } else if (isButtonEvent && isPressed) {
                startSending();
            } else if (isButtonEvent) {
                stopSending();
            } else if (shouldBeSending) {
                //console.log('STILL SENDING...'); //ignore anything that happens while we're still sending
            } else {
                //console.log('WE ARE ALL SHUT DOWN'); //update display to show we're ready to go again
                $all_buttons.removeAttr('disabled').removeClass('disabled');
                $this_button.parent().removeClass('active-vox');
                $vox_sway.hide();
            }
            function startSending() {
                that.add_contact_from_send();

                that.cut_timer = setTimeout(function() {
                    that.handle_recording_status({isPressed: false});
                }, CONSTANTS.cut_audio_timeout);

                //console.log('START SENDING MESSAGE'); //done sending last message and trying to start new one (so do it)
                that.get_conch().appendCallback(stopSending);
                that.model.set('is_pressed', true);
                // start the counter
                that.interval = setInterval(function () {
                    that.update_duration(that);
                    if (that.child_window || that.opener) {
                        that.update_duration(that.child_window || that.opener);
                    }
                }, 100);

                $this_button.parent().addClass('active-vox');

                function doSinewave () {
                    var source = App.VoxerAudio.audioContext.createMediaStreamSource(App.VoxerAudio.stream);
                    source.connect(that.analyser);

                    Account.Views.SettingsClient.prototype.visualize.call(
                        that, that.analyser, 'sinewave', that.canvas, that.canvasCtx, '#50d04a'
                    );
                    $vox_sway.show();
                };

                try {
                    if (!that.analyser)
                        that.initialize();
                    doSinewave();
                } catch (err) {
                    console.error("SINEWAVE error = " + err);
                    // If user changed source, then let's restart the sinwave
                    that.initialize();
                    doSinewave();
                }


                that.model.set('should_be_sending', true);

                that.start_recording(e);
            }
            function stopSending() {
                that.model.set('is_pressed', false);
                if (!that.model.get('should_be_sending')) {
                    return;
                }
                //console.log('START SHUTTING DOWN MESSAGE'); //start message ending process...
                that.stop_recording(e);
                // clear the interval and clean up the model
                clearTimeout(that.cut_timer);
                clearInterval(that.interval);
                delete that.cut_timer;
                delete that.interval;

                $this_button.attr('disabled', 'disabled').addClass('disabled');
            }
        },
        // interval callback to update vox time counter
        update_duration: function (context) {
            var duration_ms = (new Date().getTime() - App.record_start),
                seconds = duration_ms / 1000,
                minutes = Math.floor(seconds / 60);

            seconds = Math.floor(seconds % 60);

            if (seconds.toString().length == 1)
                seconds = "0" + seconds;

            duration = minutes + ":" + seconds;

            context.$('.voxing .vox-duration').html(duration);
        },
        serializeData: function () {
            var can_turn_admin = false;
            try {
              var capabilities = App.Auth.get("capabilities");
              can_turn_admin =
                capabilities &&
                capabilities.capability_keys &&
                capabilities.capability_keys.controlled_chat
                  ? true
                  : false;
            } catch (err) {
              console.error(err);
            }

            var workspace_width = ($('.workspace').first().width()) || $($('.workspace')[1]).width(),
                height,
                width,
                max_panel_width = 738,
                data = this.options,
                notification_levels = ['zmdi-volume-off', 'zmdi-volume-down', 'zmdi-volume-up', 'zmdi-accounts-alt'],
                notification_level = 'zmdi-volume-down',
                note_to_self;

            workspace_width = workspace_width - 1;
            if (workspace_width < 900) {
                $(".workspace").addClass("no-avatar");
            }
            else {
                $(".workspace").removeClass("no-avatar");
            }

            //20 pixels is subtracted to make up for borders and other whatnaught
            width = workspace_width - 20;
            if (width > max_panel_width) {
                width = max_panel_width;
            }
            // set width
            $('.chat-panel').css('width', width.toFixed(0) + 'px');
            this.$el.css('width', width + 'px');

            data.adminControl = 'checked';
            note_to_self = this.model.get_recipients().recipients.length == 1 &&
                !this.model.get_recipients().groups.length && this.model.is_hotline();
                // && this.model.get('invites').length < 1; TODO: uncoment this when we suport invitees as contacts

            if (this.model.get('_muted') || this.model.get('_silent') || this.model.get('_notificationsDisabled')) {
                notification_level = notification_levels[0];
            } else if (this.model.get('_extremeNotificationsEnabled')) {
                notification_level = notification_levels[2];
            } else if (this.model.get('_mentionsEnabled')) {
                notification_level = notification_levels[3];
            }

            _.extend(data, {
                notification_level: notification_level,
                button_label: Settings.fetch('talk-mode') === 'PTT' ? 'Hold-to-Talk' : 'PTT',
                live_audio: this.model.get('live_audio'),
                pinned_chat: this.model.get('pinned_chat') || false,
                zapier_chat: this.model.get('zapier_chat') || false,
                can_leave: !this.model.is_hotline(),
                can_change_title: this.model.get_recipients().to.length > 2 || !(this.model.is_hotline() || this.model.get('favorited')),
                note_to_self: note_to_self,
                popout_chat: !!this.model.get('popout_chat'),
                can_be_admin: (this.model.get('creator') === App.Auth.get('user_id')) && this.model.get_recipients().to.length > 2  && can_turn_admin,
                admin_controled: this.model.get('controlled_chat')
                    && this.model.get('creator') !== App.Auth.get('user_id'),
                favorited: this.model.get('favorited'),
                is_broadcast: this.model.is_broadcast() && this.model.get('thread_id').indexOf(App.Auth.get('user_id')) === -1,
                subject: Lib.truncate_string(escapeHTML(this.model.get('subject')), 50),
            });

            // TODO: height control with mutli rows
            /*this.$el.css('height', '100%');
             $('.chat-panel').css('height', '100%');*/

            return this.options;
        },
        handle_menu_click: function (e) {
            e.preventDefault();
        },
        handle_consume_all: function (e) {
            e.preventDefault();
            var that = this;

            this.model.consume_messages().done(function (data) {
                //debugger;
                that.model.get('messages').each(function (m) {
                    m.set({'consumed': true});
                });
            }).fail(function (xhr) {
                console.error(xhr);
            });
        },
        handle_share_chat: function(){
            var that = this;
            var modal = new UI.Views.ShareVoxModal({
                headline: 'Copy the link to share',
                callback: function () {
                },
                info_text: (window.location.origin +'/chats/'+that.model.id).toString(),
                instructions: 'The URL of this Chat is below. Copy it and start sharing with your friends.'
            });
            var disableAdminModal = new UI.Views.TaskModal({
                headline: 'Share link to chat',
                info_text: 'Please turn off Admin Control first to allow others to join from this link.',
                ok_text: 'Turn off and share',
                cancel_text: 'Not now',
                callback: function (m) {
                    that.toggle_admin_control();
                    m.$el.modal('toggle');
                    App.layout.regionIntro.show(modal);
                }
            });

            if (this.model.get('controlled_chat')) {
                App.layout.regionIntro.show(disableAdminModal);
            } else {
                App.layout.regionIntro.show(modal);
            }
        },
        handle_delete_chat: function (e) {
            var modal = new Chat.Views.TaskModal({
                model: this.model,
                task: 'delete'
            });
            App.layout.regionIntro.show(modal);
        },
        handle_leave_chat: function (e) {
            e.preventDefault();
            var modal = new Chat.Views.TaskModal({
                model: this.model,
                task: 'leave'
            });
            App.layout.regionIntro.show(modal);
        },
        handle_live_mode: function (e) {
            e.preventDefault();
            var that = this, chat = App.chats.get(that.model.get('thread_id')), live_chats;
            if (chat.get('live_audio')) {
                live_chats = Settings.fetch('live-chats');
                Settings.set({'live-chats': _.without(live_chats, this.model.get('thread_id'))});
                chat.set({live_audio: false});
                this.$el.find('.audio.menu-livemode').html('Turn ON vox autoplay');
            } else {
                live_chats = Settings.fetch('live-chats');
                Settings.set({'live-chats': _.union(live_chats, [this.model.get('thread_id')])});
                chat.set({live_audio: true});
                this.$el.find('.audio.menu-livemode').html('Turn OFF vox autoplay');
            }
        },
        handle_chat_users: function (e) {
            e.preventDefault();
            // TODO metrics
            // App.vent.trigger('metrics:track', '');
            // set class for coloring appropriately
            $(e.target).toggleClass('open');

            var that = this;
            // get all contacts in alphasort
            if ($(e.target).hasClass('open')) {
                this.participants.show(new Chat.Views.ParticipantsWrapper({
                    model: this.model,
                    panel: this,
                    target: $(e.target)
                }));
            } else {
                this.participants.currentView.destroy();
            }
        },
        handle_send_vox_button: function (e){
            var that = this,
                textbox_empty = (that.$el.find('div.vox-text')[0].textContent.replace(/[\n, ]/g, '') === "");

            if (!textbox_empty) {
                this.handle_send_vox_text(e);
            }
        },
        handle_send_vox: function (e) {
            var event_target = $(e.target);

            if (event_target.hasClass('vox-send')) {
                textbox = event_target.parent().find('div.vox-text');
                e.preventDefault();
            } else {
                textbox = event_target;
            }

            var textbox_empty = (textbox[0].textContent.replace(/[\n, ]/g, '') === "");

            if ((event_target.hasClass('vox-send') ||
                (e.keyCode === 13 && e.shiftKey === false && e.altKey === false || e.target.className === 'voxit')) &&
                !textbox_empty) {
                this.handle_send_vox_text(e);
            } else {
                if (e.which === 13 && textbox_empty) {
                    e.preventDefault();
                }
            }
        },
        handle_keyup: function (e) {
            if (e.keyCode === 50 && (e.shiftKey || e.currentTarget.textContent.substr(-1) === "@") && !this.model.is_hotline()) { // @
                this.tag_user_on = true;
                this.search_querry = "";
                this.$el.find('.pick-user').removeClass('hide');
            } else if (e.keyCode === 32 && !this.model.is_hotline()) { // Space
                this.tag_user_on = false;
                this.$el.find('.pick-user').addClass('hide');

                // Reset the when we close it
                this.conview.collection = new Backbone.Collection(this.recipients);
                this.conview.render();
            } else if (this.tag_user_on && e.keyCode !== 40 && e.keyCode !== 38) {
                try {
                    var from = $(e.target).text().lastIndexOf('@'),
                        changed = false,
                        value;

                    if (from !== -1) {
                        value = $(e.target).text().substr(from + 1);
                        if (value !== this.search_querry) {
                            this.search_querry = value;
                            changed = true;
                        }
                    } else {
                        this.tag_user_on = false;
                        this.$el.find('.pick-user').addClass('hide');

                        // Reset the when we close it
                        this.conview.collection = new Backbone.Collection(this.recipients);
                        this.conview.render();
                    }
                } catch (err) {
                    console.error(err);
                }

                if (changed) {
                    this.conview.collection = new Backbone.Collection(_.filter(this.recipients, function (el) {
                        return el.name().toLowerCase().indexOf(this.search_querry.toLowerCase()) !== -1 ||
                            (el.get('username') && el.get('username').toLowerCase().indexOf(this.search_querry.toLowerCase()) !== -1);
                    }.bind(this)));
                    this.conview.render();
                }
            } else if (e.keyCode === 8 && $(e.target).find('a').length) { // Backspace
                $(e.target).find('a').each(function (index, mention) {
                    if (!mention.innerText.trim().length) {
                        mention.remove();
                    }
                });
            } else {
                this.handle_emotify(e);
            }
        },
        handle_keydown: function (e) {
            if (this.tag_user_on && e.keyCode === 13) { // Enter
                var selected = this.$el.find('.pick-user .selected');
                if (selected && !selected.hasClass('empty')) {
                    e.preventDefault();

                    this.handle_user_tag_pick({
                        currentTarget: selected,
                        target: selected
                    });
                } else if (selected && selected.hasClass('empty')) {
                    e.preventDefault();
                    this.tag_user_on = false;
                    this.$el.find('.pick-user').addClass('hide');

                    // Reset the list after picking one user
                    this.conview.collection = new Backbone.Collection(this.recipients);
                    this.conview.render();
                }
            } else if (this.tag_user_on && e.keyCode === 38) { // Up
                e.preventDefault();

                var selected = this.$el.find('.pick-user .selected'),
                    list = this.$el.find('.pick-user .contact-item');

                if (selected.length) {
                    var index = list.index(selected)
                    selected.removeClass('selected');

                    if (index) {
                        index -= 1;
                    } else {
                        index = list.length - 1;
                    }

                    $(list[index]).addClass('selected');
                } else {
                    list.last().addClass('selected');
                }
            } else if (this.tag_user_on && e.keyCode === 40) { // Down
                e.preventDefault();

                var selected = this.$el.find('.pick-user .selected'),
                    list = this.$el.find('.pick-user .contact-item');

                if (selected.length) {
                    var index = list.index(selected)
                    selected.removeClass('selected');

                    if (index < (list.length - 1)) {
                        index += 1;
                    } else {
                        index = 0;
                    }

                    $(list[index]).addClass('selected');
                } else {
                    list.first().addClass('selected');
                }
            }
        },
        handle_paste: function (e) {
            this.$el.find('.chat-interact .vox-text-container .vox-text')[0].scrollIntoView();
        },
        handle_emotify: _.debounce(function(e) {
            var event_target = $(e.target),
                changed = false,
                value
                textbox = $(e.target);

            value = textbox.html();
            if (value.length) {
                words = value.split(/ |&nbsp;|<br>/);
                words = _.map(words, function (word) {
                    if (minEmoji.keys.indexOf(word) !== -1) {
                        changed = true;

                        return {code: word, unicode: minEmoji.emoji_codes[word]}
                    } else {
                        return false;
                    }

                }).filter(function(emoticon) {
                    return emoticon;
                });

                if (changed) {
                    words.forEach(function (word) {
                        value = value.replace(word.code,
                            '<span data="' + word.unicode + '" class="em emj'+minEmoji.emoji[word.unicode]+'"></span>&nbsp;');
                    });
                    textbox.html(value);
                    this._vox_text_focus();
                }
            }
        }, 100),
        _vox_text_focus: function () {
            var textbox = this.$el.find('div.vox-text').get(0),
                tagName = textbox.lastChild && textbox.lastChild.tagName,
                range,
                selection;
           if (window.getSelection && document.createRange) {
                range = document.createRange();
                range.selectNodeContents(textbox);
                range.collapse(false);

                // This hack allow me to write on the same line if Firefox added an braknode
                // at the end of the contenteditable
                if (tagName == "BR")
                    range.setStart(range.startContainer, range.startOffset -= 1);

                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            } else if (document.body.createTextRange) {
                range = document.body.createTextRange();
                range.moveToElementText(textboxtextbox);
                range.collapse(false);
                range.select();
            }
            textbox.focus();
        },
        handle_html: function(html){
            var tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        handle_send_vox_text: function(e){
            var that = this, data, msg,
                        textbox, msg_text = "";

            e.preventDefault();
                //Are you not messaging a contact?
                that.add_contact_from_send();
                // if this is the first vox in a thread
                var thread_id = this.model.get('thread_id');
                if (!thread_id) {
                    this.model.generate_thread_id();
                }

                msg_text = that.$el.find('div.vox-text');
                msg_text.find('span.em').each(function(index, el) {
                    el.outerHTML = el.getAttribute('data')
                });
                msg_text.find('a[data]').each(function(index, el) {
                    var mentions = that.model.get('mentions') || [],
                        $el = $(el);

                    if (!$el.text().length || !$el.attr('data') === "undefined") {
                        el.outerHTML = "";
                        return;
                    }

                    mentions.push({
                        text: $el.text(),
                        user_id: $el.attr('data')
                    });
                    that.model.set({ mentions: mentions });
                    el.outerHTML = $el.text();
                });

                msg_text = msg_text.html();
                msg_text = msg_text.replace(/&nbsp;/g, ' ')
                msg_text = msg_text.replace(/<br>/g, '\n');
                msg_text = msg_text.replace(/<div>/g, '\n');
                msg_text = this.handle_html(msg_text);

                // post the message
                data = {
                    from: App.Auth.get("rebelvox_user_id"),
                    subject: this.model.get("subject"),
                    body: msg_text,
                    content_type: "text",
                    thread_id: this.model.get("thread_id"),
                    message_id: generate_message_id()
                };

                if (App.Settings.get('geo')) {
                    var coord = App.Settings.get('geo');
                    coord = coord.coords || undefined;
                    if (coord) {
                        data.geo = {
                            altitude: 0,
                            latitude: coord.latitude,
                            longitude: coord.longitude
                        };
                    }
                }
                if (this.model.get('mentions')) {
                    var mentions = this.model.get('mentions');
                    data.mentions = [];

                    mentions.forEach(function (mention) {
                        data.mentions.push({
                            s: data.body.indexOf(mention.text),
                            l: mention.text.length,
                            user_id: mention.user_id
                        });
                    });
                }

                // check if replying to another message
                if (this.model.get('replies_to').message_id) {
                    data.replies_to = this.model.get('replies_to');
                }

                var message = new App.Message.Model(data);

                // optimistically render the message
                message.set({
                    create_time: (new Date()).getTime() / 1000,        // native parameters
                    append: true,                       // app params (will be stripped before saving)
                    status: 'pending'
                });
                if (data.replies_to && data.replies_to.message_id) {
                    message.set({replies_to: this.model.get('replies_to')});
                    this.model.set('replies_to', {});
                }

                // add thread's messages collection if not there already
                if (!this.model.get('messages')) {
                    this.model.set({
                        'messages': new Chat.Messages()
                    });
                }
                this.model.get('messages').add(message);
                this.model.set({
                    last_message: message.attributes
                });
                this._clear_input();

                message.save().then(function (data) {
                    // update the renderering of the message text
                    message.set({
                        status: 'sent',
                        message_id: data.final_message_id,
                        normalized_create_time: data.normalized_create_time,
                        consumed: true,
                        delivery_times: {},
                        read_times: {}
                    });

                    that.model.set({ mentions: undefined });
                    App.vent.trigger('metrics:track', 'v4w/message_sent', {
                        media_type: 'text',
                        chat_participants: that.model.get_participant_count(),
                        open_chats: App.chats.where({'active': true}).length
                    });
                }).fail(function (xhr) {
                    // update the renderering of the message text
                    message.set({status: 'failed'});
                    that.model.set({ mentions: undefined });
                    // retry (a couple of times ??)
                    console.log(xhr);
                });
                that.$el.find('.button.vox-voice').removeClass('flipOutX').addClass('flipInX');
                that.$el.find('.button.vox-text').removeClass('flipInX').addClass('flipOutX');
                that.$el.find('.chat-interact').css('height', '100px');
        },
        handle_show_image_upload: function (e) {
            e.preventDefault();
        },
        handle_set_title: function (e) {
            if ($(e.currentTarget).val() === "") {
                $(e.currentTarget).parent().addClass('has-warning');
                this.$('.new-chat button').attr('disabled', true).addClass('disabled');
            }
            else {
                $(e.currentTarget).parent().removeClass('has-warning');
                this.$('.new-chat button').attr('disabled', false).removeClass('disabled');
            }
        },

        /* Helpers */
        scroll_to_latest_message: function () {
            var $scroll = this.$('.panel-body');
            $scroll.prop({scrollTop: $scroll.prop("scrollHeight")});
        },
        _clear_input: function () {
            this.$('div.vox-text').html('');
            this.$('div.vox-text').get(0).focus();
        }
    });

    Chat.Layouts.TimelinePanel = Chat.Layouts.Panel.extend({
        template: 'chat/panel/timeline_panel',
        serializeData: function () {
            var data = Chat.Layouts.Panel.prototype.serializeData.call(this);

            try {
                data.is_my_timeline = this.options.user_id && this.options.user_id === App.Auth.get('user_id');
            } catch (err) {
                console.error(err);
            }

            return data;
        },
        wheelScrollHandler: function (e) {
            var that = this,
                lastEl = this.$el.find(".panel-body .message-item:last-child"),
                out = true;
            try {
                out = lastEl[0].getClientRects()[0].top + lastEl.height() > window.document.documentElement.offsetHeight;
            } catch (err) {
                console.error(err);
            }

            if (!this.is_loading && !out) {
                this.moreMessageshandler(true);
            }
        },
    });

    Chat.Views.TaskModal = M.ItemView.extend({
        template: 'chat/panel/delete_check',
        className: 'modal logout-modal',
        events: {
            "click .btn": "handle_task"
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();
        },
        serializeData: function () {
            return {
                task: this.options.task
            };
        },
        handle_task: function () {
            var that = this,
                the_task = this.options.task,
                chat_collection = this.model.collection,
                task = (the_task === 'leave' ? 'leave' : null);


            this.model.destroy(task).then(function () {
                var view = App.UI.ViewsContainer.findByModelCid(that.model.cid);

                // If it has an pop-out chat, close it
                if (view.child_window && !view.child_window.closed) {
                    view.child_window.postMessage('close', '*');
                    view.child_window.close();
                }

                view.destroy();

                App.UI.ViewsContainer.remove(view);
                Chat.resizePanels();

                chat_collection.remove(that.model);

                // add notification to notification view
                // clean up
                that.$el.modal('hide');
                setTimeout(function () {
                    that.destroy();
                }, 300);

                // When a chat is deleted or left, open the first one in chats list
                var last_chat = App.chats.first();
                if (last_chat) {
                    last_chat.handle_active();
                } else {
                    App.Router.navigate('/chats', { trigger: true });
                }

                // track
                App.vent.trigger('metrics:track', '/' + the_task + '_chat');
            }).fail(function () {
                console.error(the_task + 'ing chat failed', that);
                App.vent.trigger('metrics:track', '/' + the_task + '_chat fail');
            });
        }
    });

    // empty collection view
    Chat.Views.NoItemsView = M.ItemView.extend({
        template: "chat/no-chats-template"
    });

    Chat.Views.NewChat = M.LayoutView.extend({
        template: 'chat/create_new_chat',
        events: {
            "click .start-chat button": "handle_start_chat",
            'focus [type="text"].tt-query': "handle_input_field",
            "keyup .input-wrap .participants": "handle_search",
            "click .contact-select ul li.contact-item a": "handle_add_participant",
            "click .holder .user .zmdi-close": "handle_remove_user",
            "click .holder .user": "handle_user_details"
        },
        regions: {
            contacts: '.new-chat .modal-body .contact-select ul'
        },
        initialize: function () {
            var that = this,
                capabilities = App.Auth.get('capabilities');

            this.capabilities = (capabilities && capabilities.capability_keys) ? capabilities.capability_keys : {};

            this.$el.on('hidden.bs.modal', function () {
                App.layout.regionContent.currentView.workspace.$el
                   .find('.new-chat-panel').remove();
                App.chats.where({was_active: true}).forEach(function (chat) {
                    chat.set({was_active: false, active: true});
                });
            });

            function onRouteChanged() {
                that.$el.find('.modal').modal('hide');
            }
            App.vent.off('route:changed', onRouteChanged);
            App.vent.on('route:changed', onRouteChanged);
        },
        ui: {
            start_button: '.start-chat button'
        },
        onShow: function () {
            var that = this;
            this.$el.find('[type="text"].tt-query').focus();
            this.$input = this.$el.find('.input-wrap .participants');
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }

            var Contact = require('modules/contact');
            var Conview = Contact.Views.List.extend({
                onBeforeRender: undefined,
                childView: Contact.Views.Item.extend({
                    events: { }
                }),
                initialize: function () {
                    var _self = this;

                    this.$el.find('img.lazy').lazyload({
                        container: this.$el.closest('.panel-body'),
                        threshold: 200,
                        effect: "fadeIn"
                    });

                    function onChangeSortBy() {
                        if (!_self.isDestroyed) {
                            _self.render();
                        } else {
                            App.Settings.off('change:sort-by', onChangeSortBy, this);
                        }
                    }
                    App.Settings.on('change:sort-by', onChangeSortBy, this);

                    function onContactsUpdated() {
                        if (!_self.isDestroyed){
                            _self.render();
                        } else {
                            App.vent.off('contacts:updated', onContactsUpdated, this);
                        }
                    }
                    App.vent.on('contacts:updated', onContactsUpdated, this);

                    function onContactsImported(collection) {
                        _self.collection.reset(collection);
                        App.vent.trigger('avatar_bust');
                    }
                    App.vent.on('contacts:imported', onContactsImported, this);

                    if (!this.options.context) {
                        this.options.context = 'Contacts';
                    }

                    this.backup_collection = this.collection.slice(0);
                    this.item_height = 47;
                    this.adding_count = 25;

                    this.collection.reset(this.backup_collection.slice(0, this.adding_count), {silent:true});
                },
                emptyView: function () {
                    return new Contact.Views.NoItemsView();
                }
            });
            this.conview = new Conview({
                collection: new Backbone.Collection(this.options.panel.local_data)
            });
            this.contacts.show(this.conview);

            this.$el.find('.new-chat .modal-body .contact-select ul').on('scroll', _.debounce(function(e) {
                var el = $(e.currentTarget);

                var adding_count = parseInt((el.scrollTop() * 2 + el.height()) / that.conview.item_height + that.conview.adding_count);

                if (adding_count > that.conview.backup_collection.length - 1) {
                    adding_count = undefined;
                }

                that.conview.collection.set(that.conview.backup_collection.slice(0, adding_count));
            }.bind(this), 100));

            this.$el.find('#new-chat-modal').on('shown.bs.modal', function () {
                that.$el.find('input.participants').focus();
            });
        },
        onRender: function () {
            this.$modal_body = this.$el.find('.new-chat .modal-body');
            this.$modal_content = this.$el.find('.new-chat .modal-content');
            this.$modal_header = this.$el.find('.new-chat .modal-header');
            this.$modal_footer = this.$el.find('.new-chat .modal-footer');
        },
        handle_input_field: function (e) {
            $(e.currentTarget).val('');
        },
        handle_activate_chat: function () {
            this.ui.start_button.removeAttr('disabled', 'disabled').removeClass('disabled');
        },
        handle_deactivate_chat: function () {
            this.ui.start_button.addClass("disabled").attr("disabled","disabled");
        },
        handle_title_input: function () {
            this.$('.start-chat button').attr('disabled', 'disabled');
        },
        handle_start_chat: function (e) {
            var that = this, create_new = true,
                me = App.Auth.get('rebelvox_user_id'),
                broadcast = this.$el.find('#broadcast-checkbox').is(':checked');
            App.chats.where({was_active: true}).forEach(function (chat) {
                chat.set({was_active: false});
            });

            if (broadcast) {
                this.model.set({
                    broadcast: broadcast
                }, {silent: true});
            }

            // set up the new group chat
            if (_.without(this.model.get('recipients'), me).length >= 2 || this.model.get('groups').length > 0) {
                // start thread
                this.model.set({
                    timestamp: (+new Date()) / 1000
                });

                // persist the group chat
                this.model.save().then(function (data) {
                    that.options.panel.remove();
                    // new chat created:
                    var new_chat = App.chats.get(data.thread_id);

                    if (!new_chat) {
                        new_chat = App.chats.add(that.model);
                        that.options.panel.ui.chat_interaction.show();
                        // remove classname
                        that.options.panel.$el.removeClass('new-chat-panel');
                    } else {
                        // fake a click on the new chat
                        new_chat.handle_active();
                    }
                }).fail(function () {
                    console.warn('FAIL to start thread');

                    var modal = new UI.Views.TaskModal({
                        callback: function (self) {
                            that.options.panel.remove();
                            self.$el.find('[data-dismiss="modal"]').click();
                        },
                        headline: 'FAIL to start thread',
                        info_text: 'We failed to start thread, please retry.',
                        ok_text: 'Ok',
                        cancel_text: null
                    });
                    App.layout.regionIntro.show(modal);

                    return;
                });
            }
            else {
                // check if that HL already exists
                var search_thread_id = generate_thread_id([this.model.get('recipients'), App.Auth.get('rebelvox_user_id')]),
                    exists = App.chats.get(search_thread_id);

                if (exists) {
                    // fake a click on the new chat
                    exists.handle_active();
                    create_new = false;
                    that.options.panel.destroy();
                }
                else {
                    this.model.generate_thread_id();
                    this.model.set({create_time: (new Date()).getTime() / 1000 });

                    // add thread to chatslist
                    App.chats.add(this.model);
                    // show buttons
                    that.options.panel.ui.chat_interaction.show();
                    // remove classname
                    this.options.panel.$el.removeClass('new-chat-panel');
                    //setting subject and title to be equal
                    this.model.set({title: this.model.get('subject')});

                    App.vent.trigger('metrics:track', 'v4w/open_chat', {
                        source: 'create_chat'
                    });

                }
            }

            if (create_new) {
                // remove the new chat form from the panel
                this.remove();

                // add the message view for the chat panel
                var messagesView = new Chat.Views.Messages({
                    collection: that.model.get('messages')
                });
                that.options.panel.messages.show(messagesView);
                that.options.panel.ui.panel_menu.show();

                // fake a click on the new chat
                that.model.handle_active();
            }
            else {
                // TODO remove the new chat panel alltogether
            }

            //for the modal
            $('.modal-backdrop.in').css('display', 'none');
        },
        add_participant_to_model: function (model, participant) {
            // do some more:
            var groups = model.get('groups'),
                recipients = model.get('recipients'),
                thread_meta = model.get('thread_meta');

            // add user_id[s] to the panel's model (chat)
            if (participant.is_team === true && groups.indexOf(participant.user_id) === -1) {
                groups.push(participant.user_id);
                model.set({'groups': _.uniq(groups)}, {silent: true});
                thread_meta.groups = _.uniq(groups);
            }
            else if (participant.is_team !== true && recipients.indexOf(participant.user_id) === -1) {
                recipients.push(participant.user_id);
                recipients = _.uniq(recipients);
                thread_meta.recipients = _.uniq(recipients);
            }
            this.$el.find('input.participants').focus();
            return _.compact(recipients.concat(groups));
        },
        remove_participant_from_model: function(model, participant) {
            var groups = model.get('groups'),
                recipients = model.get('recipients'),
                thread_meta = model.get('thread_meta');

            if (participant.is_team === true && groups.indexOf(participant.user_id) !== -1) {
                groups.splice(groups.indexOf(participant.user_id), 1);
                model.set({'groups': _.uniq(groups)}, {silent: true});
                thread_meta.groups = _.uniq(groups);
            }
            else if (participant.is_team !== true && recipients.indexOf(participant.user_id) !== -1) {
                recipients = _.uniq(recipients);
                recipients.splice(recipients.indexOf(participant.user_id), 1);
                model.set({'recipients': _.uniq(recipients)}, {silent: true});
                thread_meta.recipients = _.uniq(recipients);
            }

            return _.compact(recipients.concat(groups));
        },
        handle_search: function (e) {
            var query = e.target.value,
                all_recipients = this.model.get_recipients().recipients || [],
                local_data = this.options.panel.local_data || this.local_data,
                that = this;

            clearTimeout(this.timeout);

            var local = $.map(local_data, function(user) {
                if (user.user_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)

                    return user;
            });

            // Process the local values as soon as posible for a better UX
            process_sugestions(local);

            if (query.length > 2) {
                if (this.search && this.search.readyState < 4) {
                    this.search.abort();
                }

                this.timeout = setTimeout(function () {
                    this.search = App.API.search({q: query}, {
                        router: App.Settings.get('home_router')
                    }, function () {}, function (value) {
                        var suggestions = [];

                        local.concat(value.rows).forEach(function(user) {
                            if (typeof user.is_team == "undefined") user.is_team = 0;
                            if (typeof user.avatar_url == "undefined") user.avatar_url = "/assets/img/person.png";
                            if (typeof user.name == "undefined") user.name = user.first + " " + user.last;

                            suggestions.push(user);
                        });

                        App.API.get_teams({
                            home_router: App.Settings.get('home_router')
                        },function (e) {
                            console.trace('error getting teams', e)
                            process_sugestions(_.uniq(suggestions, function(contact) {return contact.user_id}));
                        }, function (response) {
                            suggestions = suggestions.concat(Object.keys(response.teams).map(function (key) {
                                response.teams[key].user_id = response.teams[key].id
                                response.teams[key].is_team = true
                                App.profiles.add(response.teams[key])
                                return response.teams[key]
                            }).filter(function (team) {
                                return !!team.name.toLowerCase().match(query.toLowerCase())
                            }))
                            process_sugestions(_.uniq(suggestions, function(contact) {return contact.user_id}));
                        })
                    });
                }.bind(this), 250);
            }

            function process_sugestions (data) {
                var render_recipients = data.filter(function(participant) {
                    return all_recipients.indexOf(participant.user_id) == -1;
                });

                that.conview.collection.reset(render_recipients, {silent: true});
                that.conview.backup_collection = that.conview.collection.slice(0);
                if (that.conview.collection.length > that.conview.adding_count) {
                    that.conview.collection.set(that.conview.backup_collection.slice(0, that.conview.adding_count), {silent: true});
                }

                that.conview.render();
            }
        },
        handle_add_participant: function (e) {
            e.preventDefault();
            if (this.model.get_recipients().to.length >= CONSTANTS.max_participants) {
                var modal = new UI.Views.TaskModal({
                    callback: function (self) {
                        // close the modal
                        self.$el.find('[data-dismiss="modal"]').click();
                    },
                    headline: 'Limit reached',
                    info_text: 'Can\'t add more participants, limit reached.',
                    ok_text: 'Close',
                    cancel_text: null
                });
                App.layout.regionIntro.show(modal);
                return;
            }

            var that = this,
                user_id = $(e.currentTarget).closest('a.name').attr('user-id'),
                is_pro = $(e.currentTarget).find('.label:contains("PRO")').length,
                name = $(e.currentTarget).parent().find('.name span.name').html();

            var li = _.template('<div class="user" user-id="<%- user_id %>"><p>' +
                    '<%- name %>' +
                    '<% if (is_pro) { %><span> PRO </span><% } %>' +
                    '<i class="icon-x zmdi  zmdi-close"></i>' +
                    '</p></div>', {user_id: user_id, name: name, is_pro: !!is_pro});
            this.options.panel.$('.holder').prepend(li);
            e.currentTarget.parentNode.remove();

            var participant = App.profiles.get(user_id);
            if (participant) {
                this.update_on_new_participant(participant.attributes);
            } else {
                App.profiles.get_profile(user_id).then(function(profiles) {
                    if (!profiles || !profiles.length)
                        return;

                    that.update_on_new_participant(profiles[0]);
                });
            }
        },
        handle_remove_user: function (e) {
            e.preventDefault();

            var that = this,
                node = $(e.currentTarget).closest('.user'),
                user_id = node.attr('user-id') || node.children().attr('user-id'),
                name = node.children()[0].textContent;;

                // We shouldn't be able to remove ourselve from an chat, we can leave the chat if we want
                if (user_id == App.Auth.get('rebelvox_user_id'))
                    return;

                this.update_on_new_participant({user_id: user_id, name: name}, true);

                node.remove();

                if (this.$el.find('.new-chat span.holder').length &&
                    this.model.get_participant_count() === 0) {
                    this.handle_deactivate_chat();
                }
        },
        handle_user_details: function (e) {
            if (e.target.className.indexOf('zmdi-close') !== -1)
                return;

            this.$el.find('[data-dismiss="modal"]').click();

            App.Router.navigate('/contact/' + $(e.currentTarget).attr('user-id'), {trigger: true});
        },
        update_on_new_participant: function(participant, remove) {
            var panel = this.options.panel,
                model = (panel) ? panel.model : this.options.model,
                all_recipients;

            if (remove) {
                all_recipients = this.remove_participant_from_model(model, participant);
            } else {
                all_recipients = this.add_participant_to_model(model, participant);
            }


            if (panel && panel.conview) {

                clearTimeout(this.mentions_ticker);
                this.mentions_ticker = setTimeout(function (panel, model) {
                    panel.recipients = _.map(model.get_recipients().recipients, function (user_id) {
                        return App.profiles.get(user_id) || user_id;
                    });
                    panel.conview.collection = new Backbone.Collection(panel.recipients);
                    if (panel.conview.isDestroyed) {
                        panel.onRender();
                    } else {
                        panel.conview.render();
                    }
                }.bind(this, panel, model), 2000);
            }
            var me = App.Auth.get('rebelvox_user_id');
            var myName = App.profiles.get(App.Auth.get('rebelvox_user_id')).name();
            // update the chat title
            if (all_recipients.length === 1 &&
                (model.is_hotline() || model.is_hotline() == null || model.get('subject') == myName)) {
                try {
                    var profile = App.profiles.get(all_recipients[0]);
                    var members = profile.get('members');
                    var subject = profile.name();
                    if (members && members.indexOf(me) === -1) {
                        subject = myName + ' + ' + subject;
                    }
                    model.set({'subject': subject});
                } catch (e) {
                    console.error(e);
                }
            } else if (all_recipients.length === 0 && App.profiles.get(participant.user_id).name() === model.get('subject')) {
                model.set({
                    subject: App.MyProfile.get("display_name"),
                    update_subject: true
                });
            } else {
                var new_subject = [];

                all_recipients.forEach(function (recipient_id) {
                    if (recipient_id !== App.Auth.get('rebelvox_user_id')) {
                        var name,
                            profile = App.profiles.get(recipient_id);
                        if (!profile)
                            return;

                        name = profile.get('first');
                        if (!name ) {
                            name = profile.get('name');
                        }
                        new_subject.push(name);
                    }
                });

                new_subject.unshift(App.MyProfile.get('first'));
                new_subject = new_subject.join(', ');

                if (all_recipients.length == 2 && !model.is_hotline()) {
                    try {
                        var old_subject = App.profiles.get(_.without(all_recipients, participant.user_id)[0]).name();
                        if (model.get('subject') === old_subject) {
                            model.set({
                                subject: new_subject,
                                title: new_subject,
                                update_subject: true
                            });
                        }
                    } catch (e) {
                    console.error(e);
                    }
                }

                // If the participants name joint contains old title, it means we added a new person
                // and the title should contain it too, the same is if we went to a 1:many from a 1:1,
                // otherwise we should use the old title
                if (!remove) {
                    new_subject = (new_subject.indexOf(model.get('subject')) !== -1)
                                  ? new_subject : model.get('subject');
                } else {
                    new_subject = (model.get('subject').indexOf(new_subject) !== -1)
                                  ? new_subject : model.get('subject');
                }
                if (new_subject !== model.get('subject') && !model.is_hotline()) {
                    model.set({
                        subject: new_subject,
                        title: new_subject,
                        update_subject: true
                    });
                } else if (model.is_hotline()) {
                    model.set({ _new_subject: new_subject });
                }

            }

            // show the start button
            if (this.handle_activate_chat)
                this.handle_activate_chat();

            // This stuff has to happen only for new chat, so we check for regions to make the differance
            if (this.ui && this.ui.start_button)
                this.$modal_body.height(this.$modal_content.height() - this.$modal_header.height() - this.$modal_footer.height() - 40);

            var oldSearchVal = this.$el.find('input.participants').val();
            this.$el.find('input.participants').val("").focus();

            if (remove) {
                clearTimeout(this.remove_ticker);
                this.remove_ticker = setTimeout(function (all_recipients) {
                    var render_recipients = this.conview.options.collection.filter(function(participant) {
                        return all_recipients.indexOf(participant.get('user_id')) == -1;
                    }),
                    length = (render_recipients.length < this.conview.adding_count) ? render_recipients.length : this.conview.adding_count;

                    this.conview.collection.reset(render_recipients.slice(0, length));
                }.bind(this, all_recipients), 500);
            } else if (oldSearchVal.length) {
                this.$el.find('input').trigger('keyup');
            }
        }
    });

    Chat.Views.ParticipantsWrapper = M.LayoutView.extend({
        template: 'chat/participants',
        events: {
            "click a.cancel-add-participants": "handle_cancel",
            "keyup .search-bar input": "handle_search",
            "click #all-contacts .contact-item a": "handle_add_participant",
            "click .participants-list li a": "handle_user_details",
            "click .participants-list li a i": "handle_remove_user",
            "click .start-chat .btn": "handle_create_new_groupchat",
        },
        regions: {
            contacts: '.contacts-select-wrapper .modal-body #all-contacts ul'
        },
        serializeData: function () {
            var recips = [],
                participants = [],
                recipients = this.model.get_recipients(),
                that = this,
                creator = "",
                creator_id = this.model.get('creator'),
                unknown_participants = [];

            recips = recipients.to;
            recips.push(App.Auth.get("rebelvox_user_id"));
            recips = _.uniq(recips);
            recips.forEach(function (participant_id) {
                var participant = App.profiles.get(participant_id);
                if (participant) {
                    if (participant_id == creator_id) {
                        participant.set({is_admin: true})
                    }
                    participants.push(participant);
                } else {
                    unknown_participants.push(participant_id);
                }
            });

            // For unknow participants we will fetch the details and append them later
            // so we won't block the user experiance
            if (unknown_participants.length) {
                App.profiles.get_profile(unknown_participants).then(function() {
                    unknown_participants.forEach(function (participant_id) {
                        var participant = App.profiles.get(participant_id);

                        if (participant) {
                            var _new_entry = {
                                name: participant.get('name'),
                                user_id: participant.get('user_id'),
                                is_pro: participant.get('vpro_account_badge')
                            }
                            if (participant_id == creator_id) {
                                _new_entry.is_admin = true
                            }
                            that.$el.find('ul.contact-list').append(_.template(that.addedParticipantTemplate, _new_entry));
                        }
                    });
                });
            }

            if (that.admin_controled) {
                try {
                    creator = App.profiles.get(that.model.get('creator')).get('name');
                } catch (e) {
                    creator = App.MyProfile.get('display_name');
                }
            }

            return {
                participants: participants,
                admin_controled: that.admin_controled,
                creator: creator,
                is_hotline: this.model.is_hotline()
            };
        },
        initialize: function () {
            var that = this,
                capabilities = App.Auth.get('capabilities');

            this.capabilities = (capabilities && capabilities.capability_keys) ? capabilities.capability_keys : {}
            that.admin_controled = that.model.get('controlled_chat')
                && that.model.get('creator') !== App.Auth.get('user_id');
            this.$el.on('hidden.bs.modal', function () {
                if (that.options.target)
                    that.options.target.toggleClass('open');

                if (that.model.is_hotline() && that._recipients && that._recipients.length) {
                    that.create_new_groupchat();
                }
            });

            this.addedParticipantTemplate = '<li class="pull-left ">' +
                    '<a href="" class="team-contact-link green" user-id="<%- user_id %>">' +
                    '<div class="pull-left username <% if (is_pro) { %> is-pro <% } %>"><%- name %></div>' +
                    '<% if (is_pro) { %><span> PRO </span><% } %>' +
                    '<i class="pull-right zmdi  zmdi-close" style="padding:5px 0 0 5px;"></i>' +
                    '</a></li>';
        },
        handle_search: function (e) {
            return Chat.Views.NewChat.prototype.handle_search.call(this, e);
        },
        handle_add_participant: function (e) {
            var that = this,
                user_id = $(e.currentTarget).closest('a.name').attr('user-id'),
                is_pro = $(e.currentTarget).find('.label:contains("PRO")').length,
                name = $(e.currentTarget).parent().find('.name span.name').html();

            if (that.admin_controled){
                return;
            }

            if (this.model.get_recipients().to.length >= CONSTANTS.max_participants) {
                var modal = new UI.Views.TaskModal({
                    callback: function (self) {
                        // close the modal
                        self.$el.find('[data-dismiss="modal"]').click();
                    },
                    headline: 'Limit reached',
                    info_text: 'Can\'t add more participants, limit reached.',
                    ok_text: 'Close',
                    cancel_text: null
                });
                App.layout.regionIntro.show(modal);
                return;
            }

            var li = _.template(that.addedParticipantTemplate, {user_id: user_id, name: name, is_pro: !!is_pro});
            that.$('.participants-list ul').append(li);
            e.currentTarget.parentNode.remove();

            var participant = App.profiles.findWhere({user_id: user_id});
            if (participant) {
                this.update_on_new_participant(participant.attributes);
            } else {
                App.profiles.get_profile(user_id).then(function() {
                    participant = App.profiles.findWhere({user_id: user_id});
                    that.update_on_new_participant(participant.attributes);
                });
            }
        },
        handle_user_details: function (e) {
            this.$el.find('[data-dismiss="modal"]').click();

            App.Router.navigate('/contact/' + $(e.currentTarget).attr('user-id'), {trigger: true});
        },
        handle_remove_user: function (e) {
            e.preventDefault();
            e.stopPropagation();

            var node = $(e.currentTarget).closest('.team-contact-link'),
                user_id = node.attr('user-id') || node.children().attr('user-id'),
                name = node.children()[0].textContent,
                groupChatCreator = this.model.get('creator');

            if (this.admin_controled){
                return;
            }
            if (!App.Auth.isProUser()) {
                App.vent.trigger('metrics:track', '/upgrade_screen', {
                    from: 'chat',
                    feature: 'remove'
                });

                // go to upgrade page and close this one.
                App.Router.navigate('/upgrade', { trigger: true });
                this.$el.find('[data-dismiss="modal"]').click();

                return;
            }

            if (this.model.is_hotline() && (!this._recipients || this._recipients.indexOf(user_id) !== -1)) {
                alert("You can't remove users from an 1:1 chats.");
                return false;
            } else if (this.model.is_hotline() && this._recipients) {

                this.remove_participant_from_model = function (model, participant) {
                    var index = this._recipients.indexOf(participant.user_id);
                    this._recipients.splice(index, 1);

                    return this._recipients;
                }
            }

            // We shouldn't be able to remove ourselve from an chat, we can leave the chat if we want
            if (user_id == App.Auth.get('rebelvox_user_id')){
                var modal = new UI.Views.TaskModal({
                    callback: function (self) {
                        // close the modal
                        self.$el.find('[data-dismiss="modal"]').click();
                    },
                    headline: 'Group Chat Controls',
                    info_text: 'You couldn\'t remove yourself from a chat, you can leave the chat if you want.',
                    ok_text: 'Close',
                    cancel_text: null
                });
                App.layout.regionIntro.show(modal);
                return
            }

            // the chat creator (admin) cannot be removed from the thread
            if (user_id == groupChatCreator){
                var modal = new UI.Views.TaskModal({
                    callback: function (self) {
                        self.$el.find('[data-dismiss="modal"]').click();
                    },
                    headline: 'Group Chat Controls',
                    info_text: 'The chat creator or admin cannot be removed.',
                    ok_text: 'Close',
                    cancel_text: null
                })
                App.layout.regionIntro.show(modal)
                return
            }

            // User shouldn't remove teams from teams own thread
            var group = this.model.get('groups') ? this.model.get('groups')[0] : false;
            if (group && this.model.get('thread_id').indexOf(group) !== -1){
                return;
            }
            node.parent().remove();
            this.update_on_new_participant({user_id: user_id, name: name}, true);
        },
        update_on_new_participant: function (participant, remove) {
            return Chat.Views.NewChat.prototype.update_on_new_participant.call(this, participant, remove);
        },
        add_participant_to_model: function (model, participant) {
            var that = this,
                recipients = that._recipients || that.model.get_recipients().to;

            if (this.model.is_hotline()) {
                recipients.push(participant.user_id);
                that._recipients = recipients;
                return that._recipients;
            }
            else {
                // send the add participant event to backend
                var post_body = {
                    "thread_id": this.model.get('thread_id')
                };

                if (participant.is_team) {
                    var _groups = that.model.get('groups');
                    _groups.push(participant.user_id);
                    this.model.set({groups: _groups});
                    post_body.group_ids = [participant.user_id];
                    post_body.user_ids = [];
                }
                else {
                    var _recipients = this.model.get('recipients');
                    _recipients.push(participant.user_id);
                    this.model.set({recipients: _recipients});
                    post_body.user_ids = [participant.user_id];
                    post_body.group_ids = [];
                }

                App.API.add_participants(post_body, {
                    router: Settings.fetch('home_router')
                }, function (data) {
                    debugger;
                }, function (data) {

                    // create a system message and add to chat
                    var message_data = {
                        alt_text: "You added " + participant.name + " to the chat.",
                        body: "You added " + participant.name + " to the chat.",
                        consumed: true,
                        content_type: "text",
                        normalized_create_time: data.normalized_create_time,
                        posted_time: (+new Date() / 1000),
                        from: App.Auth.get('user_id'),
                        recipients: that.model.attributes.recipients,
                        message_id: data.final_message_id,
                        subcontent_type: "add_participants",
                        subject: that.model.get('subject'),
                        thread_id: that.model.attributes.thread_id
                    };
                    App.Message.route([message_data], {live: false});
                });
                this.$el.find('input.participants').focus();
                return Chat.Views.NewChat.prototype.add_participant_to_model.call(this, model, participant);
            }
        },
        remove_participant_from_model: function (model, participant) {
            var that = this;

            // send the add participant event to backend
            var post_body = {
                "thread_id": this.model.get('thread_id')
            };

            if (participant.is_team) {
                var _groups = that.model.get('groups');
                _groups.push(participant.user_id);
                this.model.set({groups: _groups});
                post_body.group_ids = [participant.user_id];
                post_body.user_ids = [];
            }
            else {
                var _recipients = this.model.get('recipients');
                _recipients.push(participant.user_id);
                this.model.set({recipients: _recipients});
                post_body.user_ids = [participant.user_id];
                post_body.group_ids = [];
            }

            App.API.remove_participants(post_body, {
                router: Settings.fetch('home_router')
            }, function (data) {
                console.warn(data);
            }, function (data) {
                // create a system message and add to chat
                var message_data = {
                    alt_text: "You have removed " + participant.name + " from the chat.",
                    body: "You have removed " + participant.name + " from the chat.",
                    consumed: true,
                    content_type: "text",
                    normalized_create_time: data.normalized_create_time,
                    posted_time: (+new Date() / 1000),
                    from: App.Auth.get('user_id'),
                    recipients: that.model.attributes.recipients,
                    message_id: data.final_message_id,
                    subcontent_type: "remove_participant",
                    subject: that.model.get('subject'),
                    thread_id: that.model.attributes.thread_id
                };
                App.Message.route([message_data], {live: false});

                App.vent.trigger('metrics:track', '/user_removed');
            });

            return Chat.Views.NewChat.prototype.remove_participant_from_model.call(this, model, participant);
        },
        onRender: function () {
            if (!this._isShown) {
                // some nice animatronic
                this.$el.hide().fadeIn();
            }

            var local_data,
                that = this;

            local_data = _.reject(App.profiles.map(function (m) {
                if ((m.attributes.is_contact || m.attributes.is_team) && m.attributes.user_id !== App.Auth.get('rebelvox_user_id')) {
                    if (m.attributes.is_team === undefined) {
                        m.attributes.is_team = false;
                    }
                    if (m.attributes.is_contact === undefined) {
                        m.attributes.is_contact = false;
                    }
                    m.attributes.avatar_url = m.avatar_src();
                    m.attributes.username = m.attributes.username || "";
                    return m.attributes;
                }
            }), function (m) {
                return m === undefined || (m.account_flags && m.account_flags.indexOf('user_deleted') !== -1) || (m.accounts_flags && m.accounts_flags.indexOf('user_deleted') !== -1);
            });

            this.local_data = local_data;

            this.$el.on('shown.bs.modal', function () {
                that.$el.find('input.participants').focus();
            });

            this.$el.on('hidden.bs.modal', function () {
                if (!that.model.get('update_subject'))
                    return;

                App.API.change_title({
                    thread_id: that.model.get('thread_id'),
                    chat_name: that.model.get('subject')
                }, {
                    router: App.Settings.fetch('home_router')
                }, function (data) {
                    that.$el.find('h1').addClass('has-error');
                }, function (data) {
                    that.model.set({'update_subject': false});
                });
            });

            // let's ensure the correct one opens
            this.$el.find('#add-users-chat').modal('show');
            this.$modal_body = this.$el.find('.modal-body');
            this.$modal_content = this.$el.find('.modal-content');
            this.$modal_header = this.$el.find('.modal-header');
            this.$modal_footer = this.$el.find('.modal-footer');
        },
        onShow: function () {
            var that = this,
                Contact = require('modules/contact'),
                Conview = Contact.Views.List.extend({
                    onBeforeRender: undefined,
                    childView: Contact.Views.Item.extend({
                        events: { }
                    }),
                    initialize: function () {
                        var _self = this;

                        this.$('img.lazy').lazyload({
                            container: this.$el.closest('.panel-body'),
                            threshold: 200,
                            effect: "fadeIn"
                        });

                        function onChangeSortBy() {
                            if (!_self.isDestroyed) {
                                _self.render();
                            } else {
                                App.Settings.off('change:sort-by', onChangeSortBy, this);
                            }
                        }
                        App.Settings.on('change:sort-by', onChangeSortBy, this);

                        function onContactsUpdated() {
                            if (!_self.isDestroyed){
                                _self.render();
                            } else {
                                App.vent.off('contacts:updated', onContactsUpdated, this);
                            }
                        }
                        App.vent.on('contacts:updated', onContactsUpdated, this);

                        function onContactsImported(collection) {
                            _self.collection.reset(collection);
                            App.vent.trigger('avatar_bust');
                        }
                        App.vent.on('contacts:imported', onContactsImported, this);

                        if (!this.options.context) {
                            this.options.context = 'Contacts';
                        }

                        this.backup_collection = this.collection.slice(0);
                        this.item_height = 47;
                        this.adding_count = 25;

                        this.collection.reset(this.backup_collection.slice(0, this.adding_count), {silent:true});
                    },
                    emptyView: function () {
                        return new Contact.Views.NoItemsView();
                    }
                }),
                all_recipients = this.model.get_recipients().recipients || [],
                render_recipients;

            render_recipients = this.local_data.filter(function(participant) {
                return all_recipients.indexOf(participant.user_id) == -1;
            });

            this.conview = new Conview({
                collection: new Backbone.Collection(render_recipients)
            });
            this.contacts.show(this.conview);

            this.$el.find('.contacts-select-wrapper .modal-body #all-contacts ul').on('scroll', _.debounce(function(e) {
                var el = $(e.currentTarget);

                var adding_count = parseInt((el.scrollTop() * 2 + el.height()) / that.conview.item_height + that.conview.adding_count);

                if (adding_count > that.conview.backup_collection.length - 1) {
                    adding_count = undefined;
                }

                that.conview.collection.set(that.conview.backup_collection.slice(0, adding_count));
            }.bind(this), 100));

            this.$input = this.$el.find('.search-bar input.participants');
            this.$input.focus().select();
        },
        create_new_groupchat: function () {
            var that = this, create_new = true,
                new_chat_model = new Chat.Model(),
                subject = this.model.get('_new_subject');

            if (_.isEmpty(subject.replace(' ', ''))) {
                this.options.panel.$('h1').addClass('has-error');
                this.$('.help').removeClass('hide');
                return;
            }
            // start thread
            new_chat_model.set({
                subject: subject,
                recipients: that._recipients
            });

            App.chats.on('add', function openChatOnAdd(model) {
                App.chats.off('add', openChatOnAdd);

                // open the new panel
                model.handle_active();

                // Render the previous Vhat Item View so we get the curent title
                // The purpous of the try/catch is to catch any possible excaption,
                // which will brake the flow here and we won't create the new chat,
                // creating the new chat is more important than rendering the correct subject
                try {
                    App.layout.regionContent.currentView.left
                       .currentView.list.currentView.children
                       .findByModelCid(that.model.cid).render();
                } catch (e) {
                    console.warn(e);
                }
            });

            // persist the group chat
            new_chat_model.save().done(function () {
                App.chats.add(new_chat_model);
            }).fail(function () {
                console.log('FAIL to start thread');
            });
            this.model.generate_thread_id();

            // add thread to chatslist
            App.chats.add(this.model);
        }
    });

    Chat.resizePanels = function () {
        App.UI.resizeChatPanels();
    };

    return Chat;
});

