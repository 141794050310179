define('modules/message',[
    'app',
    // Libraries.
    'backbone',

    // modules
    "modules/chat",
    "modules/contact",
    "main/lib",
    "main/settings",
    "main/UI",
    "main/cache",
    "libs/minEmoji",
    'moment',
    "constants"

], function (App, Backbone, Chat, Contact, Lib, Settings, UI, Cache, minEmoji, moment) {
    var M = Backbone.Marionette;
    var Message = App.module();

    Message.Model = Backbone.Model.extend({
        idAttribute : "message_id",
        defaults: {
            playback_rate: 1
        },
        url: function (query_params) {
            return App.create_url('2/cs/post_message', query_params);
        },
        save: function (ajax_options) {
            var post_data = {
                    "message_id": this.attributes.message_id || generate_message_id(),
                    "create_time":  (new Date()).getTime() / 1000,              // MUST be recreated everytime for a send, even if retrying to send after error
                    "model": navigator.userAgent.toLowerCase()
                },
                post_body,
                that = this,
                options = {},
                deferred = $.Deferred();


            post_data = _.extend(post_data, {
                from: this.get('from'),
                subject: this.get('subject'),
                body: this.get('body'),
                thread_id: this.get('thread_id')
            });

            if (this.get('expires')) {
                post_data.expires = this.get('expires');
            }

            if (this.get('geo')) {
                post_data.geo = this.get('geo');
            }

            if (this.get('mentions')) {
                post_data.mentions = this.get('mentions');
            }

            if (this.get("replies_to")) {
                post_data.replies_to = this.get("replies_to");
            }

            options = {
                router: App.Settings.get("home_router")
            }

            if (this.get('subcontent_type') !== undefined && this.get('subcontent_type')  === "file_share") {
                post_data.file_link_uri = this.get('file_link_uri');
                post_data.file_name = this.get('file_name');
                post_data.file_extension = this.get('file_extension');
                post_data.file_size = this.get('file_size');
                post_data.provider = this.get('provider');
                post_data.icons = this.get('icons');
                post_data.preview_link = this.get('body').replace('www.dropbox.com', 'dl.dropboxusercontent.com');


                App.API.send_file(post_data, options, function (err) {
                    console.log("ERROR in sent_text", err);
                    return deferred.reject(err);
                }, function (data, textStatus, jqXHR) {
                    return deferred.resolve(data);
                });
            } else {
                App.API.send_text(post_data, options, function (err) {
                    console.log("ERROR in sent_text", err);
                    return deferred.reject(err);
                }, function (data, textStatus, jqXHR) {
                    return deferred.resolve(data);
                });
            }

            return deferred.promise();
        },
        initialize: function (data) {
            var stared = this.get('thread_id') && this.get('thread_id').indexOf('FC_') !== -1;

            this.on('change:consumed', function () {
                var chat = App.chats.findWhere({thread_id: this.get('thread_id')});
                if (chat && this.get('consumed')) {
                    chat.trigger('change:unread_count');
                }
            });

            if (stared && this.get('message_id')) {
                var _id = this.get('message_id').split('.rvx.')[0];
                App.staredMessages.push(_id);
                App.vent.trigger('stared', _id);
            }

            if (stared || App.staredMessages.indexOf(this.get('message_id')) !== -1) {
                this.set({favorited: true});
            }

            App.vent.on('stared', function (message) {
                if (!this.get('favorited') && this.get('message_id') === message) {
                    this.set({'favorited': true});
                }
            }.bind(this));

            App.vent.on('unstared', function (message) {
                if (this.get('favorited') && this.get('message_id') === message) {
                    this.set({'favorited': false});
                }
            }.bind(this));
        },
        revox_message: function (thread_id, error, success) {
            var data = {
                thread_id: thread_id,
                message_id: this.get('message_id'),
            };

            if (this.get('geo')) {
                data['geo'] = this.get('geo');
            }

            App.vent.trigger('metrics:track', '/favorite', {
                media_type: this.get('content_type')
            });

            return App.API.revox(data, {router: App.Settings.get('home_router')}, error, success);
        },
        handle_share_message: function(target) {
            var deferred = new $.Deferred(),
                that = this;

            if (this.get('sharing_url')) {
                deferred.resolve({
                    sharing_url: this.get('sharing_url')
                });
            } else {
                App.API.share_message({
                    message_id: this.get('message_id')
                }, {
                    home_router: App.Settings.get("home_router")
                }, function(error) {
                    deferred.reject(error);
                }, function(data) {
                    that.set({
                        sharing_url: data.sharing_url,
                        web_id: data.web_id
                    });

                    deferred.resolve(data);
                });
            }

            return deferred.promise().then(function(data) {
                var sharing_url = data.sharing_url;
                switch (target) {
                    case "email":
                        window.location.href = 'mailto:?subject=Vox From ' + (that.get('sender_name') || that.get('sender')) +
                            '&body=Listen to my vox \n '+sharing_url;
                        break;
                    case "link":
                        var modal = new UI.Views.ShareVoxModal({
                            headline: 'Copy the link to share',
                            callback: function () {
                            },
                            info_text: sharing_url,
                            instructions: 'The URL of this Vox is below. Copy it and start sharing with your friends.'
                        });
                        App.layout.regionIntro.show(modal);
                        break;
                    case "facebook":
                        FB.ui({
                            method: 'feed',
                            link : sharing_url,
                            caption: that.get('caption') || "Listen to my vox"
                        });
                        break;
                    case "twitter":
                        var popup = window.open("https://twitter.com/share?url=" + sharing_url +
                            "&text=Listen to my vox" +
                            "&hashtags=Voxer",
                            "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=550")
                        break;
                    default:
                        console.log(target);
                }

            }).fail(function(error) {
                console.error(error);
            });

        },
        recall_message: function (error, success) {
            if (!this.get('thread_id') || !this.get('message_id'))
                return;

            var data = {
                thread_id: this.get('thread_id'),
                message_ids: [this.get('message_id')],
                create_time: this.get('create_time') || this.get('normalized_create_time')
            };

            App.vent.trigger('metrics:track', '/message_recall_tap', {
                media_type: this.get('content_type')
            });

            return App.API.recall(data, {router: App.Settings.get('home_router')}, error, success);
        },
        play_vox: (function () {
            var selectedQueue = [], that = this;

            function queueNextSound(type, sound, playObject, context) {
                var soundObject = {type: type, sound: sound, playObject: playObject};
                if (type === "interrupt" || type === "update") {
                    selectedQueue = [soundObject];
                } else if (type === "normal") {
                    selectedQueue.push(soundObject);
                } else if (type === "live") {
                    if (selectedQueue[0] && selectedQueue[0].playObject.model.collection !== playObject.model.collection) {
                        sound.outOfSequence = true;
                        playNextSound(soundObject);
                    } else if (selectedQueue[0] && selectedQueue[0].sound.state === "paused") {
                        sound.outOfSequence = true;
                        selectedQueue.splice(0, 0, soundObject);
                    } else {
                        selectedQueue.push(soundObject);
                    }
                } else {
                    throw new Error("unhandled sound type " + type);
                }
                checkNextSound();
            }


            function completeCurrentSound(sound) {
                if (sound.outOfSequence) {
                    return;
                }
                if (selectedQueue.length) {
                    selectedQueue.shift();
                }
                checkNextSound();
            }

            function checkNextSound() {
                if (selectedQueue.length) {
                    playNextSound(selectedQueue[0]);
                }
            }

            function playNextSound(object) {
                if (object.sound.state === "paused") {
                    object.sound.resume();
                    return;
                }
                if (object.sound.state === "stopped") {
                    object.sound.play();
                }
            }


            return function (type) {
                var message_id = this.get('message_id'),
                    deferred = $.Deferred(),
                    that = this,
                    sound = App.VoxerAudio.get_live_audio(message_id, function (sound) {
                        deferred.resolve();
                        that.set({'playing': true});
                        that.set({'started_live_at': Date.now()});

                        if (!App.chat.Model.prototype.is_profile.call(that)) {
                            App.chats.findWhere({thread_id: that.get('thread_id')})
                               .consume_messages(message_id).done(function() {
                                   that.set({consumed: true});
                               });
                        }

                        if (type !== "interrupt") {
                            that.trigger('scroll');
                        }
                    }, function (sound) {
                        if (sound.state == "paused") {
                            that.set({'playing': false});
                        } else if (sound.state == "stopped") {
                            that.trigger('finish');
                            that.set({'playing': false});
                        }
                        that.unset('started_live_at');
                        completeCurrentSound(sound);
                    }, function (position) {
                        if (that.get('playing')) {
                            that.trigger('update', position * 100);
                        }
                    });

                queueNextSound(type, sound, {
                    id: message_id,
                    model: that
                }, this);

                return deferred.promise();
            };
        })()
    });


    Message.Collection = Backbone.Collection.extend({
        model: Message.Model,
        initialize: function () {
            this.recalled = [];
        },
        comparator: function (message) {
            // keep sorted in reverse order
            return  -right_time(message.attributes);
        },
        remove: function (data) {
            Backbone.Collection.prototype.remove.call(this, data);
        },
        recall_message: function (message_id, chats, stared) {
            if (!message_id)
                return;

            for(var x = 0; x < message_id.length; x++) {
                if (stared) {
                    try {
                        var messages = this.get(message_id[x])
                        if (messages)
                            App.vent.trigger('unstared', messages.get('revox').message_id);
                    } catch (e) {
                        console.error(e);
                    }
                }

                this.hard_remove(message_id[x], App.chats);
                this.recalled[message_id[x]] = true;
            }
        },
        is_recalled: function (message_id) {
            if (this.recalled[message_id] !== undefined) return true;

            return false;
        },
        /**
         * Removes messages from chats and from message collection
         * @name message.collection#hard_remove
         * @function
         * @param {array} data - array of message ids to delete from message collection
         * @param {Chat collection} chats - Collection of chats to remove messages from
         */
        hard_remove: function (data, chats) {
            if (data === undefined) {
                return undefined;
            }

            if (typeof data !== "object") {
                data = [data];
            }

            chats = chats || App.chats;

            var that = this;
            data.forEach(function (id) {
                var message = that.get(id),
                    chat;

                if (message !== undefined) {
                    chat = chats.where({thread_id: message.get('thread_id')});

                    if (chat !== undefined) {

                        chat.forEach(function (chat) {
                            var chat_messages = chat.get('messages');
                            if (chat_messages !== undefined) {
                                chat_messages.remove({id: id});
                            }
                        });
                    }
                    that.remove({id: id});
                }
            });
        }
    });


    Message.content_type_handlers = {
        profile: function (data) {
            if (data.account_flags)
                data.profile.account_flags = data.account_flags;
            if (data.business_ids)
                data.profile.business_ids = data.business_ids;
            if (data.user_identities)
                data.profile.user_identities = data.user_identities;

            App.MyProfile.set(data.profile);
        },
        contacts: function (data) {
            if (!App.container) {
                App.container = {contacts: {}};
            }

            Object.keys(data.contacts).forEach(function(user_id) {
                App.container.contacts[user_id] = data.contacts[user_id];
                var profile = App.profiles.get(user_id);
                if (profile && data.contacts[user_id].last_modified > profile.get('last_modified')) {
                    profile.set({
                        last_modified: data.contacts[user_id].last_modified,
                        tags: data.contacts[user_id].tags,
                        is_contact: true
                    });
                } else if (profile) {
                    profile.set({ is_contact: true });
                }
            });
        },
        thread_meta: function (data) {
            if (data) {
                data.title = data.subject;
                var timestamp = data.create_time || data.last_modified_time;
                if (timestamp) {
                    data.timestamp = timestamp;
                    App.chats.add(data, {silent: false});
                }
            }
        },
        public_profiles: function (data) {
            if (!App.container) {
                App.container = {contacts: {}};
            }

            if (data) {
                var profiles = [];
                _.each(data.profiles, function (profile) {
                    if (App.container.contacts[profile.user_id]) {
                        profile.is_contact = true;
                        profile.last_modified = App.container.contacts[profile.user_id].last_modified;
                        profile.tags = App.container.contacts[profile.user_id].tags;
                    }
                    profiles.push(profile);
                });
                if (profiles.length > 0) {
                    App.profiles.add(profiles, {merge: true});
                }
            }
        },
        teams: function (data) {
            var teams_array = [];
            _.each(data.teams, function (team, idx, teams) {
                team.user_id = idx;
                team.first = "";
                team.last = team.name;
                team.is_team = true;
                // add to profiles temp container
                teams_array.push(team);
            });
            if (teams_array.length > 0) {
                // add to profiles collection - once to avoid resetting and triggering render
                // (filtered collections reset which triggers re-render of collectionview)
                App.profiles.add(teams_array);
            }
        },
        // meta handler for delivery and read receipts
        receipts: function (data, chat, receipt_type) {
            if (chat === undefined) {
                return;
            } else if (chat.get === undefined ){
                console.log('Issue with chat object', chat);
                return;
            }
            var messages = chat.get('messages'),
                receipt_message = data;

            // get all messages from chat
            _.forEach(data[receipt_type + '_times'], function (prop, key) {
                var m = messages.get(key);
                if (m) {
                    if (!m.get('read_times')) {
                        m.set({read_times: {}}, {silent: true});
                    }
                    if (!m.get('delivery_times')) {
                        m.set({delivery_times: {}}, {silent: true});
                    }
                    m.attributes[receipt_type + '_times'][this.from] = prop;
                    m.trigger('change:' + receipt_type + '_times', receipt_type + '_times');

                    if (receipt_type === 'read')
                        m.set({'consumed': true});
                }
            }, data);
        },
        read_receipt: function (data, chat) {
            Message.content_type_handlers['receipts'](data, chat, 'read');
        },
        delivery_receipt: function (data, chat) {
            Message.content_type_handlers['receipts'](data, chat, 'delivery');
        },
        like_receipt: function (data, chat) {
            var messages = chat.get('messages');

            _.forEach(data['like_times'], function (prop, key) {
                var m = messages.get(key),
                    like_times = {},
                    length = 0;
                if (m) {
                    like_times = m.get('like_times') || {};
                    length = Object.keys(like_times).length;

                    if (data.like) {
                        like_times[data.from] = prop;
                    } else {
                        if (like_times[data.from] && like_times[data.from] < prop) {
                            delete like_times[data.from];
                        }
                    }

                    m.set({ 'like_times': like_times });
                    if (length !== Object.keys(like_times).length) {
                        m.trigger('change:like_times', 'like_times');
                    }
                }
            }.bind(this));
        },
        start_thread: function (data, Chat) {
            var chat_data = {
                "thread_id": data.thread_id,
                "creator": data.creator || App.Auth.get('user_id'),
                "create_time": data.create_time,
                "timestamp": data.create_time,
                "subject": data.subject,
                "title": data.subject,
                "recipients": data.recipients,
                "groups": data.groups,
                "unread_count": data.unread_count || 0
            },
            existing_chat = App.chats.get(data.thread_id);

            if (existing_chat && parseFloat(chat_data.create_time) > existing_chat.get('create_time')) {
                existing_chat.set(chat_data);
            } else {
                App.chats.add(chat_data);
            }
        },
        consume_messages: function (data, chat, once) {
            if (chat === undefined || chat.get === undefined) {
                // If the chat does't exist, means the messages we want to consume
                // hasn't been proccessed yet, so we try again once more after 1s
                if (!once) {
                    _.delay(function () {
                        this.consume_messages(data, App.chats.get(data.thread_id), true);
                    }.bind(this), 1000);
                }

                // get the chat
                return;
            }
            var that = this,
                messages = chat.get('messages');

            if (data.message_ids && data.message_ids.length > 0) {
                data.message_ids.forEach(function (id) {
                    var m = messages.get(id);
                    if (m) {
                        m.set({consumed: true});
                    } else if (!once) {
                        _.delay(function () {
                            that.consume_messages(data, App.chats.get(data.thread_id), true);
                        }.bind(this), 1000);
                    }
                });
            } else if (data.allow_consume_all) {
                messages.where({consumed:false}).forEach(function (m) {
                  m.set({consumed: true});
                });
            }
        },
        timeline_complete: function (data, chat) {
            // use timeline complete as a trigger

            if (App.Settings.get('first_render') === undefined) {
                App.Settings.set('first_render', true);
                App.vent.trigger('render_active_chats');
            }
            $.ajax({
                type: "GET",
                url: App.create_url("1/cs/user/summary"),
                dataType: "text",
                xhrFields: {
                    withCredentials: true
                },
                success: function (data, textStatus, jqXHR) {
                    App.pbr_summary.set(JSON.parse(data));
                },
                error: function (err) {
                    console.log(err);
                }
            });
            App.API.ack_timeline({
                high_water_mark: data.posted_time
            }, {
                router: App.Settings.get('home_router')
            });
        },
        teams_changed: function () {
            // load teams (automatically updates teams)
            App.profiles.fetch_teams();
        },
        modify_chat_name: function (data, chat) {
            if (chat instanceof App.chat.Model == false || (chat.get('title') === data.chat_name && chat.get('subject') === data.chat_name))
                return;

            chat.set({'title': data.chat_name, 'subject': data.chat_name});

            App.Message.route([
                _.extend(data, {
                    body: data.sender_name + " named the chat " + data.chat_name,
                    content_type: "text",
                    consumed: true,
                    system: true
                })
            ], {live: false});
        },
        modify_controlled_chat: function (data, chat) {
            if (data.controlled_chat === chat.get('controlled_chat'))
                return;

            chat.set({
                controlled_chat: data.controlled_chat
            });

            App.Message.route([
                _.extend(data, {
                    content_type: "text",
                    consumed: true,
                    system: true
                })
            ], {live: false});
        },
        user_settings: function (data) {
            App.Settings.set({user_settings: data.user_settings});
        }
    };

    Message.update_timeline = function (end_time, messages_expire_ts, start_time) {
        var url,
            params;

        if (end_time || start_time) {
            params = {};

            if (end_time)
                params['end_time'] = end_time;

            if (start_time)
                params['start_time'] = start_time;

            url = App.create_url('3/cs/timeline', params);
        } else {
            url = App.create_url('3/cs/timeline');
        }

        if (messages_expire_ts !== undefined) {
            App.Message.delete_expired(messages_expire_ts);
        }

        return Lib.json_crlf_streamer(url, function (err, object) {
            if (err) {
                if (err.status === 402) {
                    // restart session and start over
                    App.Auth.getAuth().done(function (data) {
                        App.request('load:allthings');

                        return App.Message.update_timeline(end_time, messages_expire_ts, start_time);
                    }).fail(function (xhr) {
                        console.error(xhr);
                    });
                }
            } else {
                // route the "messages"
                if (object.args) {
                    App.Message.route([object.args], {live: false});
                }
            }
        }).always(function () {
            // Start the update channel and monitor
            var updates = App.Updates.start();
            if (typeof updates.monitor === 'function') {
                updates.monitor();
            }
            console.log("UC and UC monitor started");
        });
    };

    /**
     * Find all expired messages and remove them from global messages and chats
     * @name message#delete_expired
     * @function
     * @param {integer} timestamp - describes the time all messages before the time have to be deleted.
     */
    Message.delete_expired = function (timestamp) {
        var to_delete = App.messages.filter(function (val) {
            return right_time(val.attributes) > timestamp;
        });
        var message_ids = _.map(to_delete, function (model) { return model.get('message_id'); });

        App.messages.hard_remove(message_ids, App.chats);
    };

    // message "router"
    // puts any incoming message (be it from timeline calls or update channel) in the right place
    Message.route = function (raw_messages, options) {
        if (!Chat) {
            var Chat = require("modules/chat");
        }

        var live = options.live;
        var subcontent_type_actions = {
            'remove_participant': {
                add: true,
                'action': function (data, chat) {
                    // remove leaving user_id from recipients list(s)
                    var recipients = chat.get('recipients'),
                        recipients2 = chat.get('thread_meta'),
                        remove = data.user_ids.slice(),
                        remove2 = data.user_ids.slice();

                    data.system = true;
                    data.consumed = true;

                    remove.unshift(recipients);
                    remove2.unshift(recipients2);

                    recipients = _.without.apply(null, remove);
                    recipients2 = _.without.apply(null, remove2);

                    chat.set({
                        recipients: recipients
                    });
                    chat.attributes.thread_meta.recipients = recipients2;

                    if (data.user_ids.indexOf(App.Auth.get('user_id')) != -1) {
                        App.chats.remove(chat);
                    };
                }
            },
            'add_participants': {
                add: true,
                'action': function (data, chat) {
                    var groups = chat.get('groups'), recipients = chat.get('recipients'), thread_meta = chat.get('thread_meta');
                    groups = _.union(_.union(groups, thread_meta.groups), data.groups);
                    recipients = _.union(_.union(recipients, thread_meta.recipients), data.user_ids);
                    thread_meta.groups = groups;
                    thread_meta.recipients = recipients;

                    data.system = true;
                    data.consumed = true;

                    chat.set({
                        recipients: recipients,
                        groups: groups,
                        thread_meta: thread_meta
                    });
                }
            },
            'revox': {
                add: true,
                'action': function (data, chat) {
                    data.update = false;
                    data.system = false;
                    if (App.Auth.get('rebelvox_user_id') === data.from) {
                        data.consumed = true;
                    } else {
                        data.consumed = data.consumed;
                    }
                    data.active_vox = false;
                    data.audio_duration_ms = data.revox.audio_duration_ms;
                    data.audio_length_bytes = data.revox.audio_length_bytes;
                }
            },
            'banner': {
               action: function (data, chat) {
                    data.update = true;
                    data.system = true;
                    data.consume = true;
                    data.active_vox = false;
                }
            },
            'file_share': {
                add: true,
                'action': function (data, chat) {
                    data.update = false;
                    data.system = false;
                    data.preview_link = data.body.replace('www.dropbox.com', 'dl.dropboxusercontent.com');

                    if (App.Auth.get('rebelvox_user_id') === data.from) {
                        data.consumed = true;
                    } else {
                        data.consumed = data.consumed;
                    }
                    data.active_vox = false;
                }
            },
            'location': {
                add: true,
                action: function (data, chat) {
                    // TODO: handle message specific here

                    data.geo = data.location;
                }
            }
        },
        content_type_handlers = Message.content_type_handlers;

        var add_to_collection = function (chat, data) {
            var chat_messages = chat.get('messages'),
                last_message = chat.get('last_message');

            if (data.from === App.Auth.get('user_id')) {
                data.consumed = true;
            }

            if (data.consumed === undefined) {
                data.consumed = false;
            }

            // system messages should not impact the sorting of the collections and rendering of chatlist
            if (data.system === true) {
                chat_messages.add(data, {silent: true});
                // trigger the render of the message in the chat panel
                chat_messages.trigger("added:message", data.message_id);
            }
            else {
                chat_messages.add(data); // triggers render of messages
                App.messages.add(data, {silent: true});
            }

            // set this silently so as to not re-render the chats list for these kind of messages
            if (data.system !== true) {
                if ((last_message && right_time(last_message) < right_time(data)) || data.deleted) {
                    chat.set({
                        last_message: data
                    }, {silent: false});
                } else if (!last_message || !Object.keys(last_message).length) {
                    chat.set({
                        last_message: data
                    }, {silent: false});
                }

                // update the page title
                if (data.update === true && data.from !== App.MyProfile.get('user_id')) {
                    update_page_title('New vox received!!', {bounce: 5});
                }

                if (!chat.get('live_audio')) {
                    chat.trigger('play:chirp');
                }
            }
        };

        _.each(raw_messages, function (data) {
            var save_time = _.debounce(function (data) {
                var start_time = localStorage['start_time'];
                try {
                    if (start_time && right_time(data) > parseFloat(localStorage['start_time'])) {
                        localStorage.setItem('start_time', right_time(data));
                    } else if (!start_time) {
                        localStorage.setItem('start_time', Date.now() / 1000);
                    }
                } catch (err) {
                    if (Cache.isQuotaExceeded(err)) {
                        localStorage.clear();
                        Cache.set({blocked: true});
                    }
                }
            }, 500);
            save_time(data);

            if (data.revox) {
                data.sub_content_type = 'revox';
            }

            if (data.update === true && data.thread_id === undefined) {
                if (data.content_type === 'timeline_count' || data.content_type === 'account_upgrade') {
                    return;
                }
                if (data.content_type === "public_profiles") {

                    data.profiles.forEach(function(profile) {
                        if (profile.user_id === App.Auth.get('user_id')) {
                            App.MyProfile.set({'image_id': profile.image_id});
                            App.MyProfile.set({avatar_bust: true});
                        } else {
                            try {
                                var _profile = App.profiles.get(profile.user_id);
                                if (_profile) {
                                    _profile.set({
                                        image_id: data.message_id,
                                        last_modified: profile.last_modified_time,
                                        location: profile.location,
                                        status_message: profile.status_message,
                                        public_contacts: profile.public_contacts,
                                        username: profile.username,
                                        first: profile.first,
                                        last: profile.last,
                                        is_contact: true
                                    });
                                } else {
                                    profile.last_modified = profile.last_modified_time;
                                    profile.is_contact = true;
                                    App.profiles.add(profile);
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }
                    });
                    App.vent.trigger({avatar_bust: true});
                    return;
                }
            }
            // find chat
            var chat = App.chats.get(data.thread_id),
                content_type = data.content_type,
                sender = data.from;

            if (data.subcontent_type === undefined && data.revox) {
                data.subcontent_type = 'revox';
            }
            if (App.messages.is_recalled(data.message_id)) {
                data.content_type = 'recall_messages';
            }

            if (content_type === 'recall_messages') {
                var stared = data.thread_id && data.thread_id.indexOf('FC_') !== -1,
                    recalled;

                data.hard_deleted = (typeof data.hard_deleted !== 'undefined') ? data.hard_deleted : true;

                if (data.message_ids && data.message_ids.length) {
                    try {
                        recalled = chat.get('messages').get(data.message_ids[0]);
                        if (recalled) {
                            data.posted_time = recalled.get('posted_time');
                            data.create_time = recalled.get('create_time');
                            data.normalized_create_time = recalled.get('normalized_create_time');

                            data.hard_deleted = false;
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                }

                App.messages.recall_message(data.message_ids, App.chats, stared);
                data.content_type = 'text';
                data.deleted = true;
                content_type = 'text';
                data.body = 'This message was recalled by ' + data.sender_name || data.sender;
            } else {
                data.deleted = false;
            }

            if (!chat && ["text", "audio", "image", "video"].indexOf(content_type) !== -1) {
                // do something about creating this chat
                var created_user = false;
                //check if we know the creator
                if (App.profiles.where({user_id: data.from}).length === 0 && data.sender_name) {
                    created_user = true;
                    var name = data.sender_name.split(" ");
                    if (name && name.length >= 2) {
                        App.profiles.add({user_id: data.from, first: name[0], last: name[1]})
                    } else if (name && name.length){
                        App.profiles.add({user_id: data.from, first: name[0], last: ""})
                    }
                }

                var chat_data = {
                    "thread_id": data.thread_id,
                    "creator": data.from,
                    "create_time": (+new Date() / 1000),
                    "subject": data.subject,
                    "recipients": data.recipients || data.to,
                    "groups": data.groups || [],
                    "unread_count": 0,
                    "last_message": data
                };

                if (created_user) {
                    chat_data.contact = false;
                }

                chat = new Chat.Model(chat_data);
                App.chats.add(chat);

                // Messages from new hotlines come without thread meta
                // so let's set the title as users name to avoid an thread_details API call
                if (!chat_data["subject"] && chat.is_hotline()) {
                    delete chat_data["subject"];
                    chat.set({subject: data.sender_name});
                }

                for (var key in chat_data) {
                    // In case we don't have all details for the chat,
                    // we have to fetch the tread details, and update the caht on response
                    if (typeof chat_data[key] === "undefined") {
                        App.API.thread_details(chat_data.thread_id, {
                            home_router: App.Settings.get('home_router')
                        },
                        function(e) {
                            console.error(e);
                        }, function(details) {
                            App.chats.get(details.thread_id).set(details);
                        });

                        break;
                    }
                }
            }

            if (live) {
                data.append = true;
            }

            if (content_type === 'text' && data.sub_content_type && (data.sub_content_type !== 'revox' && data.sub_content_type !== 'file_share')) {
                if (subcontent_type_actions[data.sub_content_type] && subcontent_type_actions[data.sub_content_type].action) {
                    subcontent_type_actions[data.sub_content_type].action(data, chat);
                    if (subcontent_type_actions[data.sub_content_type].add) {
                        // crappy fix for server suckyness
                        if ((['add_participants', 'remove_participant'].indexOf(data.sub_content_type) !== -1) && data.text && data.text !== '') {
                            data.body = data.text;
                        }
                        // we don't want to display a message, or update the chat list
                        // when notification type is set to silent
                        if (data.notification_type !== 'silent') {
                            add_to_collection(chat, data);
                        }
                    }
                } else {
                    // console.log("TODO -----> HANDLING text FOR " + data.sub_content_type, data);
                }
            } else if (content_type === 'transcription_update') {
                // make sure user_id is in the recipients list
                if (data.to && data.to.indexOf(App.Auth.get('user_id')) === -1) {
                    return;  // ensure the transcription is for current user
                }
                if (data.sub_content_type === 'transcription_data' && data.body) {
                    // handle message with transcribed text returned from the server
                    var audio_message = chat.get('messages').find(function (m) { return m.get('message_id') === data.refers_to[0]; });

                    if (audio_message) {
                        data.message_id = data.refers_to;

                        // update model
                        audio_message.set({
                            body: data.body
                        });

                        chat.set({
                            'last_message': audio_message.attributes
                        });
                        chat.trigger('change:last_message');
                    }
                } else if (data.sub_content_type === 'transcription_initiated') {
                    // handle acknowledgment of transcription request
                    var audio_message = chat.get('messages').find(function (m) { return m.get('message_id') === data.refers_to[0]; });

                    if (audio_message) {
                        var message = 'The message is being transcribed. This may take a few minutes. If the transcription is still not available, please try again.';
                        // update model
                        audio_message.set({
                            body: message
                        });
                    }
                } else if (data.sub_content_type === 'transcription_failed') {
                    // assuming failed flag is sent by the server -> haven't seen that yet
                    // handle error of transcription request
                    var audio_message = chat.get('messages').find(function (m) { return m.get('message_id') === data.refers_to[0]; });

                    if (audio_message) {
                        var message = 'Sorry, we could not transcribe this message. There may have been excessive background noise.';
                        // update model
                        audio_message.set({
                            body: message
                        });
                    }
                } else {
                    // console.log("TODO -----> HANDLING transcription_update FOR " + data.sub_content_type, data);
                }
            } else {
                if (content_type === "audio") {
                    //handle subcontent for future text/audio/image that arnt system
                    if (subcontent_type_actions[data.sub_content_type] && subcontent_type_actions[data.sub_content_type].action) {
                        subcontent_type_actions[data.sub_content_type].action(data, chat);
                    }

                    if (data.live_audio === true) {
                        chat.set({'receiving': true});
                        add_to_collection(chat, data);
                    } else if (data.body) {
                        var audio_message = chat.get('messages').find(function (m) {
                            return m.get('message_id') === data.message_id
                        })
                        if (audio_message) {
                            audio_message.set({body: data.body});
                        } else {
                            add_to_collection(chat, data);
                        }
                    } else {
                        add_to_collection(chat, data);
                    }
                } else if (["text", "image", "video"].indexOf(content_type) !== -1) {
                    //handle subcontent for future text/audio/image that arnt system
                    if (subcontent_type_actions[data.sub_content_type] && subcontent_type_actions[data.sub_content_type].action) {
                        subcontent_type_actions[data.sub_content_type].action(data, chat);
                    }

                    if (data.live_audio === true) {
                        chat.set({'receiving': true});
                    }

                    if (content_type === 'image' && live) {
                        data.live_image = true;
                    }

                    if (data.notification_type !== 'silent') {
                        add_to_collection(chat, data);
                    }
                } else if (["audio_update"].indexOf(content_type) !== -1) {
                    var audio_message = chat.get('messages').find(function (m) { return m.get('message_id') === data.refers_to[0]; });

                    if (audio_message) {
                        chat.unset('receiving');
                        data.message_id = data.refers_to;
                        audio_message.attributes.normalized_create_time = audio_message.attributes.normalized_create_time && audio_message.attributes.normalized_create_time !== 0 ? audio_message.attributes.normalized_create_time : audio_message.attributes.create_time;

                        // update model
                        audio_message.set({
                            audio_duration_ms: data.audio_duration_ms,
                            audio_length_bytes: data.audio_length_bytes,
                            live_audio: false
                        });

                        chat.set({
                            'last_message': audio_message.attributes
                        });
                        chat.trigger('change:last_message');
                    } else {
                        chat.unset('receiving');
                        if (!App.messages.is_recalled(data.refers_to[0])) {
                            data.content_type = 'audio';
                            data.live_audio = false;
                            data.message_id = data.refers_to;
                            add_to_collection(chat, data);
                        }
                    }
                } else {
                    if (content_type_handlers[content_type]) {
                        // trickery to make the chat module available if this is a new chat
                        if (!chat) {
                            chat = Chat;
                        }
                        content_type_handlers[content_type](data, chat);
                    } else {
                        // handle unknown content types
                        console.log("TODO -----> HANDLING CONTENT TYPE FOR " + data.content_type, data);
                    }
                }
            }
        });
    };

    Message.Views.Share = Backbone.Marionette.ItemView.extend({
        template: "chat/panel/share",
        tagName: 'div',
        initialize: function (options) {
            this.model = options.model;
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();
        },
        className: 'modal share-modal',
        events: {
            'click .close': 'handle_close_modal',
            'click .modal-body ul li': 'handle_share_message'
        },
        handle_close_modal: function(e) {
             if (e) {
                e.preventDefault();
            }
            this.$el.modal('hide');
        },
        handle_share_message: function (e) {
            e.preventDefault();
            var target = $(e.target).attr('data-target')
                        || $(e.target).parent().attr('data-target'),
                that = this;

            that.$el.modal('hide');
            this.model.handle_share_message(target);
        }
    });

    Message.Views.Revox = Backbone.Marionette.ItemView.extend({
        template: "chat/panel/revox",
        tagName: 'div',
        initialize: function (options) {
            this.message = options.model;
        },
        className: 'modal list-modal',
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();

            var input = this.$el.find('.revox-filter');
            input.val(this.model.get('filter_keyword') ? this.model.get('filter_keyword') : "");
            input.focus();

            if (detectBrowser().indexOf('firefox') !== -1) {
                input[0].setSelectionRange(input.val().length, input.val().length);
            }
        },
        onShow: function () {
            if (this.$el.find('.revox-filter').val()) {
                this.model.set({'filter_keyword': false});
                this.render();
            };
        },
        events: {
            'click .close': 'close',
            'hidden.bs.modal': 'close',
            'click .modal-footer a.cancel': 'close',
            'click .submit': 'revox',
            'keyup .revox-filter': 'handle_chats_filter',
            'click li a': 'handle_set_checkbox'
        },
        attributes: {
            "data-backdrop": "static",
            "data-keyboard": "false"
        },
        serializeData: function () {
            var data = { filter_keyword: "" },
                tmp = [],
                chats = _.without(App.chats.models, App.chats.get("PC_" + App.Auth.get('user_id') + "_" + App.Auth.get('user_id'))),
                filter_keyword = this.model.get('filter_keyword');
            for (var x = 0; x < chats.length; x++) {
                var chat = {};
                var title = (chats[x].get('subject') !== 'New Chat') ?
                            chats[x].get('subject') : chats[x].get('title');


                if (chats[x].is_hotline()) {
                    profile = App.profiles.get(_.without(chats[x].get('recipients'),
                                                         App.Auth.get('rebelvox_user_id'))[0]);
                    avatar_src = profile ? profile.avatar_src(null, true) : "/assets/img/person.png";
                } else if (chats[x].is_favorited()) {
                    avatar_src = '/assets/img/chatlist-favorites-circle.png';
                } else if (!chats[x].get('groups').length &&
                           chats[x].get('recipients').length === 1 &&
                           chats[x].get('recipients')[0] === App.Auth.get('rebelvox_user_id')) {
                    // Note to self
                    avatar_src = App.MyProfile.avatar_src(null, true);
                } else {
                    var stored_recipients = chats[x].get('stored_recipients') || [];
                    avatar_src = [];

                    if (stored_recipients.length < 3) {
                        stored_recipients = stored_recipients.concat(chats[x].get('recipients').slice(0, 3));
                    }

                    stored_recipients.forEach(function(id) {
                        var profile = App.profiles.get(id),
                            avatar = (profile) ? App.profiles.get(id).avatar_src(null, true) : "/assets/img/person.png";
                        avatar_src.push(avatar);
                    });
                };

                chat.img = avatar_src;
                chat.id = chats[x].id;
                chat.name = Lib.truncate_string(escapeHTML(title), 45);
                tmp.push(chat);
            }

            if (filter_keyword) {
                tmp = _.filter(tmp, function(chat) {
                    return chat.name.toLocaleLowerCase()
                               .indexOf(filter_keyword.toLocaleLowerCase()) !== -1;
                });
            }

            data.chats = tmp;

            return data;
        },
        close: function (e) {
            if (e) {
                e.preventDefault();
            }
            $('.modal-backdrop').remove();
            Backbone.Marionette.ItemView.prototype.destroy.apply(this, arguments);
        },
        handle_set_checkbox: function (e) {
            var el = $(e.currentTarget),
                cb = el.find('input');

            if (e.target.tagName.toLowerCase() !== 'input') {
                if (cb.is(':checked')) {
                    cb.prop('checked', false);
                    this._checkbox(cb);
                }
                else {
                    cb.prop('checked', true);
                    this._checkbox(cb);
                }
            }
            else {
                this._checkbox(cb);
            }
        },
        handle_chats_filter: function (e) {
            this.model.set({'filter_keyword': $(e.target).val()});
            this.render();
        },
        _checkbox: function (cb) {
            if (cb.is(':checked')) {
                cb.closest('li').addClass('selected');
            }
            else {
                cb.closest('li').removeClass('selected');
            }
            if (this.$('input:checked').length > 0) {
                this.$('.modal-footer button').prop('disabled', false);
            }
            else {
                this.$('.modal-footer button').prop('disabled', true);
            }
        },
        revox: function () {
            var that = this, cb;

            if (this.$(':checkbox:checked').length === 0) {
                return this.destroy();
            }

            this.$(':checkbox:checked').each(function (index, value) {
                var thread = $(value).closest('li').data('value');
                cb = $(value);
                that.message.revox_message(thread, function (data) {
                    // mark the chat the message should have been revoxed to as unsuccessful
                    cb.closest('li').addClass('error');
                }, function (data) {
                    App.vent.trigger('metrics:track', '/revox', {
                        media_type: that.message.content_type
                    });
                    that.close();
                });
            });
        }
    });

    // a single message in a chat
    Message.Views.Message = M.LayoutView.extend({
        template: "chat/panel/message_item",
        regions: {
            message_body: '.message-body',
            recipients_list: '.recipients-list'
        },
        tagName: 'li',
        id: function () {
            return this.model ? Lib.sanitize_message_id(this.model.get('message_id')) : 'zid-' + new Date().getTime();
        },
        className: function () {
            var classnames = ['message-item'],
                chat = this.options.chat || App.chats.get(this.model.get('thread_id')),
                that = this;

            classnames.push(Lib.sanitize_message_id(this.model.get('message_id')));

            if (this.model.get('live_audio')) {
                classnames.push('receiving-vox');
                that.record_start = this.model.get('started_live_at') || (right_time(this.model.attributes) * 1000) || Date.now();
                this.interval = setInterval(function () {
                    that.update_duration(that);
                }, 100);
            } else if (this.model.get('active_vox')) {
                classnames.push('active-vox');
            }

            if (this.model.get('subcontent_type') === 'revox') {
                classnames.push('revox');
            }
            if (this.model.get('sub_content_type') === 'location') {
                classnames.push('location');
            }
            if (chat && chat.is_hotline()) {
                classnames.push('hotline');
            }
            return classnames.join(' ');
        },
        events: {
            "click .popover-link": "handle_share_popover",
            "click .share-vox  .share-message": "handle_show_share",
            "click .share-vox  .share-on-profile": "handle_share_on_profile",
            "click .favorite-chat": "handle_favorite",
            "click .flag": "handle_show_flag",
            "click .location-pin": "handle_show_location",
            "click .map-close": "handle_show_location",
            "click .contact": "handle_show_contact",
            "click .image-container img": "handle_image",
            "click .message-footer .timestamp": "handle_click",
            "click .new-chat": "handle_revox",
            "click .save-dropbox": "handle_dropbox_save",
            "click .recall-message": "handle_recall",
            "click .existing-chat": "handle_revox",
            "click .reply": "handle_reply_message",
            "mouseenter .message-header": "handle_mouseover",
            "mouseleave .message-header": "handle_mouseover",
            "mouseenter ":"handle_show_footer",
            "mouseleave ":"handle_hide_footer",
            "click .likes": "handle_like_message",
            "click .transcribe": "handle_transcribe_message",
            "mouseleave .popover":  'handle_hide_popover'
        },
        modelEvents: {
            "change:consumed": "handle_consume",
            "change:active_vox": "handle_vox",
            "change:message_id": "render",
            "change:audio_duration_ms": "handle_audio_update_finished",
            "change:favorited": "render"
        },
        handle_hide_popover: function(){
           this.$el.find(".popover").remove();
           this.$el.find("[data-toggle=popover]").popover('hide');
        },
        handle_share_popover: function(e){
            console.log('click popover')
            this.$el.find("[data-toggle=popover]").popover('show');
        },
        handle_share_on_profile: function (e) {
            var that = this;
            this.model.revox_message(
                "PC_" + App.Auth.get('user_id') + "_" + App.Auth.get('user_id'), function (err) {
                    console.error(err);
                    that.handle_hide_popover();
                    App.vent.trigger("alert:error",
                        "Sharring the message to your profile has failed.");
                }, function () {
                    that.handle_hide_popover();
                    App.vent.trigger("alert:success",
                        "The message has been shared to your profile.");
                });
        },
        handle_like_message:function(e){
           this.$el.find('.likes-wrapper .icon-favorite').click();
        },
        initialize: function () {
            var onAvatarBoost = _.debounce(function () {
                if (App.profiles) {
                    var from = this.model.get('revox') ?
                        this.model.get('revox').from : this.model.get('from');
                    profile = App.profiles.get(from);

                    if (profile) {
                        this.$el.find('img.avatar.img-circle')
                            .attr('src', profile.avatar_src());
                    }
                }
            }.bind(this), 250);
            App.vent.on('avatar_bust', onAvatarBoost, this);
        },
        handle_show_footer: function (e){
            $('.message-footer [data-toggle="tooltip"]').tooltip({'delay': { show: 1000, hide: 150 } });
            this.$el.find('.message-footer div')
                .removeClass("hide");

            if (this.$el.hasClass('merge_details')) {
                this.$el.find('.receipients-wrapper').show();
                this.$el.parent()
                    .find('#' + this.model.id + ' ~ li.message-item:not(.merge_details):first')
                    .find('.receipients-wrapper').hide();
            }
        },
        handle_hide_footer: function (e){
            this.$el.find('.message-footer .col-xs-2:not(.favorited)').addClass("hide");
            this.handle_hide_popover();

            if (this.$el.hasClass('merge_details')) {
                this.$el.find('.receipients-wrapper').hide();
                this.$el.parent()
                    .find('#' + this.model.id + ' ~ li.message-item:not(.merge_details):first')
                    .find('.receipients-wrapper').show();
            }
        },
        handle_favorite: function () {
            var thread_id = "FC_" + App.Auth.get('user_id') + "_" + App.Auth.get('user_id'),
                self = this;
            if (this.model.get('favorited')) {
                var _message,
                    model = this.model,
                    that = this,
                    starred_chat = App.chats.findWhere({thread_id: thread_id}),
                    starredMessages,
                    currentId = model.get('message_id').split('.rvx.')[0];

                if (starred_chat) {
                    starredMessages = starred_chat.get('messages');
                } else {
                    this.model.set({'favorited': false});
                    return;
                }

                starredMessages.some(function (message) {
                    if (message.get('message_id').indexOf(currentId) !== -1) {
                        _message = message;
                        return;
                    }
                });

                if (model.get('thread_id') === thread_id) {
                    model = App.messages.findWhere({message_id: currentId})
                }

                // This is a hook for cached messages
                if (!_message) {
                    this.model.set({'favorited': false});

                    return;
                }

                _message.recall_message(function () {
                    console.warn("Failed to recall message: " + _message.get('message_id'));
                }, function() {
                    starredMessages.remove(_message);
                    App.vent.trigger('metrics:track', '/unfavorite', {
                        media_type: _message.get('content_type')
                    });
                    self.model.set({'favorited': false});
                    App.vent.trigger("unstared", model.get('message_id'));
                });
            } else {
                var that = this;
                if (App.chats.findWhere({thread_id: thread_id}) === undefined) {
                    var that = this,
                        me = App.Auth.get('rebelvox_user_id'),
                        new_chat_model = new App.chat.Model();

                    // start thread
                    new_chat_model.set({
                        title: "Favorites",
                        recipients: [me],
                        thread_id: thread_id
                    });

                    // persist the group chat
                    new_chat_model.save().done(function () {
                        App.chats.add(new_chat_model);
                        that.model.revox_message(thread_id, function () {}, function () {
                            that.model.set({'favorited': true});
                        });
                    }).fail(function () {
                        console.log('FAIL to start thread');
                        return;
                    });
                } else {
                    that.model.revox_message(thread_id, function () {}, function () {
                        that.model.set({'favorited': true});
                    });
                }
            }
        },
        handle_dropbox_save: function (e) {
            e.preventDefault();

            var target = $(e.target).attr('data-target')
                        || $(e.target).parent().attr('data-target');

            // Messages saved to dropbox have to be public,
            // so let's call the sahre api, then construct an public url
            this.model.handle_share_message(target).then(function() {
                var web_id = this.model.get('web_id'),
                    url,
                    filename;

                if (this.model.get('content_type') === 'audio') {
                    filename = this.model.get('message_id') + '.mp3';
                    url = App.Config.get('public_www') + '/media/' + web_id + '.mp3';
                } else if (this.model.get('content_type') === 'image') {
                    filename = this.model.get('message_id') + '.jpg';
                    url = App.Config.get('public_www') + '/media/large/' + web_id + '.jpg';
                }
                Dropbox.save({
                    files: [{
                        'url': url,
                        'filename': filename
                    }],
                    success: function () {
                        App.vent.trigger('alert:success',
                                         'Your file has been saved!');
                    },
                    error: function (error) {
                        console.error(error);
                        App.vent.trigger('alert:error', error);
                    }
                });
            }.bind(this));

            App.vent.trigger('metrics:track', 'v4w/message_save', {
                media_type: 'file',
                file_type: this.model.get('content_type')
            });

        },
        handle_change_status: function () {
            if (this.model.get('status') === 'pending') {
                //this.$el.addClass('pending');
            }
            if (this.model.get('status') === 'sent') {
                this.$el.removeClass('pending');
            }
            if (this.model.get('status') === 'failed') {
                //this.$el.removeClass('pending').addClass('failed');
            }
        },
        handle_mouseover: function (e) {
            var message = "", element = this.$('.message-header .icon-revox'),
                tt = $(e.currentTarget).find('[rel="tooltip"]');

            if (e.type === "mouseenter") {
                tt.tooltip('show');
            } else {
                tt.tooltip('hide');
            }

        },
        handle_recall: function (e, has_retrayed) {
            e.preventDefault();
            var that = this;

            // If message is sent while is still pending so it hasn't an final ID, let's retray in 1s
            if (this.model.get('status') ===  'pending' && !has_retrayed) {
                return setTimeout(this.handle_recall.bind(this, e, true), 1000);
            }

            if ((App.Auth.get('capabilities') && App.Auth.get('capabilities').capability_keys.recall_message)
                || this.model.get('thread_id') ==  'PC_'+  App.Auth.get('user_id') + '_' + App.Auth.get('user_id')) {
                this.model.recall_message(function (err) {
                    console.log("Error recalling message: " + err);
                }, function (response) {
                    var message = _.extend({}, that.model.attributes);
                    message.content_type = 'recall_messages';
                    message.message_id = generate_message_id();

                    delete message.sub_content_type;
                    delete message.file_extension;
                    delete message.file_size;
                    delete message.file_name;
                    delete message.file_link_uri;
                    delete message.mentions;

                    message.sender_name = App.MyProfile.get('display_name');
                    message.deleted = true;
                    message.hard_deleted = false;
                    message.message_ids = [that.model.message_id];

                    if (App.chat.Model.prototype.is_profile.call(that.model)) {
                        if (that.model.collection) {
                            that.model.collection.remove(that.model);
                        }

                        message.content_type = 'text';
                        message.body = 'This message was recalled by ' + App.MyProfile.get('display_name');
                        that.model.set(message);
                    } else {
                        App.messages.recall_message([that.model.get('message_id')], App.chats);
                        App.Message.route([message], {live: true});

                        App.vent.trigger('metrics:track', '/message_recall_tap', {
                            media_type: message.content_type,
                            chat_size: (message.to) ? message.to.length : 1,
                            forwarded: (message.revoxed_by) ? true : false,
                            message_originator: (message.revoxed_by) ? message.revoxed_by : 'self'
                        });
                    }

                    App.vent.trigger('metrics:register', {
                        hasRecalled: true
                    });
                });
            } else {
                App.vent.trigger('metrics:track', '/upgrade', {
                                    from: 'chat',
                                    feature: 'recall'
                                 });
                App.Router.navigate('upgrade', {trigger: true});
            }
        },
        handle_revox: function (e) {
            e.preventDefault();
            if (e.target.className === 'new-chat') {
                console.log('new chat');
            } else {
                var revox_view = new Message.Views.Revox({model: this.model});

                App.layout.regionIntro.show(revox_view);
                revox_view.$('.modal-content').css({
                    'max-height': $(window).height() - 100
                });
                revox_view.$('img.avatar').on('error', function () {
                    if ($(this).attr('src') !== '/assets/img/person.png') {
                        $(this).attr('src', '/assets/img/person.png');
                    }
                });
                setTimeout(function () {
                    revox_view.$('.modal-body').css({
                        'max-height': (revox_view.$('.modal-dialog').height() - 56 - 74)
                    });
                    // make sure avatars render unbroken if no profile image exists on the backend
                }, 300);
            }
        },
        // interval callback to update vo time
        update_duration: function (context) {
            var duration_ms = (new Date().getTime() - context.record_start),
                seconds = duration_ms / 1000,
                minutes = Math.floor(seconds / 60);

            seconds = Math.floor(seconds % 60);

            if (seconds.toString().length == 1)
                seconds = "0" + seconds;

            if( minutes < 00 && seconds < 00){
                minutes = "0";
                seconds = "00";
            }

            duration = minutes + ":" + seconds;

            context.$('.vox-duration').show().html(duration);

        },
        handle_click: function (e) {
            console.log(this.model);
        },
        handle_audio_update_finished: function () {
            var seconds = this.model.get('audio_duration_ms') / 1000,
                minutes = Math.floor(seconds / 60);

            seconds = Math.floor(seconds % 60);

            if (seconds.toString().length == 1)
                seconds = "0" + seconds;

            if( minutes < 00 && seconds < 00 ){
                minutes = "0";
                seconds = "00";
            }

            duration = minutes + ":" + seconds;

            this.$el.removeClass('receiving-vox');

            // stop the counter
            clearInterval(this.interval);
            this.$('.vox-duration').html(duration);
        },
        handle_consume: function (e) {
            this.$el.find('.consumed-indicator').addClass('consumed').removeClass('not-consumed');
            this.$el.find('.likes-holder ').addClass('consumed').removeClass('not-consumed');
            this.$el.find('.message-body-holder').addClass('consumed').removeClass('not-consumed');
            this.$el.find('.progress').addClass('consumed');
        },
        handle_vox: function (e) {
            if (!this.model.get('active_vox')) {
                this.$el.find('.voxing').removeClass('voxing');
                this.$el.removeClass('active-vox');
            }
        },
        handle_image: function (e) {
            // check if we have the right session key in the model's image_urls
            var that = this,
                current_key = App.Auth.get('Rv_session_key');

            if (this.model.get('image_urls')) {
                image_urls = this.model.get('image_urls');
            } else {
                image_urls = this.model.get('preview_link');
            }

            image_urls = (typeof image_urls === 'string') ? [image_urls] : image_urls;

            Object.keys(image_urls).forEach(function (key, idx, array) {
                var existing_sid = getURLParameter('Rv_session_key', image_urls[key]);
                if (existing_sid !== current_key) {
                    image_urls[key] = image_urls[key].replace(existing_sid, current_key);
                }
            });
            this.model.set({'image_urls': image_urls}, {'silent': true});

            var imagepanel = new Message.Views.ImagePanel({
                model: this.model,
                parent: this
            });
            App.layout.regionIntro.show(imagepanel);
        },
        onBeforeRender: function () {
            var prepend_text,
                use_timestamp = this.model.get('normalized_create_time') ? this.model.get('normalized_create_time') : this.model.get('create_time'),
                hours_since_timestamp = moment().diff(use_timestamp * 1000, 'hours'),
                index = this.model.collection ? this.model.collection.indexOf(this.model) : undefined,
                prev_model = index !== undefined ? this.model.collection.at(index - 1) : undefined,
                next_model = index !== undefined ? this.model.collection.at(index + 1) : undefined,
                prev_timestamp;

            if (prev_model) {
                prev_timestamp = prev_model.get('normalized_create_time') || prev_model.get('create_time');
            }

            prepend_text = moment(use_timestamp * 1000).format('dddd MM/DD/YY');
            if (!prev_model || (prev_model && prepend_text !== moment(prev_timestamp * 1000).format('dddd MM/DD/YY'))) {
                this.model.set({
                    prepend:  prepend_text
                }, {silent: true});

                // If we already added this text we have to remove it
                if (next_model && prepend_text === moment((next_model.get('normalized_create_time') || next_model.get('create_time')) * 1000).format('dddd MM/DD/YY')) {
                    $('#' + Lib.sanitize_message_id(next_model.get('message_id')) + ' .day-time-stamp').remove();
                }
            }
        },
        onRender: function () {
            var that = this,
                chat = this.options.chat || App.chats.findWhere({thread_id: this.model.get('thread_id')}),
                body = new Message.Views.MessageBody({model: this.model, chat: chat}),
                recipients_view = new Message.Views.RecipientsList({
                    model: this.model,
                    chat: that.options.chat
                });

            this.message_body.show(body);
            this.recipients_list.show(recipients_view);

            this.$('img').error(function () {
                if ($(this).attr('src') !== '/assets/img/person.png') {
                    $(this).attr('src', '/assets/img/person.png');
                }
            }).load(function () {
                var self = $(this);
                // adjust size
                if (self.hasClass('voxer-image')) {
                    if (this.naturalHeight > this.naturalWidth) {
                        var max = App.panel_height || (self.closest('.panel-body').height() - 100);
                        self.css({
                            'width': '100%',
                            'height': '100%',
                            'max-height': max,
                            'max-width': this.naturalWidth / (this.naturalHeight / max)
                        });
                    } else {
                        self.css({
                            'width': '100%',
                            'height': '100%',
                            'max-width': this.naturalWidth
                        });
                    }

                    try {
                        var orientation = that.model.get('content_json') ?
                            that.model.get('content_json').o : false;
                        if (orientation) {
                            var margin = (this.naturalWidth > this.naturalHeight ? (1 - this.naturalHeight / this. naturalWidth) * 100 : 0),
                                width = 100 + margin;
                                if(width > 100){
                                    width = 100;
                                    margin = 0;
                                }
                            self.css({
                                'width': width + '%',
                                'margin-top': margin * width / 100 / 2+ '%',
                                'margin-bottom': margin * width / 100 / 2 + '%',
                                'margin-left': '-' + margin / 2 + '%'
                            });
                            self.removeClass().addClass('rotate' + orientation2Degrees(orientation));
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }

                if (self.hasClass('lazy')) {
                    self.removeClass('lazy');
                    self.attr('src', self.data('original'));
                }
            });
            this.$([rel="tooltip"]).tooltip({
                delay: 400
            });

            // We have to notify the last sibling to hide his read recipts
            if (this.render_details === false) {
                waitFor(function () {
                    if (that.$el.siblings().length) {
                        that.$el.prev().addClass('merge_details');
                        return true;
                    } else {
                        return false;
                    }
                }, true, 1000, 5000);
            }
            this.$el.find("[data-toggle=popover]").popover({
                trigger: 'manual'
            });

            if (this.model.get('body')) {
                var transcribeButton = $(".transcribe-message");
            
                transcribeButton.addClass('greyed-out')
                                .removeAttr('data-toggle')
                                .removeAttr('data-placement');
                if (transcribeButton[0]) {
                    void transcribeButton[0].offsetWidth;
                }

            } else {
                var transcribeButton = $(".transcribe-message");

                transcribeButton.removeClass('greyed-out')
                                .attr('data-toggle', 'tooltip')
                                .attr('data-placement', 'top');
                if (transcribeButton[0]) {
                    void transcribeButton[0].offsetWidth;
                }
            }
        },
        serializeData: function () {
            var data = this.model.attributes,
                profile,
                system_message_subcontent_types = ["remove_participant", "add_participants"],
                timestamp,
                sender = 'Unknown',
                model_index = (this.model.collection && this.model.collection.models) ? this.model.collection.models.indexOf(this.model) : 0,
                previous_message,
                from_me = data.from === (App.Auth ? App.Auth.get('rebelvox_user_id') : undefined),
                can_like = !from_me,
                revoxed_by, revox_detail = '',
                audio_message = this.model.get('content_type') === 'audio',
                reply_preview_url = '';

            const permitted_list = ['ruby.kong.voxer.1725329294135.52429415', 
                'patri.kokou.voxer.1620688308530.31031858',
                'sanja.gorur.voxer.1716402600145.34219095', 
                'xavie.bonal.voxer.1606967331884.87906997', 
                'user.irv.r.1323290336341_63195932',   
                'prate.sachd.fb-10226252940444798.1729780889631.93886737',
                'hanna.song.voxer.1715001117677.10395846',
                'sherr.lev.voxer.1735937987153.53717502',
                'mary.nelso.fb-878319319181.1468338691795.39204017',
                'tom.katis@voxer.com',
            ]; // control access to reply to feature
            data.permitted_list = permitted_list;
            data.permit_everyone = false;
            if (window.location.hostname.indexOf('web.voxer.com') === -1) {
                data.permit_everyone = true;
            }

            this.render_details = true;

            if (data.revox && data.favorited)
                from_me = false;

            if (App.profiles) {
                var from = data.revox ? data.revox.from : data.from;
                profile = App.profiles.get(from);
            }
            else {
                profile = undefined;
            }

            // sender name
            if (data.revox) {
                sender = from_me ? "You" : data.revox.sender_name;
                revox_detail = moment(right_time(data.revox) * 1000).format('ddd MM/DD/YY, h:mm a');

                // If revoxed message is from me I should treat is as mine(can't like)
                can_like = data.revox.from !== (App.Auth ? App.Auth.get('rebelvox_user_id') : undefined);
            }
            else if (profile) {
                sender = from_me ? "You" : profile.name();
            } else {
                sender = data.sender_name || "Walkie";
            }

            if (data['update'] === undefined) {
                data['update'] = false;
            }

            timestamp = format_message_timestamp(data.posted_time || data.normalized_create_time || data.create_time || (new Date()).getTime() / 1000);

            if (model_index) {
                previous_message = this.model.collection.models[model_index - 1];
                if (from === previous_message.attributes.from &&
                    timestamp == format_message_timestamp(right_time(previous_message.attributes))) {
                    this.render_details = false
                }
            }

            if (data.revox && data.favorited === undefined) {
                if (data.from !== App.Auth.get('user_id')) {
                    revoxed_by = data.sender_name;
                }
                else {
                    revoxed_by = 'You';
                }
            }
            else {
                revox = false;
            }

            if (data.content_type === 'audio') {
                data.audio_url = App.create_url('get_body', {
                    'message_id': data.message_id,
                    'format': 'mp3',
                    'download': data.message_id + '.mp3'
                });
            } else if (data.content_type === 'image') {
                var format = 'jpg';
                if (data.content_json && data.content_json.fmt) {
                    format = data.content_json.fmt;
                }
                data.image_url = App.create_url('get_body', {
                    'message_id': 'lrg_' + data.message_id,
                    'download': data.message_id + '.' + format
                });
            }

            if (App.chat.Model.prototype.is_profile.call(this.model) && data.deleted === true) {
                data.hard_deleted = true;
            }

            // check if replying to a previous message
            if (data.replies_to && data.replies_to.message_id) {                
                if (data.replies_to.content_type === 'image') { // create image url for preview
                    reply_preview_url = App.create_url('get_body', {'message_id': data.replies_to.message_id});
                }
            }
        

            _.extend(data, {
                'timestamp':    timestamp,
                'avatar_src':   profile ? profile.avatar_src() : "/assets/img/person.png",
                'is_pro':       profile && profile.get("vpro_account_badge"),
                'favorited':    data.favorited,
                'sender':       escapeHTML(sender),
                'talk_mode':    Settings.fetch('talk-mode') || 'PTT',
                'from_me':      from_me,
                'can_like':     can_like,
                'audio_message': audio_message,
                'system':       data.system || (data.subcontent_type && system_message_subcontent_types.indexOf(data.subcontent_type) !== -1),
                'location':     data.geo && (data.geo.longitude !== undefined) && (data.geo.latitude !== undefined),
                'consumed':     data.consumed,
                'active_vox':   data.active_vox,
                'prepend':      data.prepend,
                'sender_id':    profile ? (profile.get('user_id') || '') : from,
                'render_details': this.render_details,
                'revoxed_by':   revoxed_by ? escapeHTML(revoxed_by) : false,
                'revox_detail':   revox_detail ? escapeHTML(revox_detail) : '',
                'disabled':       false,
                'dropbox_save':   false,
                'replies_to':     data.replies_to,
                'reply_to_me':    data.replies_to && data.replies_to.from === App.Auth.get('user_id'),
                'reply_preview_url': reply_preview_url,
                'deleted': (data.deleted === true) ? true : false,
                'hard_deleted': data.hard_deleted === true ? true : false
            });

            return data;
        },
        /* HANDLERS */
        handle_show_share: function (e) {
            e.preventDefault();
            var share_view = new Message.Views.Share({model: this.model});

            App.layout.regionIntro.show(share_view);
        },
        handle_show_flag: function (e) {
            e.preventDefault();
            console.log('show flagging options');
        },
        handle_show_location: function (e) {
            e.preventDefault();
            var that = this,
                $msgbox = this.$('.message-text-wrapper'),
                $close = this.$('.map-close'),
                coordinates = this.model.get('geo');

            if (!this.model.get('showLoc')) {
                // flag the model
                this.model.set({showLoc: true}, {silent: true});
                // show the map
                $msgbox.find('.message-holder').addClass('full-width');
                $msgbox.find('.last').addClass('full-width' , 1000);
                $msgbox.find('.last div').addClass('full-width', 1000);
                $msgbox.closest('.message-text-wrapper').addClass('full-width');
                this.$('.map-wrapper').addClass('full-width');

                this.$('.map-wrapper').animate({height: '350px'}, 0, "linear", function () {
                    var map = mapbox.map($(e.currentTarget).parent().parent().parent().parent().parent().find('.map-wrapper')[0],
                            null, null, [
                        easey_handlers.DragHandler(),
                        easey_handlers.TouchHandler()
                    ]);
                    var layer = mapbox.layer().url(window.location.protocol + '//' + window.location.host + '/assets/js/tilejson.jsonp');
                    map.addLayer(layer);
                    mapbox.markers.marker_baseurl = "https://dnv9my2eseobd.cloudfront.net/v3/marker/";
                    // Create an empty markers layer
                    var markerLayer = mapbox.markers.layer();

                    // Add interaction to this marker layer. This
                    // binds tooltips to each marker that has title
                    // and description defined.
                    mapbox.markers.interaction(markerLayer);
                    map.addLayer(markerLayer);

                    map.zoom(13).center({
                        "lat": coordinates.latitude,
                        "lon": coordinates.longitude
                    });
                    map.ui.zoomer.add();
                    map.ui.zoombox.add();
                    // Add a single feature to the markers layer.
                    // You can use .features() to add multiple features.
                    markerLayer.add_feature({
                        geometry: {
                            coordinates: [coordinates.longitude, coordinates.latitude]
                        },
                        properties: {
                            'marker-color': '#ff6600',
                            'marker-symbol': 'star-stroked',
                            'title': 'My location'
                        }
                    });
                });
            }
            else {
                // unflag the model
                this.model.unset('showLoc', {silent: true});
                // reset width
                $msgbox.find('.message-holder').removeClass('full-width');
                $msgbox.find('.last').removeClass('full-width' , 1000);
                $msgbox.find('.last p').removeClass('full-width', 1000);
                $msgbox.closest('.message-text-wrapper').removeClass('full-width');
                this.$('.map-wrapper').removeClass('full-width');

                this.$('.map-wrapper').animate({height: '0px'}, function () {
                    $(this).html('');
                });
            }
            // toggle the map close button
            $close.toggleClass('hide');
        },
        handle_show_contact: function (e) {
            if (App.profiles.get(App.Auth.get('user_id')).cid === $(e.currentTarget).data('id')) {
                App.Router.navigate('profile', {trigger: true});
                e.preventDefault();
            }
        },
        handle_reply_message: function (e) {
            e.preventDefault();
            var chat = App.chats.findWhere({thread_id: this.model.get('thread_id')});
            
            if (chat !== undefined) {
                var message_info = {
                    message_id: this.model.get('message_id'),
                    content_type: this.model.get('content_type'),
                    from: this.model.get('from'),
                    sender_name: this.model.get('sender_name'),
                };

                switch (message_info.content_type) {
                    case 'text':
                        var body = this.model.get('body');
                        if (body.length > 100) {
                            message_info.body = body.substring(0, 100) + ' ...';
                        } else {
                            message_info.body = body;
                        }
                        break;
                    case 'audio':
                        var audio_duration = this.model.get('audio_duration_ms'),
                            duration;

                        if (audio_duration === -1) {
                            duration = '';
                        } else {
                            var seconds = (audio_duration / 1000),
                                minutes = Math.floor(seconds / 60);
    
                            if( isNaN(seconds)  && isNaN(minutes) ){
                                seconds = '0';
                                minutes = '0'
                            }
                            seconds = Math.floor(seconds % 60);
                            if (seconds.toString().length == 1)
                                seconds = "0" + seconds;
    
                            duration = minutes + ":" + seconds;
                        }                            
                        message_info.duration = duration;
                        break;
                    case 'image':
                        message_info.body = 'Photo';
                        message_info.content_json = this.model.get('content_json');
                        break;
                    case 'location':
                        message_info.body = '[Location]';
                        break;
                    case 'video':
                        message_info.body = '[Video]';
                        break;
                    default:
                        message_info.body = '[Message]';

                }
                chat.set('replies_to', message_info);
            } else {
                console.log('Missing referring chat!');
            }
        },
        handle_transcribe_message: function (e) {
            e.preventDefault();
            var that = this;

            if (App.Auth.get('capabilities') && App.Auth.get('capabilities').capability_keys.transcription) {
                $(e.currentTarget).find('.icon-transcribe-message').addClass('loading');
                // $(e.currentTarget).find('.icon-transcribe-message').removeClass('loading'); // 10 seconds timeout
                this.$('.transcribe').prop('disabled', true);

                App.API.transcribe_message({
                    message_id: this.model.get('message_id'),
                    user_id: App.Auth.get('user_id')
                }, {
                    router: App.Settings.get('home_router')
                }, function (err) {
                    console.error(err);
                    $(e.currentTarget).find('.icon-transcribe-message').removeClass('loading');
                    this.$('.transcribe').prop('disabled', false);
                }, function (data) {
                    // TODO: only show the transcribe icon if on success
                    // right now it will show even if the transcription fails
                    $(e.currentTarget).find('.icon-transcribe-message').removeClass('loading');
                    this.$('.transcribe').prop('disabled', false);
                }.bind(this));
            } else {
                App.vent.trigger('metrics:track', '/upgrade', {
                                    from: 'chat',
                                    feature: 'transcription'
                                 });
                App.Router.navigate('upgrade', {trigger: true});
            }

        },
        /* HELPERS */

    });

    Message.Views.Like = M.ItemView.extend({
        template: 'message/like',
        events: {
            'click .icon-favorite': 'handle_like',
            'click .count': 'handle_show_more'
        },
        modelEvents: {
            "change:like_times": "render",
        },
        serializeData: function () {
            var like_times = this.model.get('like_times') || {};

            return {
                personal: this.model.get('sender_id') === App.Auth.get('user_id'),
                system: this.model.get('system'),
                count: Object.keys(like_times).length,
                liked_by_me: like_times[App.Auth.get('user_id')] ? true : false
            }
        },
        handle_like: function (e) {
            var like_times = this.model.get('like_times') || {},
                liked_by_me = like_times[App.Auth.get('user_id')] ? true : false;

            // Prevent you from liking your own message unless when forwarded by someone else
            if ((this.model.get('sender_id') === App.Auth.get('user_id')) &&
                (this.model.get('subcontent_type') != 'revox' || this.model.get('revoxed_by') == 'You')  ){
                return;
            }

            App.API.like_messages({
                message_ids: [this.model.get('message_id')],
                thread_id: this.model.get('thread_id'),
                from: App.Auth.get('user_id'),
                like: (liked_by_me) ? false : true
            }, {
                router: Settings.fetch('home_router')
            }, function (err) {
                console.error(err);
            }, function (data) {
                if (liked_by_me) {
                    delete like_times[App.Auth.get('user_id')];
                } else {
                    like_times[App.Auth.get('user_id')] = Date.now() / 1000;
                }
                this.model.set({ like_times: like_times });
                this.model.trigger('change:like_times', 'like_times');

                App.vent.trigger('metrics:track', '/message_liked', {
                    liked: (liked_by_me) ? false : true
                });
            }.bind(this));
        },
        handle_show_more: function (e) {
            e.preventDefault();


            var like_times = this.model.get('like_times') || {},
                profiles = new Backbone.Collection();

            // Prevent empty list
            if (!Object.keys(like_times).length)
                return;

            Object.keys(like_times).forEach(function (id) {
                var profile = App.profiles.get(id).clone();

                if (typeof like_times[id] !== 'undefined' && profile) {
                    profile.set({liked_at: like_times[id]});
                }
                profiles.add(profile);
            });

            var modal = new UI.Views.ListModal({
                callback: function () { },
                headline: 'People who liked this',
                ok_text: 'Ok',
                cancel_text: null
            });

            App.layout.regionIntro.show(modal);
            $('.list-modal').removeClass('logout-modal');
            modal.$('.modal-content').addClass("read-receipt");
            modal.$('.modal-body').addClass('zeshallowlistclass').append('<div class="list-holder"> <ul> </ul></div>');

            var contact_list = new Contact.Views.LikeReciptsList({
                collection: profiles,
                parent: modal.$('.modal-body ul')
            });

            contact_list.render();
        }
    });

    Message.Views.RecipientsList = M.ItemView.extend({
        template: 'message/recipients_list',
        className: ' dropdown dropup',
        modelEvents: {
            "change:read_times": "handle_status_update",
            "change:delivery_times": "handle_status_update",
            "change:status": "handle_status_update"
        },
        events: {
            'click': 'handle_more_item'
        },
        initialize: function() {
            // We have to re-render when we get all the profiles
            var onAvatarBoost = _.debounce(function () {
                if (!this.isDestroyed) {
                    this.render();
                } else {
                    App.vent.off('avatar_bust', onAvatarBoost, this);
                }
            }.bind(this), 250);
            App.vent.on('avatar_bust', onAvatarBoost, this);
        },
        _status_text: function () {
            var message_type = this.model.get('content_type');

        },
        serializeData: function () {
            // people that read the message
            var profiles = new Backbone.Collection(),
                show_profiles = [],
                read_times = Object.keys(this.model.get('read_times') || {}),
                message_status = this.model.get('status'),
                message_type = this.model.get('content_type'),
                status_text = '',
                max_show = 2,
                show_more;

            read_times = _.without(read_times, App.Auth.get('rebelvox_user_id'));
            read_times.forEach(function (id) {
                profiles.add(App.profiles.get(id));
            });

            // only pass the needed number of profiles to display
            show_profiles = profiles.sortBy('last').slice(0, max_show);
            show_profiles = show_profiles.length > 0 ? show_profiles : [];
            show_more = read_times.length - show_profiles.length

            if (profiles.length > 0) {
                if (this.options.chat.is_hotline()) {
                    status_text = 'Read';
                    if (message_type === 'audio') {
                        status_text = 'Heard';
                    }
                    if (message_type === 'image') {
                        status_text = 'Seen';
                    }
                }
                else {
                    status_text = 'Read';
                    if (message_type === 'audio') {
                        status_text = 'Heard';
                    }
                    if (message_type === 'image') {
                        status_text = 'Seen';
                    }
                    status_text += ' by ';
                    _.each(show_profiles, function (profile, index) {
                        status_text += profile.get('first');
                        if (index < (show_profiles.length - 1))
                            status_text += ', ';
                    });

                    if (show_more > 0) {
                        status_text += ' and by ' + show_more + ' more...';
                    } else {
                        status_text = status_text.replace(', ', ' and ');
                    }
                }
            }
            else if (typeof this.model.get('delivery_times') === 'object' && Object.keys(this.model.get('delivery_times')).length > 0) {
                status_text = 'Delivered';
            }
            else if ((!message_status && this.model.get('from') === App.Auth.get('rebelvox_user_id')) || message_status === 'sent') {
                status_text = 'Sent';
            }

            return {
                model: this.model,
                talk_mode: Settings.fetch('talk-mode') || 'PTT',
                is_hotline: this.options.chat && this.options.chat.is_hotline(),
                show_profiles: show_profiles,
                status_text: status_text || ''
            };
        },
        handle_status_update: function (status_type) {
            this.render();
        },
        handle_more_item: function (e) {
            e.preventDefault();


            var read_peers_ids = this.model.get('read_times') || {},
                delivery_peers_ids = this.model.get('delivery_times') || {},
                profiles = new Backbone.Collection(),
                message_type = this.model.get('content_type'),
                status_text = 'read',
                peers_ids;

            if (this.options.chat && this.options.chat.get_recipients().recipients) {
                peers_ids = this.options.chat.get_recipients().recipients;
            } else {
                peers_ids = _.union(_.keys(read_peers_ids), _.keys(delivery_peers_ids));
            }

            if (message_type === 'audio') {
                status_text = 'heard';
            } else if (message_type === 'image' || message_type === 'video') {
                status_text = 'seen';
            }

            peers_ids = _.without(peers_ids, App.Auth.get('rebelvox_user_id'));
            peers_ids.forEach(function (id) {
                var profile = App.profiles.get(id).clone();

                if (typeof read_peers_ids[id] !== 'undefined') {
                    profile.set({read_at: read_peers_ids[id]});
                }

                if (typeof delivery_peers_ids[id] !== 'undefined') {
                    profile.set({delivered_at: delivery_peers_ids[id]});
                }

                profiles.add(profile);
            });
            var modal = new UI.Views.ListModal({
                callback: function () { },
                headline: 'People who ' + status_text + ' this',
                ok_text: 'Ok',
                cancel_text: null
            });
            App.layout.regionIntro.show(modal);
            $('.list-modal').removeClass('logout-modal');
            modal.$('.modal-content').addClass("read-receipt");
            modal.$('.modal-body').addClass('zeshallowlistclass').append('<div class="list-holder"> <ul> </ul></div>');

            profiles.comparator = function (a, b) {
                return (a.get('is_contact') === b.get('is_contact')) ? (a.get('first').toLowerCase() > b.get('first').toLowerCase() ? 1 : -1) : a.get('is_contact') ? -1 : 1;
            }
            profiles.sort();

            var contact_list = new Contact.Views.ReadReciptsList({
                collection: profiles,
                parent: modal.$('.modal-body ul')
            });

            contact_list.render();
        }
    });

    Message.Views.MessageBody = M.LayoutView.extend({
        template: "chat/panel/message_item_body",
        regions: {
            likes: ".likes-wrapper"
        },
        className: function () {
            var classes = 'message-item-body';
            if (this.model.get('status') === 'pending') {
                classes += ' pending';
            }
            if (this.model.get('content_type') === 'audio') {
                classes += ' audio';
            }
             if (this.model.get('content_type') === 'video') {
                classes += ' video';
            }
            if (this.model.get('content_type') === 'image') {
                classes += ' image';
            }
            if (this.model.get('sub_content_type') === 'location') {
                classes += ' location';
            }
            return classes;
        },
        events: {
            "click a.play-vox": "handle_play_vox",
            "click a.speed-vox": "handle_speed_vox",
            "mousedown .progress": "handle_skip",
            "drag .progress": "handle_skip",
            "click .message-issue a": "handle_retry_error",
            "click .transcription": "handle_show_all_transcription"
        },
        modelEvents: {
            "change:live_audio": "handle_change",
            "change:playing": "handle_model_playing",
            "change:status": "handle_change_status",
            "change:has_error": "handle_has_error",
            "scroll": "handle_scroll",
            "stop": "handle_audio_stop",
            "finish": "handle_audio_finish",
            "update": "handle_audio_update",
            "change:body": "handle_change_body"
        },
        onBeforeRender: function () {
            var chat = this.options.chat; //App.chats.get(this.model.get('thread_id'));
            if (!this.model.get('receiving')) {
                this.$el.closest('.message-item').removeClass('receiving-vox');
            }
        },
        onRender: function () {
            var that = this,
                like_times = this.model.get('like_times');

            if(!this.model.get('system') && !this.model.get('deleted')){
                this.likes.show(new Message.Views.Like({model: this.model}));
            }

            if (this.model.get('content_type') === 'video') {
                var ratio = 1,
                    content_json = this.model.get('content_json'),
                    reference = (content_json.width > content_json.height) ? content_json.width : content_json.height;

                if (reference > App.video_width) {
                   ratio = parseFloat((reference / App.video_width).toFixed(2));
                }

                var video = videojs(this.$el.find("#video_" + Lib.sanitize_message_id(this.model.get('message_id')))[0], {
                    controls: true,
                    autoplay: false,
                    preload: "auto",
                    poster: (App.create_url('get_body', {
                        'message_id': that.model.get('message_id')
                    }) + ".thm"),
                    width: parseInt(content_json.width / ratio),
                    height: parseInt(content_json.height / ratio)
                }).ready(function () {
                    this.on("play", function() {
                        if (!that.model.get('consumed')) {
                            var chat = App.chats.get(that.model.get('thread_id'));

                            chat.consume_messages(
                                [that.model.get('message_id')]
                            ).done(function () {
                                that.model.set({consumed: true});
                            });
                        }
                    });
                });

                window.videos.push(video);
            } else if (this.model.get('sub_content_type') === 'location') {
                var layer,
                    markerLayer,
                    coordinates = this.model.get('content_json'),
                    map = mapbox.map(this.$el.find('.map-wrapper')[0], null, null, [
                        easey_handlers.DragHandler(),
                        easey_handlers.TouchHandler()
                    ]);

                layer = mapbox.layer().url(window.location.protocol + '//' + window.location.host + '/assets/js/tilejson.jsonp');
                map.addLayer(layer);

                // Create an empty markers layer
                mapbox.markers.marker_baseurl = "https://dnv9my2eseobd.cloudfront.net/v3/marker/";
                markerLayer = mapbox.markers.layer();
                mapbox.markers.interaction(markerLayer);
                map.addLayer(markerLayer);

                map.zoom(13).center({
                    "lat": coordinates.latitude,
                    "lon": coordinates.longitude
                });
                map.ui.zoomer.add();
                map.ui.zoombox.add();
                // Add a single feature to the markers layer.
                // You can use .features() to add multiple features.
                markerLayer.add_feature({
                    geometry: {
                        coordinates: [coordinates.longitude, coordinates.latitude]
                    },
                    properties: {
                        'marker-color': '#ff6600',
                        'marker-symbol': 'star-stroked'
                    }
                });
            }


            if (this.model.get('has_error')) {
                this.$el.closest('.message').addClass('error');
                this.$el.append('<div class="message-issue"><i class="voxer-exclamation"></i>This message experienced an error <a href="">Try again?</a></div>');
            }

            if (this.model.get('content_type') === 'audio' && this.model.get('body')) {
                var transcription = this.$el.find('.transcription');

                this.$el.find('.audio-holder').addClass('sub');
                transcription.html(this.model.get('body'));
            }
        },
        //handlers
        handle_scroll: function (ev) {
            this.$el.closest('.panel-body').scrollTo(this.$el, 800, {offset: -50});
        },
        handle_change_body: function (model, text) {
            if (model.get('content_type') === 'audio' && model.get('body')) {
                var transcription = this.$el.find('.transcription');

                this.$el.find('.audio-holder').addClass('sub');
                transcription.html(text);
            }
        },
        handle_change_status: function () {
            this.$el.removeClass('pending failed ')
                    .addClass(this.model.get('status'));

            if (this.model.get('status') === 'sent') {
                this.model.set({has_error: false});
            }
            if (this.model.get('status') === 'failed') {
                this.model.set({has_error: 'failed_send'});
            }
        },
        handle_audio_update: function (value) {
            var progress = this.$el.find(".progress");
            var bar = progress.find('.bar');
            bar.css("left", ((value / 100) * (progress.width() - bar.width())) + "px");
            this.$el.find('.message-text-wrapper .progress .consumed-length')
                    .css("width", value + "%");
        },
        handle_audio_stop: function () {
            this.$el.find('.bar').css("width", "0%");
            this.$el.find('.message-text-wrapper .progress .consumed-length')
                .css("width", 0 + "%");
        },
        handle_audio_finish: function () {
            this.$el.find('.progress').addClass('consumed');
            this.$el.find('.bar').css("left", 0 + "%");
            this.$el.find('.message-text-wrapper .progress .consumed-length')
                .css("width", 0 + "%");
        },
        handle_model_playing: function (event, value) {
            var button = this.$el.find('.play-vox i');

            if (value) {
                this.$el.find('.audio-holder').addClass('playing');
                if (button.hasClass('zmdi-play')) {
                    button.toggleClass('zmdi-play').toggleClass('zmdi-pause');
                }
            } else {
                this.$el.find('.audio-holder').removeClass('playing');

                if (button.hasClass('zmdi-pause')) {
                    button.toggleClass('zmdi-play').toggleClass('zmdi-pause');
                }
            }
        },
        handle_change: function (e) {
            this.render();
        },
        handle_retry_error: function (e) {
            e.preventDefault();

            if (this.model.get('has_error') == 'failed_send' &&
                this.model.get('content_type') == 'audio' &&
                this.model.get('blobUrl')) {

                this.model.set({status: 'pending'});
            var that = this,
                params = {
                    session_key: App.Auth.get('Rv_session_key'),
                    host: App.Auth.get('home_router').address,
                    port: App.Auth.get('home_router').port,
                    from: App.Auth.get("user_id"),
                    to: this.model.get("to"),
                    thread_id: this.model.get("thread_id"),
                    message_id: this.model.get("message_id"),
                    active_vox: true,
                    secure_router: {
                        host: App.Auth.get('home_router').address,
                        port: App.Auth.get('home_router').port
                    },
                    content_type: 'audio',
                    encoded: true,
                    offset: 0
                },
                offsetsDeferred = $.Deferred(),
                bufferDeferred = $.Deferred(),
                xhr = new XMLHttpRequest();

                if (this.model.get("replies_to") && this.model.get("replies_to").message_id) {
                    params["replies_to"] = this.model.get("replies_to");
                }

                xhr.open('GET', this.model.get('blobUrl'), true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function() {
                    if (this.status == 200) {
                        bufferDeferred.resolve(this.response);
                    }
                };
                xhr.send();

                App.API.get_offsets(this.model.get("message_id"), {
                    home_router: App.Settings.get("home_router")
                }, function () {
                    offsetsDeferred.reject();
                }, function (data) {
                    offsetsDeferred.resolve(data[that.model.get("message_id")] || false);
                });

                $.when.apply($, [offsetsDeferred, bufferDeferred]).then(function (offset, buffer) {
                    if (offset) {
                        params["offset"] = offset.length;
                    }

                    var stream = App.VoxerAudio.client.createStream(params);
                    stream.on('data', function (data) {
                        var data = (typeof data == 'string') ? JSON.parse(data) : data;
                        if (data && data.header_store_status === 200) {
                            that.model.set({status: 'sent'});
                        } else {
                            that.model.set({status: 'failed'});
                        }
                    });
                    stream.on('close', function () {
                        that.model.set({status: 'failed'});
                    });
                    if (offset) {
                        stream.write(new Uint8Array(buffer).slice(offset.length).buffer);

                        if (stream.writable) {
                            stream.pause();
                            stream.end();
                        }
                    } else {
                        stream.write(buffer);

                        if (stream.writable) {
                            stream.pause();
                            stream.end();
                        }
                    }
                }).fail(function () {
                    that.model.set({status: 'failed'});
                });
            } else if (this.model.get('has_error') == 'failed_send') {
                var that = this;

                that.model.set({status: 'pending'});
                this.model.save({context: that}).then(function() {
                    that.model.set({status: 'sent'});
                }).fail(function() {
                    that.model.set({status: 'failed'});
                });
            } else {
                soundManager.destroySound(this.model.get('message_id'));
                this.$('a.play-vox').trigger('click');
            }

            this.model.set({has_error: false});
        },
        handle_show_all_transcription: function (ev) {
            var that = this,
                $target = $(ev.target);
            if (this.transcription_open) {
                this.transcription_open = false;
                $target.attr('style', '');
                this.$el.find('.audio-holder').attr('style', '');
            } else {
                this.transcription_open = true;
                $target.css('white-space', 'normal');
                $target.height(ev.target.scrollHeight);
                this.$el.find('.audio-holder').css('padding-bottom', ev.target.scrollHeight + 15);

                if (!this.model.get('consumed')) {
                    App.chats.get(this.model.get('thread_id'))
                       .consume_messages(this.model.get('message_id'))
                       .done(function () {
                            that.model.set({'consumed': true});
                    });
                }
            }
        },
        handle_has_error: function () {
            if (this.model.get('has_error')) {
                this.$el.closest('.message').addClass('error');
                this.$el.append('<div class="message-issue"><i class="voxer-exclamation"></i>This message experienced an error <a href="">Try again?</a></div>');
            }
            else {
                this.$('.message-issue').remove();
                this.$el.closest('.message').removeClass('error');
            }
        },
        serializeData: function () {
            var data = _.clone(this.model.attributes),
                profile = App.profiles ? App.profiles.get(data.from) : undefined,
                system_message_subcontent_types = ["remove_participant", "add_participant"],
                sender = 'Unknown',
                format = 'jpg',
                
                from_me = data.from === (App.Auth ? App.Auth.get('rebelvox_user_id') : undefined);


            // sender name
            if (profile) {
                //sender = from_me ? "You" : profile.name();
                if (data.revox) {
                    var from = data.revox ? data.revox.from : data.from;
                    from_me = (from === (App.Auth ? App.Auth.get('rebelvox_user_id') : undefined));
                    sender = from_me ? "You" : data.revox.sender_name;
                }
                else {
                    sender = from_me ? "You" : profile.name();
                }
            }

            timestamp = format_message_timestamp(right_time(data));

            if (data.content_type === "image" && data.content_json && data.content_json.fmt) {
                format = data.content_json.fmt;
            }

            _.extend(data, {
                'timestamp': timestamp,
                'avatar_src': profile ? profile.avatar_src() : "",
                'sender': escapeHTML(sender),
                'talk_mode': Settings.fetch('talk-mode') || 'PTT',
                'body': this.prepare_body() || "",
                'image_urls': this.prepare_image_urls() || "",
                'from_me': from_me,
                'system': data.system || (data.subcontent_type &&
                    system_message_subcontent_types.indexOf(data.subcontent_type) !== -1),
                'location': data.geo && (data.geo.longitude !== undefined) && (data.geo.latitude !== undefined),
                'consumed': data.consumed,
                'duration': data.duration,
                'is_image': this.model.get('content_type') === 'image',
                'format': format,
                'deleted': this.model.get('deleted')
            });

            var setters = _.clone(data);
            setters.body = this.model.get('body');
            this.model.attributes = setters;

            return data;
        },
        prepare_body: function () {
            var model = this.model, body = '',
                type = model.get('content_type'),
                chat = this.options.chat || App.chats.findWhere({thread_id: this.model.get('thread_id')}),
                live = (chat && chat.get('live_audio')),
                playing = model.get('playing'),
                duration;

            if (type === 'image') {
                if (model.get('live_image') || model.get('favorited') !== undefined) {
                    return '<div class="image-container">' +
                           '<img src="' + App.create_url('get_body', {'message_id': model.get('message_id')}) + '" class="voxer-image" /></div>';
                } else {
                    return '<div class="image-container">' +
                        '<img src="/assets/img/ajax-loader-medium.gif" data-original="' +
                            App.create_url('get_body', {'message_id': model.get('message_id')}) +
                        '" class="lazy voxer-image" /></div>';
                }
            }
            if (type === 'text') {
                body = model.get('body');
                if (model.get('subcontent_type') === 'file_share' ||
                        model.get('sub_content_type') === 'file_share') {
                    var file_extension = model.get('file_extension') ? model.get('file_extension') :
                                        (model.get('content_json') ? model.get('content_json').file_extension : '');
                    if ((/(gif|jpg|jpeg|tiff|png)$/i).test(file_extension)) {
                        body = linkify(body);

                        return '<span>' + body + '</span>' +
                                '<div class="image-container">' +
                                '<img src="' + model.get('preview_link') + '" class="voxer-image" /></div>';
                    } else {
                        body = escapeHTML(body);
                        body = linkify(body);
                        body = minEmoji(body);
                        body = nl2br(body);
                    }
                } else if (model.get('sub_content_type') === 'location') {
                    body = "<div class='map-wrapper'></div>";

                    if (model.get('content_json').name) {
                        body += "<span class='location-name'>" + model.get('content_json').name + "</span>";
                    }
                    if (model.get('content_json').address) {
                        body += "<span>" + model.get('content_json').address + "</span>";
                    } else {
                        body += "<span>" + model.get('body') + "</span>";
                    }
                } else {
                    if (['add_participants', 'remove_participant'].indexOf(model.get('subcontent_type')) !== -1) {
                        body = model.get('text') || model.get('alt_text');
                    }
                    body = escapeHTML(body);

                    var mentions = model.get('mentions');
                    if (mentions && mentions.length) {
                        var mentions_applied = {};
                        mentions.forEach(function (mention) {
                            var name = model.get('body').substr(mention.s, mention.l);
                            if (!mentions_applied[name] &&
                                    mention.s !== undefined && mention.l !== undefined &&
                                    mention.s >= 0 && mention.l >= 0) {
                                body = body.split(name)
                                        .join('<a href="/contact/' + mention.user_id + '">' + name + '</a>');
                                mentions_applied[name] = true;
                            }
                        });
                    }
                    body = linkify(body);
                    body = minEmoji(body);
                    body = nl2br(body);
                }


                return body;
            }
            if (type === 'audio') {
                if (model.get('live_audio')) {
                    body = '<a class="play-vox pull-left"><i class="icon zmdi zmdi-play"></i></a>';
                    body += '<span class="pull-right vox-duration">' + duration + '</span>';
                } else {
                    var audio_duration = model.get('audio_duration_ms');

                    if (audio_duration === -1) {
                        duration = '';
                    } else {
                        var seconds = (audio_duration / 1000),
                            minutes = Math.floor(seconds / 60);

                        if( isNaN(seconds)  && isNaN(duration) ){
                            seconds = '0';
                            minutes = '0'
                        }
                        seconds = Math.floor(seconds % 60);
                        if (seconds.toString().length == 1)
                            seconds = "0" + seconds;

                        duration = minutes + ":" + seconds;


                    }
                    var icon = playing ? "zmdi-pause" : "zmdi-play";

                    body =
                      '</div> <div class="audio-holder">' +
                      '<span class="pull-right vox-duration">' +
                      duration +
                      "</span>" +
                      '<a class="play-vox pull-left" tabindex="-1">' +
                      '<i class="zmdi ' +
                      icon +
                      '"></i></a>' +
                      '<div class="progress' +
                      (model.get("consumed") ? " consumed" : "") +
                      '">' +
                      '<div class="bar" style=""></div>' +
                      '<div class="consumed-length"></div>' +
                      "</div>" +
                    '<i class="icon-voxer-transcribe"></i>' +
                    '<div class="transcription"></div>';
                }
                return body;
            }
            if (type === 'video') {
                var video_template = '<div class="video-container"><video id="<%- \'video_\' + message_id %>"' +
                                            'class="video-js vjs-default-skin vjs-big-play-centered" >' +
                                        '<source src="<%- location %>" type="video/mp4" />' +
                                     '</video></div>';

                return _.template(video_template, _.extend({
                    message_id: Lib.sanitize_message_id(model.get('message_id'))
                }, model.get('content_json')));
            }
        },
        prepare_image_urls: function () {
            if (this.model.get('content_type') === 'image') {
                var model = this.model,
                    message_id = model.get('message_id');

                return {
                    small: App.create_url('get_body', {'message_id': message_id}),
                    large: App.create_url('get_body', {'message_id': 'lrg_' + message_id})
                };
            }
            return null;
        },
        handle_speed_vox: function (e) {
            var that = this,
                value = this.model.get('playback_rate'),
                message_id = this.model.get('message_id'),
                sound = App.VoxerAudio.live_audio_playing[message_id];

            value = (value === Object.keys(CONSTANTS.playback_x).length)
                ? 1 : value + 1;
            this.model.set({'playback_rate': value});

            if (sound) {
                sound.playbackRate(CONSTANTS.playback_x[value].value);
            } else {
                sound = App.VoxerAudio.get_live_audio(message_id, function (sound) {
                    that.model.set({'playing': true});
                    that.model.set({'started_live_at': Date.now()});

                    if (!App.chat.Model.prototype.is_profile.call(that.model)) {
                        App.chats.findWhere({thread_id: that.model.get('thread_id')})
                           .consume_messages(message_id).done(function() {
                               that.model.set({consumed: true});
                           });
                    }
                }, function (sound) {
                    if (sound.state == "paused") {
                        that.model.set({'playing': false});
                    } else if (sound.state == "stopped") {
                        that.model.trigger('finish');
                        that.model.set({'playing': false});
                    }
                    that.model.unset('started_live_at');
                }, function (position) {
                    if (that.model.get('playing')) {
                        that.model.trigger('update', position * 100);
                    }
                });
                sound.playbackRate(CONSTANTS.playback_x[value].value);
            }

            this.$el.find('.speed-vox').html(
                CONSTANTS.playback_x[value].string
            );
        },
        handle_play_vox: function (e) {
            this.model.collection.sort();
            e.preventDefault();

            var that = this,
                sound = App.VoxerAudio.live_audio_playing[this.model.attributes.message_id];

            if (sound && this.model.get('playing')) {
                sound.pause();
                return;
            } else if (sound && !this.model.get('playing')) {
                sound.play();
            } else if (!sound && this.model.get('live_audio') && !this.model.get('playing')) {
                that.model.play_vox("live").done(function (data) {
                    that.model.set({consumed: true});
                });
            } else if (sound === undefined && this.model.get('update')) {
                queue_vox(that.model, false);
            } else if (sound === undefined) {
                queue_vox(that.model, true);
            }

            var thisIndex = that.model.collection.indexOf(this.model);
            for (var i = thisIndex; i <= that.model.collection.length - 1 ; i++) {
                var model = that.model.collection.models[i];
                if (model.get('content_type') === 'audio' && model.get('consumed') == false && i !== thisIndex) {
                    queue_vox(model, false);
                }
            }

            function queue_vox(model, interrupt) {
                model.play_vox(interrupt ? "interrupt" : "normal").done(function () {
                    model.set({consumed: true});
                }).fail(function () {
                    that.model.set({has_error: true});
                });
            }
        },

        handle_drag: function (e) {
            e.target.backbonebs.handle_skip(e);
        },

        stop_dragging: function (e) {
            var progress_el = e.target;
            var backbonebs = progress_el.backbonebs;
            progress_el.removeEventListener('mousemove', backbonebs.handle_drag);
            progress_el.removeEventListener('mouseup', backbonebs.stop_dragging);
            progress_el.removeEventListener('mouseleave', backbonebs.stop_dragging);
            progress_el.removeEventListener('mouseout', backbonebs.stop_dragging);
            if (progress_el.playing) {
                backbonebs.handle_play_vox(e);
            }
            delete progress_el.backbonebs;
        },

        handle_skip: function (e) {
            e.preventDefault();

            if (!this.model.get('audio_length_bytes'))
                return;

            var that = this,
                message_id = this.model.get('message_id'),
                sound = App.VoxerAudio.live_audio_playing[message_id],
                click = $(e.target).offset(),
                bar = $(e.target), progress = bar,
                location = (e.pageX - click.left) / $(e.target).width();

            if (bar.hasClass('bar')) {
                progress = bar.parent();
            } else {
                bar = bar.find('.bar');
            }
            progress = bar.parent();
            var progress_el = progress[0];
            
            if (!progress_el.backbonebs) {
                progress_el.backbonebs = this;
                progress_el.sound = sound;
                progress_el.playing = this.model.get('playing');
                if (progress_el.playing) {
                    progress_el.sound.pause();
                }
                progress_el.addEventListener('mousemove', that.handle_drag);
                progress_el.addEventListener('mouseup', that.stop_dragging);
                progress_el.addEventListener('mouseleave', that.stop_dragging);
                progress_el.addEventListener('mouseout', that.stop_dragging);
            }

            bar.css("left", (location * (progress.width() - bar.width())) + "px");
            if (sound && sound.completed) {
                sound.setPosition(location);
            } else {
                sound = App.VoxerAudio.get_live_audio(message_id, function (sound) {
                    that.model.set({'playing': true});
                    that.model.set({'started_live_at': Date.now()});

                    if (!App.chat.Model.prototype.is_profile.call(that.model)) {
                        App.chats.findWhere({thread_id: that.model.get('thread_id')})
                           .consume_messages(message_id).done(function() {
                               that.model.set({consumed: true});
                           });
                    }
                }, function (sound) {
                    if (sound.state == "paused") {
                        that.model.set({'playing': false});
                    } else if (sound.state == "stopped") {
                        that.model.trigger('finish');
                        that.model.set({'playing': false});
                    }
                    that.model.unset('started_live_at');
                }, function (position) {
                    if (that.model.get('playing')) {
                        that.model.trigger('update', position * 100);
                    }
                }, location);
            }
        }
    });


    Message.Views.ImagePanel = M.ItemView.extend({
        template: 'chat/panel/image_panel',
        className: 'image-panel modal',
        events: {
            'click button': 'handle_close_panel',
            'click .modal-footer i': 'handle_rotate'
        },
        initialize: function () {
            this.angle = 0;
            this.$el.css('opacity', '0');
        },
        onShow: function () {
            var img_urls = '',
                that = this;

            if (this.model.get('subcontent_type') === 'file_share') {
                if ((/(gif|jpg|jpeg|tiff|png)$/i).test(this.model.get('file_extension'))) {
                    img_urls = this.model.get('preview_link');
                    img_urls = {small: img_urls, large: img_urls};
                } else {
                   img_urls = this.model.get('image_urls');
                }
            } else {
                img_urls = this.model.get('image_urls');
            }

            // turn view into a modal window
            this.$el.attr('tabindex', '-1').modal();

            this.$('.panel-body').css({
                'text-align': 'center'
            });

            // placeholder
            $('<img>').attr('src', '/assets/img/ajax-loader-medium.gif').on('load', function () {
                if (!that.loaded && !that.small_loaded)
                    that.$('.image-wrapper').html('').append(this);
            });
            $('<img>').attr('src', img_urls.small).on('load', function () {
                that.small_loaded = true;

                try {
                    var orientation = that.model.get('content_json') ?
                        that.model.get('content_json').o : false;
                    if (orientation) {
                        var margin = (this.naturalWidth > this.naturalHeight ? (1 - this.naturalHeight / this. naturalWidth) * 100 : 0),
                            width = 100 + margin;
                            if(width > 100){
                                width = 100 ;
                                margin= 0;
                            }
                        $(this).css({
                            'width': width + '%',
                            'margin-top': margin * width / 100 / 2+ '%',
                            'margin-bottom': margin * width / 100 / 2 + '%',
                            'margin-left': '-' + margin / 2 + '%'
                        });
                        $(this).removeClass().addClass('rotate' + orientation2Degrees(orientation));
                    }
                } catch (e) {
                    console.error(e);
                }

                if (!that.loaded)
                    that.$('.image-wrapper').html('').append(this);
            });

            // actual image
            $('<img>').on('load', function () {
                try {
                    var orientation = that.model.get('content_json') ?
                        that.model.get('content_json').o : false;
                    if (orientation) {
                        var margin = (this.naturalWidth > this.naturalHeight ? (1 - this.naturalHeight / this. naturalWidth) * 100 : 0),
                            width = 100 + margin;
                            if( width > 100){
                                width = 100;
                                margin = 0;
                            }
                        $(this).css({
                            'width': width + '%',
                            'margin-top': margin * width / 100 / 2+ '%',
                            'margin-bottom': margin * width / 100 / 2 + '%',
                            'margin-left': '-' + margin / 2 + '%'
                        });
                        $(this).removeClass().addClass('rotate' + orientation2Degrees(orientation));
                    }
                } catch (e) {
                    console.error(e);
                }

                that.loaded = true;
                that.$('.image-wrapper').html('').append(this).addClass('zoomIn animated');
            }).attr('src', img_urls.large);

            $('.modal-backdrop').addClass('hide');
            this.$el.addClass('animated fadeIn').css('opacity', '1');
        },
        serializeData: function () {
            return {
                subject: this.model.get('subject'),
                time: moment((this.model.get('normalized_create_time') || this.model.get('posted_time')) * 1000)
                            .format("MM/DD/YYYY hh:mm:ss")
            };
        },
        handle_rotate: function (e) {
            var left = e.target.className.includes('left'),
                right = e.target.className.includes('right');

            if (left) {
                this.angle = (this.angle === 0) ? 360 : this.angle;
                this.angle = (this.angle - 90) % 360;
            } else if (right) {
                this.angle = (this.angle + 90) % 360;
            }

            this.$el.find('img').removeClass().addClass('rotate' + this.angle);
        },
        handle_close_panel: function () {
            var that = this;
            this.$el.on('hide.bs.modal', function () {
                //that.destroy();
                that.$el.addClass('zoomOut');
            });
            this.$el.modal('hide');
        },

    });
    return Message;
});
