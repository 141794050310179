define('modules/account',[
    'app',
    // Libraries.
    'backbone',
    'main/settings',
    'main/UI',
    'main/lib',
    'main/setup',
    'constants'
], function (App, Backbone, Settings, UI, Lib, Setup) {
    var Account = new App.module();
    var M = Backbone.Marionette;
    App.Account = Account;

    Account.Views.Login = M.ItemView.extend({
        template: "account/login",
        events: {
            "keyup input": "handle_hide_message",
            "click button#loginButton": "handle_login",
            "click button#oauthFacebookloginButton": "handle_facebook_oauth_login",
            "click button#oauthGoogleloginButton": "handle_google_oauth_login",
            "click a.forgot_password": "forgot_password",
            "click a.sign_up": "sign_up"
        },
        serializeData: function () {
            var data = this.options;
            data.signup_url = App.Config.get('vbm_url') + '/signup' || 'https://business.voxer.com/signup';
            data.router = 'http://' + App.Config.get('router');
            return data;
        },
        handle_google_oauth_login: function (e) {
            e.preventDefault();

            var url = "https://accounts.google.com/o/oauth2/auth?" +
                    "response_type=code" +
                    //"&client_id=346173804525-2bj37sho8vcccp19okabm55musvo6lqv.apps.googleusercontent.com" + //julian
                    "&client_id=474801698111-safqd9lvavcmpnc30vp34uc10r9jr4pi.apps.googleusercontent.com" + //anand
                    "&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts.readonly" + //anand
                    //"&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.me%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile" +
                    "&access_type=offline" +
                    "&redirect_uri=https%3A%2F%2Flocalhost%3A3080%2Foauth.html";

            window.popup = window.open(url, "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=550");


            window.addEventListener("message", this.recieved_code, false);

            this.poll_popup();
        },
        handle_facebook_oauth_login: function (e) {
            if (e)
                e.preventDefault();
            var that = this;
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    var credentials = {
                        accessToken: response.authResponse.accessToken
                    }
                    App.vent.trigger('login', credentials);
                } else {
                    FB.login(function (response) {
                        var credentials = {
                            accessToken: response.authResponse.accessToken
                        }
                        App.vent.trigger('login', credentials);
                    });
                }
            });
        },
        poll_popup: function () {
            var pollTimer = window.setInterval(function() {
                if (window.popup && window.popup.closed !== false) { // !== is required for compatibility with Opera
                    window.clearInterval(pollTimer);
                    window.popup = undefined;
                } else if (window.popup) {
                    window.popup.postMessage("Ack", "*");
                }
            }, 500);
        },
        recieved_code: function(event){
            var credentials = {
                code: event.data
            }
            App.vent.trigger('login', credentials);

            window.popup.postMessage("Close", "*");
        },
        handle_login: function (e, cred) {
            if (e !== undefined) {
                e.preventDefault();
                $(e.currentTarget).button('reset').button('loading');
                var inputs = this.$el.find('input'),
                    $message = this.$('.alert-danger.message'),
                    $button = $(e.currentTarget),
                    messages = {
                        'missing': 'Please enter an email/username and a password'
                    },
                    credentials = {
                        password: $(inputs[1]).val(),
                        username: $(inputs[0]).val().trim()
                    };

                if (_.isEmpty(credentials.username) || _.isEmpty(credentials.password)) {
                    $message.html(messages['missing']).removeClass('hide');
                    $button.button('reset');
                    return;
                }
            } else {
                credentials = {
                    code: cred.code
                };
            }
            App.vent.trigger('login', credentials, $button, $message);
        },
        handle_hide_message: function (e) {
            if (e.which !== 13) {
                this.$('.message').fadeOut(function () {
                    $(this).addClass('hide').show();
                });
            }
        },
        forgot_password: function (e) {
            e.preventDefault();
            App.Router.navigate('/forgot_password', {
                trigger: true
            });
        },
        sign_up: function (e) {
            e.preventDefault();
            App.Router.navigate('/sign_up', {
                trigger: true
            });
        },
        sso_login: function (e) {
            e.preventDefault();
            App.Router.navigate('/login_sso', {
                trigger: true
            });
        },
        account_url: function () {
            return App.create_url('/3/cs/start_session');
        },
        onShow: function () {
            this.$('input:first').focus();
            var browser = detectBrowser();
            if (browser.indexOf('msie 9') === -1) {
                if (!placeholderIsSupported()) {
                    $.placeholder.shim();
                }
            }
            $('#main').addClass('no-header');
            $('#content').removeClass('signup-details');
        }
    });

    Account.Views.LoginSso = M.ItemView.extend({
        template: "account/login_sso",
        events: {
            'click #ssoLoginButton': 'handle_login_sso',
        },
        handle_login_sso: function (e) {
            var that = this;
            e.preventDefault();
            var email = this.$el.find('input[name="user"]').val().trim();
            var $message = this.$('.alert-danger.message');
            var $button = $(e.currentTarget);
            let routerDomain = window.location.origin || "https://web.voxer.com";
            App.API.sso_provider({
                email: email,
                client_type: "web"
            }, {
                router: App.Settings.get('home_router')
            }, function(error, response) {
                if(error && (response.status === 500 || response.status === 404)) {
                    $message.html(error.error).removeClass('hide');
                    $button.button('reset');
                }
            }, function(response) {
                // For methods like SAML that require password
                if (response.sso_method && response.sso_method === 'saml') {
                    if (response.sso_authn_request) {
                        localStorage.setItem('business_id', response.businessId);
                        const payload = {
                            sso_service_url: response.sso_service_url,
                            sso_authn_request: response.sso_authn_request
                        }
                        const request_options = {
                            method : "POST",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body   : JSON.stringify(payload),
                        };

                        fetch(`${routerDomain}/sso/saml/submit_authrequest`, request_options)
                        // fetch(response.sso_service_url, request_options)
                        .then(test_response => {
                            console.log('Send request success: ', test_response)
                            if (test_response.status === 200 || test_response.status === 302) {
                                // 200 - URL in the body
                                // 302 - URL in the location header
                                // Normally in the body but just in case
                                const decodedLocation = decodeURIComponent(test_response.url || test_response.headers.location);
                                if (decodedLocation) {
                                    window.location.href = decodedLocation;
                                } else {
                                    throw new Error("IDP url not found.");
                                }
                            } else {
                                throw new Error("Unexpected error.");
                            }
                        }).catch(err => {
                            console.log('Send request failed: ')
                            console.log(err)
                            $message.html("Unable to connect to your SSO Identity Provider. Please contact you administrator.").removeClass('hide');
                            $button.button('reset');
                        })
                    } else {
                        $message.html("SSO settings are not properly configured. Please contact your administrator.").removeClass('hide');
                        $button.button('reset');
                    }
                    return;
                }

                // Other methods like OIDC don't require password
                if(!response.sso_provider_url || !response.client_id || !response.environment_id) {
                    $message.html("SSO settings are not properly configured. Please contact your administrator.").removeClass('hide');
                    $button.button('reset');
                } else {
                    that.signin_with_sso(response);
                }
            }.bind(this)); // Ensure the correct `this` context
        },
        signin_with_sso: function (sso_config) {
            localStorage.setItem('business_id', sso_config.businessId);
            let routerDomain = window.location.origin || "https://web.voxer.com";
            const authClient = new PingOneAuthClient({
                AUTH_URI: `${sso_config.sso_provider_url.split('/').slice(0, 3).join('/')}`,
                environmentId: sso_config.sso_provider_url.split('/').pop(),
                clientId: sso_config.client_id,
                redirectUri: `${routerDomain}/authcallback/`, 
                flowId: sso_config.sso_flow_id || '', // optional in case of SAML
                scopes: ['openid','profile', 'email'],
                responseType: ['token', 'id_token'],
                pkce: false
            });
            authClient.signIn();
        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
            $('#main').addClass('no-header');
        }
    });

    Account.Views.ForgotPassword = M.ItemView.extend({
        template: "account/forgot_password",
        events: {
            "click .btn.forgot_password": "handle_reset",
            "click a[href=\"login\"]": "login",
            "keydown input": "handle_hide_message"
        },
        serializeData: function () {
            return this.options;
        },
        handle_hide_message: function (e) {
            if (e.which === 13) {
                e.preventDefault();
                // hit enter
                this.$('.btn.forgot_password').trigger('click');
            }
            this.$('.message').addClass('hide');
        },
        handle_reset: function (e) {
            e.preventDefault();
            var that = this;
            var error = that.$('.alert.alert-danger');
            var success = that.$('.alert .alert-success');
            if (success.length !== 0) {
                success.remove();
            }

            if (!this._validate_email(this.$el.find('input').val())) {
                that.$('.message').html('Please enter a valid email address').toggleClass('hide');
            } else {
                var url = App.create_url('forgot_password', {
                        email: encodeURIComponent(this.$el.find('input').val())
                    });

                $.ajax({
                    url: url,
                    dataType: 'text',
                    type: "GET",
                    success: function (data, textStatus, jqXHR) {
                        $('<div>').addClass("alert alert-success reseted-password")
                                .html('<b>Success!</b><br/>We have sent you an email with instructions for resetting your password.</div>')
                                .insertAfter(that.$el.find('.buttons-container'));
                        $(e.target).text('Success');
                        $(e.target).attr({disabled: "disabled"});
                        $('.buttons-container, .help-block').addClass('hide');
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        var response_object;
                        try {
                            response_object = JSON.parse(jqXHR.responseText);
                        } catch (err) {
                            response_object = {
                                "error": {
                                    "error": err.message
                                }
                            };
                        }
                        error.html(response_object.error).toggleClass('hide');
                        $(e.target).button('reset');
                    }
                });
            }
        },

        _validate_email: function (string) {
            // a string consisting only of non-whitespaces
            var regexp = /^\S*$/;

            return string !== "" && regexp.test(string);
        },
        login: function (e) {
            e.preventDefault();
            App.Router.navigate('/login', {
                trigger: true
            });
        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
            $('#main').addClass('no-header');
        }
    });

    Account.SignUpModel = Backbone.Model.extend({
        constants: {
            'EMAIL_REGEX': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'PHONE_REGEX': new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$"),
            'USERNAME_REGEX': new RegExp("^\\w{2,30}(?:@\\w{2,30})?$"),
            'PASSWORD_MIN_LENGTH': 5,
            'INVALID_EMAIL_ERROR': { errorCode: 1, message: "Please enter a valid email address" },
            'INVALID_NAME': { errorCode: 2, message: "Please enter first and a last name" },
            'INVALID_PASSWORD': { errorCode: 3, message: "Password too short" },
            'INVALID_PASSWORD_WHITE': { errorCode: 3, message: "Password can't have whitespaces" },
            'INVALID_PHONE_NUMBER': { errorCode: 4, message: "Please enter a valid phone number or leave it empty." },
            'INVALID_SERVER_ERROR': { errorCode: 5, message: "" },
            'INVALID_FIRST_LAST_TOO_LONG': { errorCode: 2, message: "First and last name cannot be longer than 50 characters each." }
        },
        validate: function(attrs) {
            var errors = [];
            if (!attrs.email || !attrs.email.match(this.constants.EMAIL_REGEX))
                errors.push(this.constants.INVALID_EMAIL_ERROR);

            if (!attrs.f_name || !attrs.l_name)
                errors.push(this.constants.INVALID_NAME);

            if (!attrs.password || attrs.password.length < this.constants.PASSWORD_MIN_LENGTH)
                errors.push(this.constants.INVALID_PASSWORD);

            if (attrs.password && !/^\S*$/.test(attrs.password))
                errors.push(this.constants.INVALID_PASSWORD_WHITE);

            if (attrs.f_name.length > 50 || attrs.l_name.length > 50 ) {
                errors.push(this.constants.INVALID_FIRST_LAST_TOO_LONG);
            }

            if (attrs.phone && !attrs.phone.match(this.constants.PHONE_REGEX))
                errors.push(this.constants.INVALID_PHONE_NUMBER);

            return (errors.length) ? errors : false;
        }
    });

    Account.Views.SignUp = M.ItemView.extend({
        template: "account/sign_up",
        events: {
            "click .btn#CreateUser": "handle_user",
            "click #create-account-btn": "handle_create_user",
            "click #change-email": "handle_change_email",
            "keyup #email-address": "handle_change_email_done",
            "click #availabe-user-name": "handle_available_username",
            "change #user-name": "handle_username_check",
            "change #profile-picture": "handle_image_files",
            "click #profile-picture .signup-btn.upload-google": "handle_google_photo",
            "keyup #user-name": "handle_username_check",
            "click #create-username-btn": "handle_create_username",
            "click #profile-picture .skip-step ": "handle_import_contacts",
            "click #profile-picture #continue-play-message": "handle_review_profile",
            "click #profile-picture #continue-get-phone": "handle_get_phone",
            "click #send-link": "handle_resend",
            "click #import-contacts .signup-btn": "handle_import_google_contacts",
            "click #import-contacts a.skip-step": "handle_invite_step",
            "click #invite-voxer a.skip-step": "handle_continue_desktop",
            "click #invite-voxer .add-more-invites": "handle_add_more_invites",
            "submit #invite-voxer form": "handle_invite_friends",
            "click a[href=\"login\"]": "login"
        },

        handle_continue_desktop: function(e) {
            App.render_layout().then(function() {
                App.Router.navigate('/chats/' + generate_thread_id([App.Auth.get('user_id'), 'voxer.bot@voxer.com']), {trigger: true});
                App.layout.regionIntro.show(new App.UI.Views.WelcomeVoxer({}));
                App.vent.trigger('metrics:track', 'v4w/chats', {first_time: true});
            });
        },
        initialize: function () {
            var that = this,
                step = this.options.step || "";
            this.model.on('invalid', function(model, errors) {
                // Reset the already added errors marks, they might be fixed
                that.$el.find("input.error").removeClass("error");
                that.$el.find("p.error").hide();
                that.$el.find(".fg-line.error").removeClass("error");
                that.$el.find("#create-account-btn").removeAttr("disabled");

                errors.forEach(function(error) {
                    switch (error.errorCode) {
                        case model.constants.INVALID_EMAIL_ERROR.errorCode:
                            that.$el.find('label[for="email-address"] ~ p.error:first')
                                .html(error.message).show();
                            that.$el.find('input[name="email-address"]').addClass('error');
                            that.$el.find('input[name="email-address"]').parent().addClass('error');
                            break;
                        case model.constants.INVALID_NAME.errorCode:
                            that.$el.find('label[for="user-full-name"] ~ p.error:first')
                                .html(error.message).show();
                            that.$el.find('input[name="user-full-name"]').addClass('error');
                            that.$el.find('input[name="user-full-name"]').parent().addClass('error');
                            break;
                        case model.constants.INVALID_PASSWORD.errorCode:
                            that.$el.find('label[for="user-password"] ~ p.error:first')
                                .html(error.message).show();
                            that.$el.find('input[name="user-password"]').addClass('error');
                            that.$el.find('input[name="user-password"]').parent().addClass('error');
                            break;
                        case model.constants.INVALID_PHONE_NUMBER.errorCode:
                            that.$el.find('label[for="user-phone"] ~ p.error:first')
                                .html(error.message).show();
                            that.$el.find('#message-success .error').show();
                            that.$el.find('input[name="user-phone"]').parent().addClass('error');
                            break;
                        case model.constants.INVALID_SERVER_ERROR.errorCode:
                            that.$el.find('.server-error.error').html(error.message).show();
                        default:
                            console.log(error.message);
                    }
                });
            });
            switch (step) {
                case "step1":
                    var distinct_id = Lib.getBrowserFingerprint(Date.now());
                    App.vent.trigger('metrics:identify', distinct_id);
                    App.vent.trigger('metrics:register', {
                        voxer_client: 'v4w',
                        voxer_version: voxer.version,
                        distinct_id: distinct_id
                    });
                    App.vent.trigger('metrics:track', 'v4w/signup_landing');
                    // Bind callback function for reCAPTCHA
                    window.onReCaptchaSuccess = this.onReCaptchaSuccess.bind(this);
                    break;
                case "step3":
                    App.vent.trigger('metrics:track', 'v4w/signup_step2_username', {
                        username: (this.model.get('available_username') === this.model.get('username')) ?
                            'N' : 'Y'
                    });
                    break;
                case "step4":
                    App.vent.trigger('metrics:track', 'v4w/signup_step3_profile', {
                        google: this.model.get('avatar_src') && this.model.get('google_avatar') ?
                            true : false,
                        photo: this.model.get('avatar_src') && this.model.get('photo') ?
                            true : false,
                        skip: this.model.get('avatar_src') ? false : true
                    });
                    break;
                default:
            }
        },
        onRender: function () {
            var that = this;
            // Load reCAPTCHA API (v2)
            if (!document.getElementById('recaptcha-script')) {
                var script = document.createElement('script');
                // For v3
                // script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcsglAqAAAAADk_ZoZlqHNGNA0k8mjL0LrA6Q81";

                script.src = "https://www.google.com/recaptcha/enterprise.js";
                script.async = true;
                script.defer = true;

                script.id = 'recaptcha-script';
                document.head.appendChild(script);
            }

            this.$el.find("#user-phone").on('keyup', function(e) {
                if (e.keyCode == 13) {
                    that.$el.find("#continue-get-link").trigger('click');
                }
            });

            if (this.options.step === "step2") {

                    var stripe = Stripe(App.Config.get("stripe_publishable_key"));
                    var elements = stripe.elements();
                    var style = {
                      base: {
                        color: '#32325d',
                        lineHeight: '24px',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                          color: '#aab7c4'
                        },
                        borderBottom: "1px solid #d2d2d2",
                        marginBottom: "20px"
                      },
                      invalid: {
                        color: '#CE0000',
                        iconColor: '#CE0000'
                      }
                    };

                    var cardElement = this.$el.find('#card-element');
                    var cardErrors = this.$el.find('#card-errors');
                    // Create an instance of the card Element
                    var card = elements.create('card', {style: style});
                    // Add an instance of the card Element into the `card-element` <div>
                    card.mount(cardElement[0]);
                    card.on('focus', function () {
                        cardElement.addClass('card-focused');
                    });
                    card.on('blur', function () {
                        cardElement.removeClass('card-focused');
                    });
                    card.addEventListener('change', function(event) {
                      if (event.error) {
                          cardElement.addClass('card-error');
                          cardErrors.html(event.error.message);
                      } else {
                          cardElement.removeClass('card-error');
                          cardErrors.html('');
                      }
                    });
                    this.$el.find('#skip-freetrial').click(function () {
                        App.vent.trigger('metrics:track', 'v4w/signup_skip_trial');
                        App.Router.navigate("/sign_up/step3", {trigger: true});
                    });
                    var is_clicking = false;
                    this.$el.find('#free-trial-btn').click(function () {
                        if (is_clicking) return;
                        is_clicking = true;
                       stripe.createToken(card).then(function(result) {
                            console.log(result);
                            if (result.error) {
                                //handel errors
                                cardElement.addClass('card-error');
                                cardErrors.html(event.error.message);
                                is_clicking = false;
                                return;
                            }
                            cardElement.removeClass('card-error');
                            var sku = "com.voxer.recurring.yearly.2999";
                            var purchase_id = sku + "_" + Date.now();
                            var msg = {
                                "purchase_type": "web",
                                "sku": sku,
                                "purchase_id": purchase_id,
                                "token": result.token.id
                            };
                            App.API._post_message(msg, {
                                url: '1/cs/user/validate_purchase',
                                home_router: Settings.fetch('home_router')
                            }, function(error) {
                                App.vent.trigger('upgrade:error', error.error);
                                is_clicking = false;
                            }, function (response) {
                                App.Auth.set({'capabilities': response.capabilities});
                                App.vent.trigger('upgrade:success', response);
                                if(response.summary) {
                                    App.pbr_summary.set(response.summary);
                                }
                                App.vent.trigger('metrics:track', 'v4w/signup_trial');
                                App.Router.navigate("/sign_up/step3", {trigger: true});
                                is_clicking = false;
                            });
                        });
                    });
            }
            if (this.options.step === "step6") {
                var modal = new UI.Views.InvitesView({});
                    modal.render();
                that.$el.find('#invite-holder').html(modal.el);
            }
        },
        onDestroy: function() {
            var script = document.getElementById('recaptcha-script');
            if (script) {
                script.remove();
            }
        },
        signUpViewEvents: {
            enter: 13,
            escape: 27
        },
        serializeData: function () {
            var data = this.options,
                fullName;
            var invatee = {
                first: "",
                last: ""
            };
            if (this.model.get('invitee_details')) {
                try {
                    invatee = JSON.parse(this.model.get('invitee_details'));
                } catch (e) {
                }
            }
            invatee.email = (invatee.emails && invatee.emails.length) ? invatee.emails[0] : undefined;

            if (this.model.get('fullName')) {
                fullName = this.model.get('fullName');
            } else {
                fullName = (invatee.first) ? (invatee.first + " " + invatee.last) : ""
            }

            data.email = this.model.get('email') || invatee.email;
            data.fullName = fullName;
            data.password = this.model.get('password');
            data.available_username = this.model.get('available_username');
            data.avatar_src = this.model.get('avatar_src');
            data.inviter = this.model.get('inviter');
            data.f_name = this.model.get('f_name') || invatee.first;
            data.l_name = this.model.get('l_name') || invatee.last;
            data.username = this.model.get('username') || 'user_name';
            data.phone = this.model.get('phone');

            return data;
        },
        handle_invite_step:function(e){
            if (e) {
                e.preventDefault();
                App.vent.trigger('metrics:track', 'v4w/signup_step4_contacts', {skip: true});
            } else {
                App.vent.trigger('metrics:track', 'v4w/signup_step4_contacts', {google: true});
            }

            App.Router.navigate("/sign_up/step6", {trigger: true});
        },
        handle_username_check: _.debounce(function(e) {
            e.preventDefault();

            var that = this,
                value = e.target.value;

            if (value.length > 25) {
                onError({error: "Username can't be longer than 25 characters"});
                return;
            } else if (value.length === 0) {
                onError({error: "Please type an username"});
                return;
            }


            App.API.is_username_available({
                user_id: App.Auth.get('user_id'),
                username: e.target.value
            }, {
                router: Settings.fetch('home_router')
            },
            onError,
            function (data) {
                if(data.available) {
                    that.model.set({valid: true});

                    $(e.target).removeClass('error');
                    $(e.target).parent().removeClass('error');
                    that.$el.find('.user-name-available').show();
                    that.$el.find('.user-name-suggestion').hide();
                    that.$el.find('#username p.error').hide();
                }
            });

            function onError(error) {
                var message;
                if (error.error) {
                    message = error.error;
                } else if (typeof error === "string" && error.length) {
                    message = error;
                } else {
                    message = "Server error";
                }

                that.model.set({valid: false});

                $(e.target).addClass('error');
                $(e.target).parent().addClass('error');
                that.$el.find('.user-name-suggestion').show();
                that.$el.find('#username p.error:not(.server-error)').html(message).show();
                that.$el.find('.user-name-available').hide();
            }
        }, 500),
        handle_google_photo: function(e) {
            var auth = App.google.authInstance,
                that = this;


            if(auth.isSignedIn.get()) {
                getMyProfile()
            } else {
                auth.signIn({app_package_name: 'com.rebelvox.enterprise'}).then(getMyProfile);
            }

            function getMyProfile() {
                gapi.client.plus.people.get({'userId': 'me'}).then(function(res) {
                    App.profileImageUploadHandler(res.result.image.url.split("?sz=")[0], function() {
                        that.$el.find('#profile-picture img').attr("src", "/assets/img/ajax-loader-medium.gif");
                    }, function (data) {
                        //console.log("Profile pic successfully updated.", data);
                        that.$el.find('#profile-picture img').attr("src",
                            App.Config.get('public_www') + '/profile/' + App.Auth.get("rebelvox_user_id") + '.jpg?' + new Date().getTime());
                        that.$el.find('#profile-picture p:first').hide();
                        that.$el.find('#create-account header h1').html("Looking good?");
                        that.$el.find('#profile-picture .signup-btn.upload-photo').hide();
                        that.$el.find('#profile-picture .signup-btn.upload-google').hide();
                        that.$el.find('#profile-picture .skip-step').hide();
                        that.$el.find('#profile-preview').removeClass('hide');

                        that.model.set({
                            avatar_src: App.Config.get('public_www') + '/profile/' + App.Auth.get("rebelvox_user_id") + '.jpg?' + new Date().getTime(),
                            google_avatar: true
                        });
                    });
                });
            }
        },
        handle_import_google_contacts: function () {
            var self = this,
                url = App.API._create_url({
                    uri         : 'tp/google/oauth/1',
                    query_params: {
                        user_id: App.Auth.get("user_id")
                    }
                }, {
                    home_router: App.Settings.get("home_router")
                });

            function onMessage(event) {
                if (event.data.error && event.data.error == "access_denied") {
                        self.handle_invite_step();
                } else if (event.data.error) {
                    handle_import_error(event.data.error);
                } else if (event.data.code) {
                    App.API.link_google_account({
                        user_id: App.Auth.get('user_id'),
                        state  : event.data.state,
                        code   : event.data.code
                    }, {
                        router: Settings.fetch('home_router')
                    }, handle_import_error, function() {
                        self.handle_invite_step();
                    });
                }

                function handle_import_error(error) {
                    var message;
                    if (error.error) {
                        message = error.error;
                    } else if (typeof error === "string" && error.length) {
                        message = error;
                    } else {
                        message = "Server error";
                    }

                    self.model.trigger('invalid',
                        self.model,
                        [{
                            errorCode: 5,
                            message: message
                        }]
                    );
                }


                window.popup.postMessage("Close", "*");
                window.removeEventListener("message", onMessage);
            }

            window.popup = window.open(url, "mywindow", "resizable=yes, height=550, width=500");
            window.addEventListener("message", onMessage, false);

            App.Account.Views.Login.prototype.poll_popup.call(self);
        },
        handle_image_files: function(e) {
            var that = this,
                file = e.target.files[0];

            // Only process image files.
            if (!file || !file.type.match('image.*')) {
                return;
            }

            App.profileImageUploadHandler(e, function () {
                that.$el.find('#profile-picture img').attr("src", "/assets/img/ajax-loader-medium.gif");
            },function () {
                //console.log("Profile pic successfully updated.", data);
                that.$el.find('#profile-picture img').attr("src",
                    App.Config.get('public_www') + '/profile/' + App.Auth.get("rebelvox_user_id") + '.jpg?' + new Date().getTime());
                that.$el.find('#profile-picture p:first').hide();
                that.$el.find('#create-account header h1').html("Looking good?");
                that.$el.find('#profile-picture .signup-btn.upload-photo').hide();
                that.$el.find('#profile-picture .signup-btn.upload-google').hide();
                that.$el.find('#profile-picture .skip-step').hide();
                that.$el.find('#profile-preview').removeClass('hide');

                that.model.set({
                    avatar_src: App.Config.get('public_www') + '/profile/' + App.Auth.get("rebelvox_user_id") + '.jpg?' + new Date().getTime(),
                    photo: true
                });
            });
        },
        create_password_hash: function (password) {
            var shaObj = new jsSHA(password, "TEXT");
            return shaObj.getHash("SHA-512", "HEX");
        },
        onReCaptchaSuccess: function(token) {
            document.getElementById('g-recaptcha-token').value = token;
        },
        handle_create_user: function (e) {
            e.preventDefault();

            var email = this.$el.find('input[name="email-address"]').val(),
                fullName = this.$el.find('input[name="user-full-name"]').val(),
                password = this.$el.find('input[name="user-password"]').val(),
                opt_in_marketing = (this.$el.find('input[name="opt-in-marketing"]').is(":checked")) ? true : false,
                phone = this.$el.find('#user-phone').val(),
                dialing_code = this.$el.find('[name="countryCode"]').val(),
                fullNameArray = fullName.split(' '),
                valid = false,
                that = this;
            var recaptcha_response = this.$el.find('#g-recaptcha-token').val();

            if (typeof fullName.trim !== 'function') {
                fullName = fullName.trim();
            }

            valid = this.model.set({
                'email': email,
                'fullName': fullName,
                'password': password,
                'f_name': fullNameArray.shift(), // First name in fullName
                'l_name': fullNameArray.join(' '), // All other names after the first one
                'clienthash': this.create_password_hash(password),
                'opt_in_marketing': opt_in_marketing,
                'phone': phone,
                'dialing_code': dialing_code
            }, {validate: true});

            if (valid) {
                $(e.currentTarget).attr('disabled', true);

                if (!recaptcha_response || recaptcha_response.length === 0){
                    that.model.trigger('invalid',
                        that.model,
                        [{
                            errorCode: 5,
                            message: "Please complete the reCAPTCHA verification"
                        }]
                    );
                    return;
                }
                // If there are chats from another session, let's purge them
                if (App.chats)
                    App.chats.reset();

                // Proceed with the signup process after successful verification
                this.doActualSignup(recaptcha_response).done(function(data) {
                    // Growsumo is now ParnerStack.
                    // if (growsumo) {
                    //     growsumo.data.name = fullName;
                    //     growsumo.data.email = email;
                    //     growsumo.data.customer_key = data.args.user_id;
                    //     growsumo.createSignup();
                    // }

                    var deferreds = [new $.Deferred(), new $.Deferred()];

                    if (data.args && data.args.new_user_created) {
                        that.model.set({available_username: data.args.username});
                        deferreds[0].resolve();
                    }

                    // redirect to email confirmation page
                    App.Router.navigate("/sign_up/step-account-verification", {trigger: true});

                    // App.Auth.login({password: password, username: email}).done(function(response) {
                    //     if (!that.model.get('available_username')) {
                    //         App.profiles.get_profile(data.args.user_id).then(function(profiles) {
                    //             that.model.set({
                    //                 available_username: App.profiles.get(data.args.user_id).get('username')
                    //             });
                    //             deferreds[0].resolve();
                    //         });
                    //     } else {
                    //         deferreds[0].resolve();
                    //     }

                    //     App.Auth.set(response);
                    //     App.vent.trigger('session:new', response);
                    //     App.vent.trigger('metrics:track', 'v4w/log_in');

                    //     response.user_id = response.rebelvox_user_id;
                    //     App.Auth.set(response);
                    //     console.log("session set:", App.Auth.attributes);

                    //     App.home_router = "https://" + response.home_router.address + ":" + response.home_router.port;
                    //     Settings.set({
                    //         'home_router': App.home_router,
                    //         'port': response.home_router.port,
                    //         'login-id': App.Auth.get('login_id'),
                    //         'user_id': App.Auth.get('user_id'),
                    //         'logged-in': true,
                    //         'logged-since': new Date().getTime()
                    //     });
                    //     var thread_id = that.model.get('thread_id');
                    //     if (thread_id) {
                    //         App.API.thread_details(thread_id, {
                    //             home_router: Settings.fetch('home_router')
                    //         }, function () {
                    //             // If we can't find the thread, the sign-up has to continue anyway
                    //             // otherwise the view will stop and it will cause bad user experiance
                    //             App.Router.navigate("/sign_up/step2", {trigger: true});
                    //         }, function(thread) {
                    //             App.chats.add(thread, {silent: false});
                    //             App.profiles.get_profile(thread.creator).then(function() {
                    //                 var inviter = App.profiles.get(thread.creator);
                    //                 that.model.set({inviter: {
                    //                     avatar_src: inviter.avatar_src(),
                    //                     name: inviter.get('name')
                    //                 }});

                    //                 deferreds[1].resolve();
                    //             });
                    //         });
                    //     } else {
                    //         deferreds[1].resolve();
                    //     }
                    // });
                    // $.when.apply($, deferreds).then(function () {
                    //     App.Router.navigate("/sign_up/step2", {trigger: true});
                    // });
                }).fail(function(error) {
                    var message;
                    if (error.error) {
                        message = error.error;
                    } else if (typeof error === "string" && error.length) {
                        message = error;
                    } else {
                        message = "Server error";
                    }

                    that.model.trigger('invalid',
                        that.model,
                        [{
                            errorCode: 5,
                            message: message
                        }]
                    );
                });
            }
        },
        handle_change_email: function (e) {
            e.preventDefault();
            $('#email-address').removeAttr('disabled').val('').focus();
            $('#change-email').addClass('hide');
        },
        handle_change_email_done: function (e) {
            e.preventDefault();
            if (e.keyCode == this.signUpViewEvents.enter ||
                e.keyCode == this.signUpViewEvents.escape) {
                $('#change-email').removeClass('hide');
            }
        },
        handle_available_username: function() {
            this.$el.find('#user-name').val(this.model.get('available_username'))
        },
        handle_create_username: function(e){
            e.preventDefault();
            var username = this.$el.find('input[name="user-name"]').val(),
                that = this;

            if (username == this.model.get('available_username')) {
                that.model.set({username: this.model.get('available_username')});
                App.Router.navigate("/sign_up/step4", {trigger: true});
                return;
            }

            if (!this.model.get('valid')) {
                return;
            }
            this.create_username(username).done(function() {
                that.model.set({username: username});
                App.Router.navigate("/sign_up/step4", {trigger: true});
                $("section#profile-picture").removeClass('hide');
            }).fail(function(error) {
                var message;
                if (error.error) {
                    message = error.error;
                } else if (typeof error === "string" && error.length) {
                    message = error;
                } else {
                    message = "Server error";
                }

                that.model.trigger('invalid',
                    that.model,
                    [{
                        errorCode: 5,
                        message: message
                    }]
                );
            });
        },
        handle_review_profile: function(e){
            e.preventDefault();
             $('#profile-picture p').addClass('hide');
             $('#profile-picture .skip-step').addClass('hide');
             $('#profile-picture button.upload').addClass('hide');
             $('#profile-preview').removeClass('hide');
        },
        handle_import_contacts:function(e) {
            e.preventDefault();

            App.Router.navigate("/sign_up/step5", {trigger: true});
        },
        handle_get_phone: function(e){
            e.preventDefault();
            App.Router.navigate("/sign_up/step5", {trigger: true});
        },
        handle_resend: function(e) {
            e.preventDefault();
            var phone = this.$el.find('#resend-link').val(),
                valid = this.model.set({phone: phone}, {validate: true}),
                that = this;
            if (valid) {
                this.doPhoneSubmit().done(function() {
                    that.render();
                }).fail(function() {
                    // Do something on fail
                });
            }
        },
        doActualSignup: function (recaptcha_response) {
            var that = this,
                signupData,
                deferred = $.Deferred();

            signupData = {
                f_name: that.model.get('f_name'),
                l_name: that.model.get('l_name'),
                email: that.model.get('email'),
                phone: that.model.get('phone'),
                pass: that.model.get('clienthash'),
                opt_in_marketing: that.model.get('opt_in_marketing')
            }

            if (that.model.get('suggested_user_id')) {
                signupData.suggested_user_id = that.model.get('suggested_user_id');
            }

            App.API.signup(signupData, { router: Settings.fetch('home_router'), recaptcha_response: recaptcha_response },
            function (err) {
                deferred.reject(err);
            }, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise();
        },
        create_username:function(username) {
            var that = this,
            deferred = $.Deferred();

            App.API.change_username({
                user_id: App.Auth.get('user_id'),
                username: username
            },
            {
                router: Settings.fetch('home_router')
            },
            function (err) {
                deferred.reject(err);
            }, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise();
        },
        get_username:function() {
            var that = this,
            deferred = $.Deferred();

            App.API.get_available_username({
                user_id: App.Auth.get('user_id'),
            },
            {
                router: Settings.fetch('home_router')
            },
            function (err) {
                deferred.reject(err);
            }, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise();
        },
        doPhoneSubmit: function () {
            var deferred = $.Deferred();

            // TODO call the backend endpoint here
            setTimeout(function() { deferred.resolve(); }, 250);

            return deferred.promise();
        },
        create_username:function(username) {
            var that = this,
            deferred = $.Deferred();

            App.API.change_username({
                user_id: App.Auth.get('user_id'),
                username: username
            },
            {
                router: Settings.fetch('home_router')
            },
            function (err) {
                deferred.reject(err);
            }, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise();
        },
        get_username:function() {
            var that = this,
            deferred = $.Deferred();

            App.API.get_available_username({
                user_id: App.Auth.get('user_id'),
            },
            {
                router: Settings.fetch('home_router')
            },
            function (err) {
                deferred.reject(err);
            }, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise();
        },
        login: function (e) {
            e.preventDefault();
            App.Router.navigate('/login', {
                trigger: true
            });
        },
        onShow: function () {
            $('#main').addClass('no-header');
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
            $('#content').addClass('signup-details');
            /*  on focus animation   */

            if($('.fg-line')[0]) {
                $('body').on('focus', '.form-control', function(){
                    $(this).closest('.fg-line').addClass('fg-toggled');
                });
                $('body').on('blur', '.form-control', function(){
                    var p = $(this).closest('.form-group');
                    var i = p.find('.form-control').val();
                    if (p.hasClass('fg-float')) {
                        if (i.length == 0) {
                            $(this).closest('.fg-line').removeClass('fg-toggled');
                        }
                    }
                    else {
                        $(this).closest('.fg-line').removeClass('fg-toggled');
                    }
                });
            }

            var close = true;
            $('[data-toggle="popover"]').popover({trigger:"manual"});
            $(document).on('mousedown', function (e) {
                if($(e.target).attr('class') == 'popover-content'){
                    close = false;
                }
                else{
                    close = true;
                }
            });
            $('#user-phone').blur(function(){
                if(close){
                    $('#create-account').popover('hide');
                }
                else {
                   $(this).focus();
               }
            });
            $('#user-phone').focus(function(){
               if(close){
                   $('#create-account').popover('show');
               }
            });
        }
    });

    Account.Views.FreeTrial = M.ItemView.extend({
        template: "account/free_user",
        className: "free_user_wrapper",
        events: {
            "click .btn#FreeTrialButton": "handle_trial"
        },
        serializeData: function () {
            return this.options;
        },
        handle_trial: function (e) {
            e.preventDefault();
            window.location.href = Setup._vbm(Setup._webserver()) + "/signup";
        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
        }
    });

    Account.Views.ChangePassword = M.ItemView.extend({
        template: "account/change_password",
        events: {
            "click .btn.change_password": "handle_change_password",
            "click a[href=\"login\"]": "login",
            "keydown input": "handle_hide_message"
        },
        serializeData: function () {
            return this.options;
        },
        handle_hide_message: function (e) {
            if (e.which === 13) {
                e.preventDefault();
                // hit enter
                this.$('.btn.change_password').trigger('click');
            }
            this.$('.message').addClass('hide');
        },
        handle_change_password: function (e) {
            e.preventDefault();
            var that = this;
            var error = that.$('.alert.alert-danger');
            var success = that.$('.alert .alert-success');
            if (success.length !== 0) {
                success.remove();
            }
            var password = null;
            var password_id = this.options.password_id;

            this.$el.find('input').each(function (index, element) {
                if (password === null) {
                    password = $(element).val();
                } else {
                    if (password !==  $(element).val()) {
                        password = null;
                    }
                }
            });

            if (password === null && password_id === undefined) {
                error.html('Passwords do not match').toggleClass('hide');
            } else {

                //At the moment do not send sha512 password, server expect plaintext (:()
                //var shaObj = new jsSHA(password, "TEXT");
                //password = shaObj.getHash("SHA-512", "HEX");
                App.API.change_password(
                    {password: password, password_id: password_id},
                    {router: Settings.fetch('home_router')},
                    function (value, object) {
                        error.html('Error changing password').toggleClass('hide');
                    },
                    function (data) {
                        $('<div>').addClass("alert alert-success")
                            .html('<b>Success!</b><br/>We have successfully changed your password.</div>')
                            .insertAfter(that.$el.find('form legend'));
                    });
            }
        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
        },
        login: function (e) {
            e.preventDefault();
            App.Router.navigate('/login', {
                trigger: true
            });
        }
    });

    Account.Views.SettingsClient = M.ItemView.extend({
        template: "settings/settings-client",
        events: {
            'click label': "handle_click",
            'click .mic_element': "handle_mic_select",
            'click .mic_settings': "handle_mic_settings",
            'click #deleteaccount-btn': 'openAccountDeletionModal',
            'click #confirm-account-deletion': 'handle_accountdeletion',
        },
        initialize: function (options) {
            Settings.on(
              "change:desktop-notifications",
              function () {
                if (!this.isDestroyed) {
                  this.render();
                }
              }.bind(this)
            );
            Settings.on(
                "change:zapier-pop-up",
                function () {
                  if (!this.isDestroyed) {
                    this.render();
                  }
                }.bind(this)
              );
            Settings.on(
              "change:sound-on",
              function () {
                if (!this.isDestroyed) {
                  this.render();
                }
              }.bind(this)
            );
            App.vent.off("mic_level");
            App.vent.on(
              "mic_level",
              function () {
                if (!this.isDestroyed) {
                  this.render();
                }
              }.bind(this)
            );
        },
        serializeData: function () {
            var settings = this.options.settings,
                data = {};
            if (settings === 'webclient') {
                data = {
                    'talk_method': Settings.fetch('talk-method'),
                    'address_sort': Settings.fetch('sort-by'),
                    'sound_level': Settings.fetch('sound-level'),
                    'mic_access': App.VoxerAudio.checkMic(),
                    'version': voxer.version,
                    'sound_on': Settings.fetch('sound-on'),
                    'cache_timeline': Settings.fetch('cache-timeline'),
                    'desktop_notifications': Settings.fetch('desktop-notifications'),
                    'zapier-pop-up': Settings.fetch('zapier-pop-up'),
                    'location': Settings.fetch('location'),
                };
            } else if (settings === 'contacts') {

            } else if (settings === 'manager') {

            }
            data.user_id = App.Auth.get('login_id'); //App.Auth.get('rebelvox_user_id')
            data.isBusiness =  App.Auth.get('business_id') ? true : false;
            return data;
        },
        handle_mic_settings: function (e) {
            e.preventDefault();
            App.layout.regionIntro.show(new App.UI.Views.IntroView({
                MicStatus: !App.VoxerAudio.get_is_muted(),
                Name: App.Auth.get('login_id')
            }));
        },
        onRender: function () {
            var that = this;
            App.vent.trigger('metrics:track', '/settings');
            setTimeout(function () {
                that.ready(that);
                that.$el.find('.mic-access').popover();
            }, 500);

        },
        ready: function (context) {
            var that = this;
            if (App.VoxerAudio.voxer_audio) {
                context.handle_native_load(that);
            } else {
                context.handle_flash_load(that);
            }
        },
        handle_flash_load: function (e) {
            var that = this;
            this._runningAverage = [];
            var container = $('#settings-content');
            that.interval = window.setInterval(function () {
                if (container.css('display') === 'none') {
                    window.clearInterval(that.interval);
                }

                if (App.VoxerAudio.micAverageLevel !== -1) {
                    var data = App.VoxerAudio.micAverageLevel * 1.5;
                    that._runningAverage.push(data);
                    var sum = _.reduce(that._runningAverage, function (sum, val) { return (sum + 0) + (val + 0); }, 0);
                    $('#mic-volume .bar').css("width", ((sum / that._runningAverage.length).toFixed(2) + "%"));
                    if (that._runningAverage.length >= 6) {
                        that._runningAverage.shift();
                    }
                } else {
                    $('#mic-volume .bar').css("width", 0);
                }
            }, 10);

            if (true) {
                this.$el.find('#microphone-access').removeClass('hide');
            }

            if (get_os().os.indexOf("Mac") !== -1) {
                that.$('div#mic-selection').html(
                    "<p>" +
                        "If you do not see the bar move, change the microphone<br /> " +
                        "in your computer's system settings</p>");
            } else {
                var html = '',
                    active_mic = App.VoxerAudio.get_mic_index();

                _.each(mic_list, function (element, index) {
                    if (active_mic === index) {
                        html += "<div class='mic_element active' index='" + index + "'><div class='mic-area'><p>" + Lib.truncate_string(element, 17) + "</p></div></div>";
                    } else {
                        html += "<div class='mic_element' index='" + index + "'><div class='mic-area'><p>" + Lib.truncate_string(element, 17) + "</p></div></div>";
                    }
                });

                that.$('div#mic-selection').html(html);
            }
        },
        handle_native_load: function (e) {
            var that = this;

            if (App.VoxerAudio.audioContext) {
                var analyser = App.VoxerAudio.audioContext.createAnalyser()
                analyser.minDecibels = -90;
                analyser.maxDecibels = -10;
                analyser.smoothingTimeConstant = 0.85;

                if(App.VoxerAudio.buffer_has_value){
                    that.$el.find('#microphone-access').removeClass('hide');
                    that.$('.visualizer').removeClass('hide');
                }

                var canvas = that.$('.visualizer')[0];
                var canvasCtx = canvas.getContext("2d");
                source = App.VoxerAudio.audioContext.createMediaStreamSource(App.VoxerAudio.stream);
                source.connect(analyser);

                that.visualize(analyser, 'sinewave', canvas, canvasCtx);
            } else {
               console.log('getUserMedia not supported on your browser, or you didn\'t accepted the permissions yet!');
            }

        },
        visualize: function (analyser, visualSetting, canvas, canvasCtx, strokeStyle) {
          var WIDTH = canvas.width;
          var HEIGHT = canvas.height;

          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

          this.visualSetting = visualSetting  || 'sinewave';

          if(this.visualSetting  == "sinewave") {
            analyser.fftSize = 2048;
            var bufferLength = analyser.frequencyBinCount; // half the FFT value
            var dataArray = new Uint8Array(bufferLength); // create an array to store the data

            canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

            function draw() {

              drawVisual = requestAnimationFrame(draw);

              analyser.getByteTimeDomainData(dataArray); // get waveform data and put it into the array created above

              canvasCtx.fillStyle = '#ffffff'; // draw wave with canvas
              canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

              canvasCtx.lineWidth = 2;
              canvasCtx.strokeStyle = strokeStyle || '#ff6600';

              canvasCtx.beginPath();

              var sliceWidth = WIDTH * 1.0 / bufferLength;
              var x = 0;

              for(var i = 0; i < bufferLength; i++) {

                var v = dataArray[i] / 128.0;
                var y = v * HEIGHT/2;

                if(i === 0) {
                  canvasCtx.moveTo(x, y);
                } else {
                  canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
              }

              canvasCtx.lineTo(canvas.width, canvas.height/2);
              canvasCtx.stroke();
            };

            draw();

          } else if(this.visualSetting  == "off") {
            canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
            canvasCtx.fillStyle = "red";
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
          }

        },
        handle_click: function (e) {
            var input = $(e.target).find('input');
            if (!input.length) input = $(e.target);
            var name = input.attr('name'), value = input.attr('data-value'), holder = input.parent().parent();
            var val = {};

            value = (value === "false") ? false : value;
            value = (value === "true") ? true : value;
            console.log(val);
            val[name] = value;
            Settings.set(val, {silent: true});
            Settings.save(val);
            App.vent.trigger('metrics:track', '/settings/' + name, { status: value});

            if (holder.hasClass('off-opt') && value) {
                holder.removeClass('off-opt');
            } else if (!holder.hasClass('off-opt') && !value) {
                holder.addClass('off-opt');
            }

            if (!holder.hasClass('off-opt') && value && name === 'sound-on') {
                holder.addClass('off-opt');
                Settings.set({'sound-on': false});
            } else if (holder.hasClass('off-opt') && !value && name === 'sound-on') {
                holder.removeClass('off-opt');
                Settings.set({'sound-on': true});
            }

            if(name == 'sound-level'){
                holder.parent().find('.vol-holder').removeClass().addClass('vol-holder sound-'+value);
            }
        },
        handle_mic_select: function (e) {
            var target = $(e.currentTarget);
            App.VoxerAudio.set_mic_index(target.attr('index'));
            this.$('.mic_element.active').removeClass('active');
            target.addClass('active');
            this._runningAverage = [];
        },
        destroy: function () {
            window.clearInterval(this.interval);
            Backbone.Marionette.ItemView.prototype.destroy.apply(this, arguments);
        },
        openAccountDeletionModal: function (e) {
            $('#deleteaccount-modal').modal();
        },
        handle_accountdeletion: function (e) {
            var that = this;
            const user_id = App.Auth.get('login_id');

            if (user_id) {
                that.$el.find("#confirm-account-deletion").attr({disabled: "disabled"});
                App.API.delete_account({user_id: user_id}, {router: Settings.fetch('home_router')},
                    function (value, object) {
                        $('#notification-spot').addClass("alert alert-danger")
                            .append('<div><b>Error!</b><br/>Error deleting your account. Please retry later or contact support.</div>');
                    },
                    function (data) {
                        $('#notification-spot').addClass("alert alert-success")
                            .append('<div><b>Success!</b><br/>We have successfully deleted your account.</div>');
                    });
            } else {
                console.log('Error, cannot find the user_id')
            }
        }
    });

    Account.Views.SettingsMenu = M.ItemView.extend({
        template: "settings/settings-menu",
        className: 'panel panel-default',
        events: {
            'click a': 'handle_menu_click'
        },
        handle_menu_click: function (e) {
            e.preventDefault();
            $(e.currentTarget).parent().find('.active').removeClass('active');
            $(e.currentTarget).addClass('active');

            // toggle content
            var $ws = App.layout.regionSettings.currentView.workspace.$el;
            $ws.find('fieldset').addClass('hide');
            $ws.find('fieldset.' + $(e.currentTarget).data('menu')).removeClass('hide');
        }
    });

    Account.Views.LogoutView = M.ItemView.extend({
        template: 'account/logout_check',
        className: 'modal logout-modal',
        events: {
            "click .logout-btn": "handle_logout"
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();
        },
        handle_logout: function (e) {
            var device_token = Settings.fetch('old_subscription');

            $(e.currentTarget).button('loading');
            if (device_token) {
                App.API._post_message({
                    device_token: device_token
                }, {
                    url: 'notifications/disable_device_token/1',
                    asynchronousRequest: false,
                    home_router: App.Settings.get('home_router')
                });
            }

            Settings.set({ "zapier-pop-up": true });
            Settings.set({ "zapier-pop-up-dismissed": false });

            onSuccess = function() {
                App.vent.off('logged_out', onSuccess);
                this.$el.modal('hide');
                window.location = "/login";
            }.bind(this);
            App.vent.off('logged_out', onSuccess);
            App.vent.on('logged_out', onSuccess);
            App.Router.navigate('/logout', {trigger: true});
        }
    });


    return Account;
});

